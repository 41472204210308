import React, { useState } from 'react'
import AddPartnerShip from '../../components/dashboard/partnership/AddPartnerShip'
import ViewPartnerShip from '../../components/dashboard/partnership/ViewPartnerShip'

function PartnerShip() {
  const [added,setAdded] = useState(0)
  return (
    <div>
        <AddPartnerShip added={added} setAdded={setAdded} />
        <ViewPartnerShip added={added} />
    </div>
  )
}

export default PartnerShip