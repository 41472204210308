import React, { useState } from 'react'
import AddRegistrationInfoPage from '../../components/dashboard/scholarshipandgrant/AddRegistrationInfoPage'
import ViewRegistrationInfoPage from '../../components/dashboard/scholarshipandgrant/ViewRegistrationInfoPage'

function RegistrationInfoPage() {
    const [added,setAdded] = useState(0)

  return (
    <div>
        <AddRegistrationInfoPage added={added} setAdded={setAdded} />
        <ViewRegistrationInfoPage added={added} />
    </div>
  )
}

export default RegistrationInfoPage