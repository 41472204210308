import React, { useState } from 'react'
import AddMissionsAndValues from '../../components/dashboard/missionsandvalues/AddMissionsAndValues'
import ViewMissionsAndValues from '../../components/dashboard/missionsandvalues/ViewMissionsAndValues'

function MissionsAndValues() {
    const [added,setAdded] = useState(0)

  return (
    <div>
        <AddMissionsAndValues setAdded={setAdded} added={added} />
        <ViewMissionsAndValues added={added} />
    </div>
  )
}

export default MissionsAndValues