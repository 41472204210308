import React, { useState } from 'react'
import AddDonationIntro from '../../components/dashboard/donation/AddDonationIntro'
import ViewDonationIntro from '../../components/dashboard/donation/ViewDonationIntro'

function DonationPage() {
    const [added,setAdded] = useState(0)
  return (
    <div>
         <AddDonationIntro added={added} setAdded={setAdded} />
         <ViewDonationIntro added={added} />
    </div>
  )
}

export default DonationPage