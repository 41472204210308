import React, { useState } from 'react'
import AddSection from '../../components/dashboard/sectionimg/AddSection'
import ViewSection from '../../components/dashboard/sectionimg/ViewSection'

function AddsectionImg() {
  const [added,setAdded] = useState(0)

  return (
    <div>
        <AddSection setAdded={setAdded} added={added} />
        <ViewSection added={added} />
    </div>
  )
}

export default AddsectionImg