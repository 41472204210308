import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import HttpClient from "../../../utils/HttpClient";
import { editChampion, postChampion } from "../../../Api/api";

function AddChampionsLife({ setAdded, added }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [editId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });

  const [initialValues, setInitialValues] = useState(() => {
    if (location.state) {
      return {
        heading: location.state.heading,
        description: location.state.description,
        image: location.state.image,
      };
    } else {
      return {
        heading: "",
        description: "",
        image: [],
      };
    }
  });

  const [uploadedImgArr, setUploadedImgArr] = useState(false);

  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState();
  const [images, setImages] = useState([]);
  const [alreadyUploadedImg, setAlreadyUploadedImg] = useState(() => {
    if (location.state) {
      return location.state.image;
    } else {
      return null;
    }
  });
  const inputRef = useRef();
  const handleChange = async (e) => {
    let imagesList = [];
    for (let i = 0; i < e.target.files.length; i++) {
      imagesList.push(URL.createObjectURL(e.target.files[i]));
    }
    setImages([...images, ...imagesList]);
    setAlreadyUploadedImg(null);
    // let promisesArr =[];
    // for(let i=0;i<e.target.files.length;i++){
    //     const formdata = new FormData();
    //     formdata.append("image",e.target.files[i]);
    //     let result = await HttpClient.fileUplode("homeImageUpload","POST",formdata)
    //     console.log(result);
    //     // promisesArr.push(HttpClient.fileUplode("homeImageUpload","POST",formdata))
    //     // console.log(e.target.files[i])
    // }

    // console.log(promisesArr)
    if (!files) {
      let list = new DataTransfer();
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        console.log(file.name, file);
        let filenew = new File([file], file.name);
        list.items.add(filenew);
      }
      let myFileList = list.files;
      setFiles(myFileList);
      inputRef.current.files = myFileList;
    } else {
      let list = new DataTransfer();
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        let filenew = new File([file], file.name);
        list.items.add(filenew);
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let filenew = new File([file], file.name);
        list.items.add(filenew);
      }
      let myFileList = list.files;
      setFiles(myFileList);
      inputRef.current.files = myFileList;
    }
  };

  const handleRemove = (index) => {
    let list = new DataTransfer();
    for (let i = 0; i < inputRef.current.files.length; i++) {
      const f = inputRef.current.files[i];
      if (index !== i) {
        list.items.add(f);
      }
    }
    inputRef.current.files = list.files;
    setFiles(list.files);
    const imagesFilter = images.filter((file, idx) => index !== idx);
    setImages(imagesFilter);
  };
  // const imgUpload = async(file)=>{
  //   let data = new FormData()
  //   data.append("image",file)
  //   let result = await HttpClient.fileUplode("homeImageUpload","POST",data)
  //  return result
  // }

  const handleImgUpload = async () => {
    if (files) {
      setLoading(true);
      const arr = Array.from(files);
      // for (let index = 0; index < arr.length; index++) {
      //   const element = arr[index];
      //   imgUpload(element)
      // }
      const imgs = [];

      for (let i = 0; i < arr.length; i++) {
        const formdata = new FormData();
        formdata.append("image", arr[i]);
        let result = await HttpClient.fileUplode(
          "homeImageUpload",
          "POST",
          formdata
        );
        console.log(result);
        if (result && result.status) {
          imgs.push(result.url);
        }
        // promisesArr.push(HttpClient.fileUplode("homeImageUpload","POST",formdata))
        // console.log(e.target.files[i])
      }
      console.log(imgs);

      // arr.forEach((val,i)=>{
      //   const formdata = new FormData();
      //   console.log(val.name,val.size)
      //   formdata.append("image",val)
      //   // promisesArr.push(val.name)
      //   promisesArr.push(HttpClient.fileUplode("homeImageUpload","POST",formdata))
      // })
      // console.log(promisesArr);
      // const data = await Promise.all(promisesArr);
      // console.log(data)
      // const imgArr = data.map((val,i)=>{
      //    console.log(val.url)
      //    return val.url
      // })
      if (!location.state) {
        setInitialValues({
          ...initialValues,
          image: [...initialValues.image, ...imgs],
        });
      } else {
        setInitialValues({ ...initialValues, image: [...imgs] });
      }
      setUploadedImgArr(true);
      setLoading(false);
    } else {
      setInitialValues({ ...initialValues, image: [...initialValues.image] });
      setUploadedImgArr(true);
    }
  };

  const handleChampionAdd = async () => {
    const { heading, description, image } = initialValues;
    if (!heading || !description) {
      toast.error("please enter all values");
      return;
    }
    if (!location.state) {
      if (images.length === 0) {
        toast.error("Please upload at least 1 image");
        return;
      }
    }
    handleImgUpload();
  };

  useEffect(() => {
    if (uploadedImgArr === true) {
      console.log(initialValues, "init");
      postChampionData();
    }
    return () => {
      setUploadedImgArr(false);
    };
  }, [uploadedImgArr]);

  const postChampionData = async () => {
    if (!location.state) {
      setLoading(true);
      console.log("sendatat", initialValues);
      const res = await postChampion(initialValues);
      setLoading(false);
      console.log(res);
      setAdded(++added);
      if (res && res.status) {
        toast.success("Champion data added successfully");
        setImages([]);
        setFiles(null);
        inputRef.current.value = "";
      } else {
        toast.error("Something went wrong,please try again");
      }
    } else {
      setLoading(true);
      const res = await editChampion(editId, initialValues);
      setLoading(false);
      console.log(res);
      if (res && res.status) {
        toast.success("Champion data edited successfully");
      } else {
        toast.error("Something went wrong,please try again");
      }
      navigate(-1);
    }
  };

  useEffect(() => {
    console.log(initialValues);
  }, [initialValues]);

  useEffect(() => {
    console.log(files, images);
  }, [files, images]);
  return (
    <div>
      <label htmlFor="heading" className="mt-3">
        Enter heading
      </label>
      <input
        type="text"
        id="heading"
        defaultValue={initialValues ? initialValues.heading : ""}
        className="form-control mt-3 mb-1"
        placeholder="Enter heading"
        onChange={(e) => {
          setInitialValues({ ...initialValues, heading: e.target.value });
        }}
      />
      <br />
      <label htmlFor="elemTitle" className="mt-3">
        Enter description
      </label>
      <textarea
        defaultValue={initialValues ? initialValues.description : ""}
        id="elemTitle"
        className="form-control mt-3 mb-3"
        placeholder="Enter description"
        onChange={(e) => {
          setInitialValues({ ...initialValues, description: e.target.value });
        }}
      />
      <div className="mb-3">
        <label for="formFile" className="form-label">
          Upload images
        </label>
        <input
          className="form-control"
          type="file"
          id="formFile"
          multiple
          onChange={handleChange}
          accept="image/*"
          ref={inputRef}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {alreadyUploadedImg &&
          alreadyUploadedImg.map((image, i) => (
            <div className="d-flex flex-column">
              <img src={image} width={150} height={150} className="mx-2" />
            </div>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {images.map((image, i) => (
          <div className="d-flex flex-column">
            <img src={image} width={150} height={150} className="mx-2" />
            <button className="btn btn-danger" onClick={() => handleRemove(i)}>
              <i class="bi bi-x"></i>
            </button>
          </div>
        ))}
      </div>
      <div className="mb-3" style={{ maxWidth: "200px" }}>
        {/* <button className="btn btn-info" onClick={handleImgUpload}>Upload Images</button> */}
        <button
          className="btn btn-primary"
          onClick={handleChampionAdd}
          disabled={loading}
        >
          {loading ? <Loader /> : location.state? "Edit Champions":"Add Champion"}
        </button>
      </div>
    </div>
  );
}

export default AddChampionsLife;
