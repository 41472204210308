import React, { useState } from 'react'
import AddDonationForm from '../../components/dashboard/donation/AddDonationForm'
import ViewDonationForm from '../../components/dashboard/donation/ViewDonationForm'

function DonationFormPage() {
    const [added,setAdded] = useState(0)
  return (
    <div>
         <AddDonationForm added={added} setAdded={setAdded} />
         {/* <ViewDonationForm added={added} /> */}
    </div>
  )
}

export default DonationFormPage