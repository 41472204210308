import React, { useState } from "react";
import AddAdmissionDropDown from "../../components/dashboard/Admission/AdmissionDropDown/AddAdmissionDropDown";
import ViewAdminationDropDown from "../../components/dashboard/Admission/AdmissionDropDown/ViewAdminationDropDown";


export default function AdmissionDropDown(){
  const[add,setAdd]=useState(0)
  return(
    <>
    <AddAdmissionDropDown add={add} setAdd={setAdd}/>
    <ViewAdminationDropDown add={add}/>
    </>
  )
}