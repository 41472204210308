import React, { useState } from 'react'
import AddPartnerShipDegree from '../../components/dashboard/partnershipdegree/AddPartnerShipDegree'
import ViewpartnerShipDegree from '../../components/dashboard/partnershipdegree/ViewPartnerShipDegree'

function PartnerShipDegree() {
  const [added,setAdded] = useState(0)
  return (
    <div>
      <AddPartnerShipDegree added={added} setAdded={setAdded} />
      <ViewpartnerShipDegree added={added} />
    </div>
  )
}

export default PartnerShipDegree