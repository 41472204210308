import React, { useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { introHistoryUpload, addNewsPost, editNewsPost, postNews } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

function AddNews({ setAdded, added }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [editId] = useState(() => {
        if (location.state) {
            return location.state._id
        } else {
            return null
        }
    })
    const [initialValues] = useState(() => {
        if (location.state) {
            return {
                pageName: location.state.pageName,
                shortDesc: location.state.shortDesc,
                longDesc: location.state.longDesc,
                bgImage: location.state.bgImage
            }
        } else {
            return null
        }
    })
    const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
        if (location.state) {
            return location.state.bgImage
        } else {
            return ""
        }
    });
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const { pageName, shortDesc,longDesc } = data;
        if (!uploadedImgUrl) {
            toast.error("Please upload an image");
            return;
        }
        let sendData = {
            pageName,
            shortDesc,
            longDesc,
            bgImage: uploadedImgUrl,
        };

        if (!initialValues) {
            setLoading(true);
            const res = await postNews(sendData);
            setLoading(false);
            console.log(res);
            if (res && res.status) {
                toast.success("News added successfully");
                reset();
                setUploadedImgUrl("");
                setAdded(added + 1)
            }else{
                setLoading(false);
                toast.error(res.message);
            }
        } else {
            setLoading(true)
            const res = await editNewsPost(editId, sendData)
            setLoading(false)
            if (res && res.status) {
                toast.success("News edited successfully")
                navigate(-1)
            } else {
                toast.error("Something went wrong please try again later")
            }
        }
    };

    const handleImageUpload = async (e) => {
        let data = new FormData();
        data.append("image", e.target.files[0]);
        console.log(data, "daaaaa");
        setLoading(true);
        const result = await introHistoryUpload(data);
        setLoading(false);
        console.log(result);
        if (result && result.status) {
            setUploadedImgUrl(result.url);
            toast.success("Image successfully uploaded");
        } else {
            toast.error("Something went wrong,please try again");
        }
    };

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                            <i className="bi bi-bell me-2"> </i>
                            Add News
                        </CardTitle>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="heading" className="mt-3">
                                Enter Page Name
                            </label>
                            <input
                                type="text"
                                id="heading"
                                defaultValue={initialValues ? initialValues.pageName : ""}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter Page name"
                                {...register("pageName", { required: true })}
                            />
                            {errors.pageName && (
                                <span className="mt-2 text-danger">
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="elemTitle" className="mt-3">
                                Enter short description
                            </label>
                            <textarea
                                defaultValue={initialValues ? initialValues.shortDesc : ""}
                                id="elemTitle"
                                rows={"2"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter short description"
                                {...register("shortDesc", { required: true })}
                            />
                            {errors.shortDesc && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="elemTitle" className="mt-3">
                                Enter Long Description
                            </label>
                            <textarea
                                defaultValue={initialValues ? initialValues.shortDesc : ""}
                                id="elemTitle"
                                rows={"4"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter long description"
                                {...register("longDesc", { required: true })}
                            />
                            {errors.longDesc && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br />

                          
                            <div className="mb-1 mt-3">
                                {uploadedImgUrl && (
                                    <img
                                        style={{ height: "auto", width: 100, height: 100 }}
                                        src={uploadedImgUrl}
                                    />
                                )}
                            </div>
                            <div className="mb-3">
                                <label for="formFile" className="form-label mt-3">
                                    Add an image
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    accept="image/*"
                                    id="formFile"
                                    {...register("bgImage")}
                                    onChange={handleImageUpload}
                                />
                                {/* {errors.image && (
                  <span className="mt-2 text-danger">
                    {" "}
                    * This field is required
                  </span>
                )} */}
                                <br />
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary mt-3" disabled={loading}>
                                    {loading ? <Loader /> : initialValues ? "Edit News" : "Add News"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AddNews;
