import React, { useEffect, useState } from "react";
// import HttpClient from "../../utils/HttpClient";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";

import { MDBDataTable } from "mdbreact";
import HttpClient from "../../../utils/HttpClient";
import { toast } from "react-hot-toast";
import {  newsDelete } from "../../../Api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";

function ViewScholarshipForm({added}) {
  const navigate = useNavigate();
  const [applicationform, setApplicationForm] = useState([]);
  const [Loading, setLoading] = useState(false);
  // const [elementFieldType,setelementFieldType] = useState("")

  const data = {
    columns: [
      {
        label: "First Name",
        field: "firstName",
        center: true,
      },
      {
        label: "Last Name",
        field: "lastName",
        center: true,
      },
      {
        label: "Email",
        field: "email",
        center: true,
      },
      {
        label: "Zip Code",
        field: "zipCode",
        center: true,
      },
      {
        label: "Phone",
        field: "phone",
        center: true,
      },
      {
        label: "DOB",
        field: "dob",
        center: true,
      },

      {
        label: "Country",
        field: "country",
        center: true,
      },

      {
        label: "Type",
        field: "studentType",
        // center: true,
      },
      {
        label: "Admission For",
        field: "addmissionFor",
        // center: true,
      },
      {
        label: "Additional Information",
        field: "additionalInformation",
        // center: true,
      },
      
      // {
      //   label:"School",
      //   field:"school",
      //   center:true,
      // },
      // {
      //   label:"Year Of Completion",
      //   field:"yearOfCompletion",
      //   center:true,
      // },
      // {
      //   label:"Highest Qualification",
      //   field:"highestQualification",
      //   center:true,
      // },
      // {
      //   label:"Current Status",
      //   field:"currentStatus",
      //   center:true,
      // },
      // {
      //   label:"Area Of Study",
      //   field:"areaOfStudy",
      //   center:true,
      // },
      // {
      //   label:"Degree Level",
      //   field:"degreeLevel",
      //   center:true,
      // },
      // {
      //   label:"Passport Or BirthDoc",
      //   field:"passportOrBirthDoc",
      //   center:true,
      // },
      // {
      //   label:"Application FullName",
      //   field:"applicationFullName",
      //   center:true,
      // },
      // {
      //   label:"Additional Information",
      //   field:"additionalInformation",
      //   center:true
      // }
    ],
    rows: applicationform,
  };

  useEffect(() => {
    fetchApplicationForms();
  }, [added]);

  async function fetchApplicationForms() {
    setLoading(true);
    try {
      const res = await HttpClient.requestData("viewApplicationForm", "GET", {});
      console.log(res);
      if (res.status) {
        const data = res.data
        .filter(student => student.applicationDetails.scholarship === true)
        .map((val, i) => {
          return {
            ...val.applicationDetails,
            ...val.educationRecord,
            ...val.educationDetails,
            ...val.documentation,
            ...val.declaration
          };
        });
        console.log(data);
        setApplicationForm(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  if (Loading) {
    return (
       <Loader />
    );
  }


  return (
    <div className="container">
      <Col>
        <Row>
          <Card>
            <CardBody>
              <CardTitle tag="h5">All Scholarship Forms</CardTitle>
              <MDBDataTable responsive bordered data={data} />
            </CardBody>
          </Card>
        </Row>
      </Col>
    </div>
  );
}

export default ViewScholarshipForm;



