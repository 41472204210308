import React, { useState } from 'react'
import AddOrgInfo from '../../components/dashboard/orginfo/AddOrgInfo'
import ViewOrgInfo from '../../components/dashboard/orginfo/ViewOrgInfo'

function OrgInfo() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddOrgInfo added={added} setAdded={setAdded} />
        <ViewOrgInfo added={added} />
    </div>
  )
}

export default OrgInfo