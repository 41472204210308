import React, { useState, useEffect } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { addScholarshipPost, scholarshipEdit, scholarshipImageUpload } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import toolbarConfig from "../../ReactRte/Index";
import RichTextEditor from "react-rte";

function AddScholarShip({ setAdded, added }) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [editId, setEditId] = useState(null);
  const [initialValues, setInitialValues] = useState({ title: "", description: "", image: "" });
  const [uploadedImgUrl, setUploadedImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(RichTextEditor.createEmptyValue());
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (location.state) {
      const { _id, title, description, image } = location.state;
      setEditId(_id);
      setInitialValues({ title, description, image });
      setDescription(RichTextEditor.createValueFromString(description, "html"));
      setUploadedImgUrl(image);
    }
  }, [location.state]);

  const onSubmit = async (data) => {
    const { title } = data;
    
    if (!uploadedImgUrl) {
      toast.error("Please upload an image");
      return;
    }

    const sendData = {
      title,
      description: description.toString("html"),
      image: uploadedImgUrl,
    };

    setLoading(true);
    
    try {
      let res;
      if (editId) {
        res = await scholarshipEdit(editId, sendData);
      } else {
        res = await addScholarshipPost(sendData);
      }

      if (res && res.status) {
        toast.success(`Scholarship ${editId ? "edited" : "added"} successfully`);
        if (!editId) {
          reset();
          setUploadedImgUrl("");
          setAdded(added + 1);
        } else {
          navigate(-1);
        }
      } else {
        toast.error(res.message || "Something went wrong, please try again later");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    let data = new FormData();
    data.append("image", file);

    setLoading(true);
    try {
      const result = await scholarshipImageUpload(data);
      if (result && result.status) {
        setUploadedImgUrl(result.url);
        toast.success("Image successfully uploaded");
      } else {
        toast.error("Image upload failed, please try again");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"></i>
              {editId ? "Edit Scholarship" : "Add Scholarship"} Section
            </CardTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="heading" className="mt-3">
                Enter heading
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues.title}
                className="form-control mt-3 mb-1"
                placeholder="Enter heading"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="description" className="mt-3">
                Enter description
              </label>
              <RichTextEditor
                toolbarConfig={toolbarConfig}
                value={description}
                onChange={setDescription}
              />
              {errors.description && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <div className="mb-1 mt-3">
                {uploadedImgUrl && (
                  <img
                    style={{ height: "auto", width: 100 }}
                    src={uploadedImgUrl}
                    alt="Scholarship"
                  />
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label mt-3">
                  Add an image
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  onChange={handleImageUpload}
                />
              </div>
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? <Loader /> : editId ? "Edit Scholarship" : "Add Scholarship"}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddScholarShip;
