import React, { useState } from 'react'
import AddCIEApply from '../../../components/dashboard/applycie/AddCIEApply'
import ViewCIEApply from '../../../components/dashboard/applycie/ViewCIEApply'

function ApplyCIEPage() {
  const [added,setAdded] = useState(0)
  return (
    <div>
      <AddCIEApply added={added} setAdded={setAdded} />
      <ViewCIEApply added={added} />
    </div>
  )
}

export default ApplyCIEPage