import React, { useState } from 'react'
import AddHistoryDetails from '../../components/dashboard/historydetails/AddHistoryDetails'
import ViewHistoryDetails from '../../components/dashboard/historydetails/ViewHistoryDetails'

function HistoryDetails() {
    const [added,setAdded] = useState(0)

  return (
    <div>
        <AddHistoryDetails setAdded={setAdded} added={added} />
        <ViewHistoryDetails added={added} />
    </div>
  )
}

export default HistoryDetails