import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  postAreaOfStudy,
  postDegreeLevel,
  postStudentType,
} from "../../Api/api";
import { Card, Row, Col, CardTitle, CardBody, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import HttpClient from "../../utils/HttpClient";

import Swal from "sweetalert2";

function AddAreaOfStudentAndDegree({}) {
  const [areaOfStudy, setareaOfStudy] = useState("");
  const [degreeLevel, setdegreeLevel] = useState("");

  const [allData, setAllData] = useState([]);
  //   const areaofstudyID = useRef();
  const [dissableButton, setDissableButton] = useState(true);

  const [editEnable, seteditEnable] = useState(false);
  const [editId, setEditId] = useState("");
  const handleSubmit = async () => {
    if (!areaOfStudy) {
      toast.error("Please provide area of study value");
      return;
    }
    let res;
    if (editEnable) {
      res = await HttpClient.requestData(
        `update-area-of-study/${editId}`,
        "PUT",
        { areaOfStudy: areaOfStudy }
      );
      // edit api here
    } else {
      res = await postAreaOfStudy({ areaOfStudy: areaOfStudy });
    }
    console.log(res);
    if (res && res.status) {
      fetchAreaOfStudy();
      seteditEnable(false);
      setEditId("");
      //   areaofstudyID.current = res.data;
      toast.success("Action successfully");
      setareaOfStudy("");
    } else {
      toast.error("Something went wrong please try again");
    }
  };

  //   const handleSubmitdegree = async () => {
  //     if (!areaofstudyID.current) {
  //       toast.error("Please enter an area of study first");
  //       return;
  //     }
  //     if (!degreeLevel) {
  //       toast.error("Enter a degree level");
  //       return;
  //     }
  //     console.log({
  //       areaOfStudy: areaofstudyID.current,
  //       degreeLevel,
  //     });
  //     const res = await postDegreeLevel({
  //       areaOfStudy: areaofstudyID.current,
  //       degreeLevel,
  //     });
  //     if (res && res.status) {
  //       areaofstudyID.current = null;
  //       setdegreeLevel("");
  //       toast.success("Degree added successfully");
  //     }
  //   };

  const data = {
    columns: [
      {
        label: "Area Of Study",
        field: "areaOfStudy",
        center: true,
      },
      //   {
      //     label: "Degree Lable",
      //     field: "degreeLable",
      //     center: true,
      //   },
      {
        label: "Action",
        field: "action",
        center: true,
        sort: "disabled",
      },
    ],
    rows: allData,
  };

  const fetchAreaOfStudy = async () => {
    let result = await HttpClient.requestData("addAreaOfStudy", "GET");

    // console.log("areaofstudy", result);

    if (result && result.status) {
      let arr = result.data.map((item) => {
        return {
          ...item,
          action: (
            <div>
              <Button
                color="info"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  setareaOfStudy(item.areaOfStudy);
                  seteditEnable(true);
                  setEditId(item._id);
                }}
              >
                Edit
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteHandle(item._id);

                    
                    }
                  });
                }}
              >
                Delete
              </Button>
            </div>
          ),
        };
      });
      //   setareaOfStudy(result.data);
      setAllData(arr);
    }
  };

  useEffect(() => {
    fetchAreaOfStudy();
  }, []);

  const deleteHandle = async (id) => {
    let result = await HttpClient.requestData(
      `delete-area-of-study/${id}`,
      "DELETE"
    );
    if(result && result.status){
        Swal.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        fetchAreaOfStudy()
    }
  };

  let a ={
    name:"dfcjh",
    name:"kjbjb"
  }
  return (
    <>
      <div className="form-group">
        <label for="exampleInputEmail1">Enter Area of Study</label>

        <input
          type={"text"}
          className="form-control"
          placeholder="Enter Area of Study Name"
          value={areaOfStudy}
          onChange={(e) => setareaOfStudy(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSubmit}>
          {editEnable ? "Update" : "Add"}
        </button>
      </div>
      <div className="container">
        <Col>
          <Row>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Area Of Study</CardTitle>
                <MDBDataTable responsive bordered data={data} />
              </CardBody>
            </Card>
          </Row>
        </Col>
      </div>
      {/* <div>
       
           <input
                type={"text"}
                className="form-control mt-3 me-3"
                placeholder="Enter degree level"
                value={degreeLevel}
                onChange={(e) => setdegreeLevel(e.target.value)}
            />
            <button className='btn btn-primary'  onClick={handleSubmitdegree}>Add Degree Level</button>
        </div> */}
    </>
  );
}

export default AddAreaOfStudentAndDegree;
