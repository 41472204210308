import React, { useState } from 'react'
import AddGallery from '../../components/dashboard/gallery/AddGallery'
import ViewGallery from '../../components/dashboard/gallery/ViewGallery'

function Gallery() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddGallery setAdded={setAdded} added={added} />
        <ViewGallery added={added} />
    </div>
  )
}

export default Gallery