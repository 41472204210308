import React, { useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { addExperiencePost, experienceImageUpload, experienceEdit, introHistoryUpload, addHistoryIntroPost, introHistoryEdit, addApplyCIE, cieApplyEdit } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

function AddCIEApply({ setAdded, added }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [editId] = useState(() => {
        if (location.state) {
            return location.state._id
        } else {
            return null
        }
    })
    const [initialValues] = useState(() => {
        if (location.state) {
            return {
                title: location.state.title,
                description: location.state.description,
            }
        } else {
            return null
        }
    })

    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const { title, description } = data;
        
         if(!title || !description){
            toast.error("Enter all fields");
            return;
         }

        if (!initialValues) {
            setLoading(true);
            const res = await addApplyCIE({title,description});
            setLoading(false);
            console.log(res);
            if (res && res.status) {
                toast.success("Apply CIE added successfully");
                reset();
                setAdded(added + 1)
            }else{
                toast.error(res.message)
            }
        } else {
            setLoading(true)
            const res = await cieApplyEdit(editId, {title,description})
            setLoading(false)
            if (res && res.status) {
                toast.success("Apply CIE edited successfully")
                navigate(-1)
            } else {
                toast.error("Something went wrong please try again later")
            }
        }
    };

  

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                            <i className="bi bi-bell me-2"> </i>
                            Add Apply CIE
                        </CardTitle>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="title" className="mt-3">
                                Enter title
                            </label>
                            <input
                                type="text"
                                id="title"
                                defaultValue={initialValues ? initialValues.title : ""}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter Title"
                                {...register("title", { required: true })}
                            />
                            {errors.title && (
                                <span className="mt-2 text-danger">
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="description" className="mt-3">
                                Enter description
                            </label>
                            <textarea
                                defaultValue={initialValues ? initialValues.description : ""}
                                id="description"
                                rows={"3"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter description"
                                {...register("description", { required: true })}
                            />
                            {errors.description && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br />

                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary mt-3" disabled={loading}>
                                    {loading ? <Loader /> : initialValues ? "Edit Apply CIE" : "Add apply CIE"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AddCIEApply;
