import React from 'react'
import { Navigate,Outlet} from 'react-router-dom'
const PublicRoute = () => {
    let user = localStorage.getItem("userAdminData");
    if (user) {
        return <Navigate to={"/"} />;
      } else {
        return <Outlet />;
      }
}

export default PublicRoute