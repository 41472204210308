import React, { useEffect, useState } from 'react'
import Welcome from '../../components/dashboard/welcome/Welcome'
import WelcomeList from '../../components/dashboard/welcome/WelcomeList'

function WelcomePage() {
  const [addData,setAddData] = useState(0);
  return (
     <>
     <Welcome setAddData={setAddData} addData={addData} />
     <WelcomeList addData={addData} />
     </>
  )
}

export default WelcomePage