import React, { useState } from "react";
import AddAreaOfStudentAndDegree from "./AreaOfStudentAndDegree";
// import ViewAreaOfStudentAndDegree from "./ViewAreaOfStudentAndDegree";



export default function AreaOfStudentAndDegree (){
    const [add,setAdd]=useState(0)
    return(
        <>
        <AddAreaOfStudentAndDegree />
        {/* <ViewAreaOfStudentAndDegree/> */}
        </>
    )
}