import React, { useEffect, useState } from "react";
// import HttpClient from "../../utils/HttpClient";
import { Card, Row, Col, CardTitle, CardBody, Button } from "reactstrap";

import { MDBDataTable } from "mdbreact";

import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import HttpClient from "../../utils/HttpClient";
import Loader from "../../layouts/loader/Loader";

function ViewDonation({ added }) {
  const navigate = useNavigate();
  const [donationForm, setDonationForm] = useState([]);
  const [Loading, setLoading] = useState(false);
  // const [elementFieldType,setelementFieldType] = useState("")

  const data = {
    columns: [
      {
        label: "Fund Name",
        field: "fundName",
        center: true,
      },
      {
        label: "Name",
        field: "firstName",
        center: true,
      },
      {
        label: "Amount",
        field: "Amount",
        center: true,
      },
      {
        label: "Country",
        field: "country",
        center: true,
      },
      {
        label: "Gift Type",
        field: "giftType",
        center: true,
      },
      {
        label: "Memorial Name",
        field: "memorialName",
        center: true,
      },
    ],
    rows: donationForm,
  };

  useEffect(() => {
    fetchApplicationForms();
  }, [added]);

  async function fetchApplicationForms() {
    setLoading(true);
    try {
      const res = await HttpClient.requestData("viewGiveAsDonation", "GET", {});
      console.log(res);
      if (res.status) {
        const data = res.data.map((val, i) => {
          return {
            ...val,
            Amount: "₹" + val.amount,
          };
        });
        console.log(res.data.data);
        setDonationForm(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  if (Loading) {
    return <Loader />;
  }

  return (
    <div className="container">
      <Col>
        <Row>
          <Card>
            <CardBody>
              <CardTitle tag="h5">View All Donations</CardTitle>
              <MDBDataTable responsive bordered data={data} />
            </CardBody>
          </Card>
        </Row>
      </Col>
    </div>
  );
}

export default ViewDonation;
