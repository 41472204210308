import React, { useState } from 'react'
import AddExperience from '../../components/dashboard/experience/AddExperience'
import ViewExperience from '../../components/dashboard/experience/ViewExperience'

function Experience() {
  const [added,setAdded] = useState(0)
  return (
    <div>
       <AddExperience setAdded={setAdded} added={added} />
       {/* <ViewExperience added={added} /> */}
    </div>
  )
}

export default Experience