import { Chip, Stack } from "@mui/material";
import React from "react";

const Field = ({
  label,
  placeholder,
  state,
  setState,
  keyName,
  handleDelete,
  addNew,
  addData,
  inputValue,
  handleEdit,
  editEnable,
}) => {
  //   console.log('state', state)

  return (
    <div>
      <label htmlFor="shortDesc" className="mt-3">
        {label}
      </label>

      <br />
      <Stack direction="row" spacing={1}>
        {state?.map((it) => {
          return (
            <Chip
              color="primary"
              label={it[keyName]}
              variant="outlined"
              onClick={() => handleEdit(it[keyName], it._id)}
              onDelete={() => handleDelete(it._id)}
            />
          );
        })}
      </Stack>
      <input
        type="text"
        id="pageName"
        value={inputValue}
        onChange={(e) => {
          addNew(e.target.value);
        }}
        className="form-control mt-3 mb-1"
        placeholder={placeholder}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();

            addData();
          }
        }}
      />

      <button
        className={`btn btn-${editEnable ? "success" : "primary"}`}
        onClick={addData}
      >
        {editEnable ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default Field;
