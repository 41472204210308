import React, { useState } from 'react'
import AddContactIntro from '../../components/dashboard/contact/AddContactIntro'
import ViewContactIntro from '../../components/dashboard/contact/ViewContactIntro'

function ContactIntroPage() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddContactIntro setAdded={setAdded} added={added} />
        <ViewContactIntro added={added} />
    </div>
  )
}

export default ContactIntroPage