import React, { useState } from 'react'
import AddFacultyList from '../../components/dashboard/facultylist/AddFacultyList'
import ViewFacultyList from '../../components/dashboard/facultylist/ViewFacultyList'

function FacultyList() {
    const [added,setAdded] = useState(0)
  return (
    <div>
       <AddFacultyList setAdded={setAdded} added={added} />
       <ViewFacultyList added={added} />
    </div>
  )
}

export default FacultyList