import React from 'react'
import ViewApplicationForm from '../../components/dashboard/applicationform/ViewApplicationForm'

function ApplicationForm() {
  return (
    <div>
        <ViewApplicationForm />
    </div>
  )
}

export default ApplicationForm