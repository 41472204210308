import {
  Button,
  Nav,
  NavItem,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
// import Logo from "./Logo";
import logoimg from "../assets/images/logos/lgogo.png";

import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

const navigation = [
  {
    title: "Home",
    children: [
      {
        title: "Welcome section",
        href: "/home/welcome",
        icon: "bi bi-bell",
      },
      {
        title: "Experience section",
        href: "/home/experience",
        icon: "bi bi-bell",
      },
      {
        title: "Apply section",
        href: "/home/apply",
        icon: "bi bi-bell",
      },
      // {
      //   title: "News and Event",
      //   href: "/home/newsandevent",
      //   icon: "bi bi-bell",
      // },
      {
        title: "Add image section",
        href: "/home/addimgsection",
        icon: "bi bi-bell",
      },
      {
        title: "Quick view section",
        href: "/home/addquickview",
        icon: "bi bi-bell",
      },
      {
        title: "Banner section",
        href: "/home/banner",
        icon: "bi bi-bell",
      },
      {
        title: "Scholarship section",
        href: "/home/scholarship",
        icon: "bi bi-bell",
      },
      {
        title: "Networking",
        href: "/home/networking",
        icon: "bi bi-bell",
      },
      {
        title: "Tuition fees",
        href: "/home/tuitionfees",
        icon: "bi bi-bell",
      },
      {
        title: "Champions life",
        href: "/home/championslife",
        icon: "bi bi-bell",
      },
      {
        title: "Partnership",
        href: "/home/partnership",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "About",
    children: [
      {
        title: "Intro History",
        href: "/about/introhistory",
        icon: "bi bi-bell",
      },
      {
        title: "University History",
        href: "/about/universityhistory",
        icon: "bi bi-bell",
      },
      {
        title: "News Page",
        href: "/about/newspage",
        icon: "bi bi-bell",
      },
      {
        title: "Faculty Intro",
        href: "/about/facultyintro",
        icon: "bi bi-bell",
      },
      {
        title: "Faculty List",
        href: "/about/facultylist",
        icon: "bi bi-bell",
      },
      {
        title: "About Intro",
        href: "/about/aboutIntro",
        icon: "bi bi-bell",
      },
      {
        title: "Campus",
        href: "/about/campus",
        icon: "bi bi-bell",
      },
      {
        title: "Organization info",
        href: "/about/orginfo",
        icon: "bi bi-bell",
      },
      {
        title: "History Details",
        href: "/about/historydetails",
        icon: "bi bi-bell",
      }, {
        title: "News And Events",
        href: "/about/newsandevent",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "Common",
    children: [
      {
        title: "Missions & Values",
        href: "/common/missions-and-values",
        icon: "bi bi-bell",
      },
      {
        title: "View application forms",
        href: "/common/applicationform",
        icon: "bi bi-bell",
      },
      {
        title: "View Scholarship forms",
        href: "/common/scholarshipform",
        icon: "bi bi-bell",
      },
      {
        title: "Student Type",
        href: "/common/addstudenttype",
        icon: "bi bi-bell",
      },
      {
        title: "Current Status",
        href: "/common/addcurrentstatus",
        icon: "bi bi-bell",
      },
      {
        title:"Area of Study",
        href:"/common/areaofstudyanddegree",
        icon:"bi bi-bell"
      },
      {
        title:"Degree Level",
        href:"/common/degress",
        icon:"bi bi-bell"
      }
    ],
  },
  {
    title: "Partnership",
    children: [
      {
        title: "Partnership Intro",
        href: "/partnership/partnershipintro",
        icon: "bi bi-bell",
      },
      {
        title: "Partnership Degree",
        href: "/partnership/partnershipdegree",
        icon: "bi bi-bell",
      },
    ],
  },
  , {
    title: "Gallery",
    children: [
      {
        title: "Gallery Intro",
        href: "/gallery/galleryintro",
        icon: "bi bi-bell",
      },
      {
        title: "Gallery Images",
        href: "/gallery/galleryimages",
        icon: "bi bi-bell",
      },
      
    ],
  },
  ,
  {
    title: "Champions",
    children: [
      {
        title: "Champions Intro",
        href: "/champions/championsintro",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "contact",
    children: [
      {
        title: "Contact Intro",
        href: "/contact/contactIntro",
        icon: "bi bi-bell",
      },
      {
        title: "Contact Details",
        href: "/contact/contactdetails",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "viewcontacts",
    children: [
      {
        title: "All Contacts",
        href: "/viewcontacts/contacts",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "Our goal",
    children: [
      {
        title: "Our Goal Intro",
        href: "/ourgoal/goalintro",
        icon: "bi bi-bell",
      },
      {
        title: "President Goal",
        href: "/ourgoal/presidentgoal",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "Scholarship And Grants",
    children: [
      {
        title: "Scholarship and Intro",
        href: "/scholarship/scholarshipandgrants",
        icon: "bi bi-bell",
      },
      {
        title: "Registration Info",
        href: "/scholarship/registrationinfo",
        icon: "bi bi-bell",
      },
      {
        title: "Scholarship Overview",
        href: "/scholarship/scholarshipoverview",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "Center of Excellence",
    children: [
      {
        title: "Add CIE intro",
        href: "/centerofexcellence/cieIntro",
        icon: "bi bi-bell",
      },
      {
        title: "Add Apply CIE",
        href: "/centerofexcellence/applyCEI",
        icon: "bi bi-bell",
      },
      {
        title: "Delivery Mode",
        href: "/centerofexcellence/deliveryMode",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "Donation",
    children: [
      {
        title: "Donation intro",
        href: "/donation/donationIntro",
        icon: "bi bi-bell",
      },
      {
        title: "Donation Form",
        href: "/donation/donationForm",
        icon: "bi bi-bell",
      },
      {
        title: "View Donations",
        href: "/donation/view-donation",
        icon: "bi bi-bell",
      },
    ],
  },
  {
    title: "Admission",
    children: [
      {
        title: "Admission DropDown",
        href: "/admissions/admissionDropDown",
        icon: "bi bi-bell",
      },
      {
        title: "Admission Details",
        href: "/admissions/admissionDetail",
        icon: "bi bi-bell",
      },
    ],
  },
  // {
  //   title: "Alert",
  //   href: "/alerts",
  //   icon: "bi bi-bell",
  // },
  // {
  //   title: "Badges",
  //   href: "/badges",
  //   icon: "bi bi-patch-check",
  // },
  // {
  //   title: "Buttons",
  //   href: "/buttons",
  //   icon: "bi bi-hdd-stack",
  // },
  // {
  //   title: "Cards",
  //   href: "/cards",
  //   icon: "bi bi-card-text",
  // },
  // {
  //   title: "Grid",
  //   href: "/grid",
  //   icon: "bi bi-columns",
  // },
  // {
  //   title: "Table",
  //   href: "/table",
  //   icon: "bi bi-layout-split",
  // },
  // {
  //   title: "Forms",
  //   href: "/forms",
  //   icon: "bi bi-textarea-resize",
  // },
  // {
  //   title: "Breadcrumbs",
  //   href: "/breadcrumbs",
  //   icon: "bi bi-link",
  // },
  // {
  //   title: "About",
  //   href: "/about",
  //   icon: "bi bi-people",
  // },
];

const Sidebar = () => {
  const [open, setOpen] = useState("0");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  let location = useLocation();

  return (
    <div className="p-3">
      <div style={{background:'#03173a'}} className="d-flex align-items-center">
        {/* <Logo /> */}
        {/* <img /> */}
        {/* <logoimg /> */}
        <img src={logoimg} width="150" height="100" />
        <Button
          close
          size="sm"
          className="ms-auto d-lg-none"
          onClick={() => showMobilemenu()}
        ></Button>
      </div>
      <div className="pt-4 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((list, i) => (
            <Accordion
              open={open}
              flush
              toggle={toggle}
              style={{ backgroundColor: "transparent" }}
            >
              <AccordionItem>
                <AccordionHeader targetId={`${i + 1}`}>
                  {list.title}
                </AccordionHeader>
                <AccordionBody
                  accordionId={`${i + 1}`}
                  style={{ width: "200px" }}
                >
                  {list.children.map((navi, index) => (
                    <NavItem key={index} className="sidenav-bg">
                      <Link
                        to={navi.href}
                        className={
                          location.pathname === navi.href
                            ? "text-primary nav-link py-3"
                            : "nav-link text-secondary py-3"
                        }
                      >
                        <i className={navi.icon}></i>
                        <span className="ms-3 d-inline-block">
                          {navi.title}
                        </span>
                      </Link>
                    </NavItem>
                  ))}
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          ))}

          {/* <Button
            color="danger"
            tag="a"
            target="_blank"
            className="mt-3"
            href="https://www.wrappixel.com/templates/xtreme-react-redux-admin/?ref=33"
          >
            Upgrade To Pro
          </Button> */}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
