import React, { useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import AddAdminationDetail from "../../components/dashboard/Admission/AdmissionDetail/AddAdminationDetail";
import ViewAdminationDetail from "../../components/dashboard/Admission/AdmissionDetail/viewAdminationDetail";


export default function AdmissionDetails() {
  const [add ,setAdd]=useState(1)
  return (
    <>
      <AddAdminationDetail add={add} setAdd={setAdd}/>
      {/* <ViewAdminationDetail add={add} setAdd={setAdd}  /> */}
    </>
  );
}
