import React, { useState } from "react";
import AddCurrentStatus from "./AddCurrentStatus";
import ViewCurrentStatus from "./ViewCurrentStatus";


export default function CurrentStatus(){
    const[add,setAdd]=useState(0)
    


    return(
        <>
        <AddCurrentStatus add={add} setAdd={setAdd}/>
        <ViewCurrentStatus added={add} />
        </>
    )
}