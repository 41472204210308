import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import {
  applyEdit,
  facultyListEdit,
  facultyListImgUpload,
  introHistoryUpload,
  universityHistoryEdit,
  uploadHomeImage,
} from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";
import { Button, Form, Input, Radio } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

function AddFacultyList({ added, setAdded }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const [applyId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });
  const [fields, setFields] = useState(() => {
    if (location.state) {
      const arr = location.state.list.map((val, i) => {
        return {
          id: i + 1,
          name: val.name,
          desc: val.desc,
          image: val.image,
          designation: val.designation,
          mobile: val.mobile,
          email: val.email,
        };
      });
      return arr;
    } else {
      return [
        {
          id: 1,
          name: "",
          desc: "",
          image: "",
          designation: "",
          mobile: "",
          email: "",
        },
      ];
    }
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState(() => {
    if (location.state) {
      return {
        title: location.state.title,
        list: location.state.list.map((val, i) => {
          return {
            name: val.name,
            desc: val.desc,
            image: val.image,
            designation: val.designation,
            mobile: val.mobile,
            email: val.email,
            id: i + 1,
          };
        }),
      };
    } else {
      return {
        title: "",
        list: [],
      };
    }
  });
  const handleKeyValueAdd = () => {
    if (fields && fields.length > 0) {
      const lastKeyValue = fields[fields.length - 1];
      const newKeyValue = {
        id: lastKeyValue.id + 1,
        name: "",
        desc: "",
        image: "",
        designation: "",
        mobile: "",
        email: "",
      };
      setFields([...fields, newKeyValue]);
    } else {
      setFields([
        {
          id: 1,
          name: "",
          desc: "",
          image: "",
          designation: "",
          mobile: "",
          email: "",
        },
      ]);
    }
  };

  useEffect(() => {
    console.log(submitValues);
  }, [submitValues]);

  const handleChange = (id, ev, index, str) => {
    const found = fields.find((f) => f.id === id);
    if (str === "desc") {
      found.desc = ev.target.value;
    } else if (str === "name") {
      found.name = ev.target.value;
    } else if (str === "designation") {
      found.designation = ev.target.value;
    } else if (str === "email") {
      found.email = ev.target.value;
    } else if (str === "mobile") {
      found.mobile = ev.target.value;
    }
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, list: oldFields });
  };

  const handleImgValueChange = async (id, ev, index) => {
    // submitLoading(true)
    setSubmitLoading(true);
    let data = new FormData();
    data.append("image", ev.target.files[0]);
    console.log(data, "daaaaa");
    const result = await facultyListImgUpload(data);
    setSubmitLoading(false);
    if (result && result.status) {
      const found = fields.find((f) => f.id === id);
      found.image = result.url;
      let oldFields = [...fields];
      oldFields.splice(index, 1, found);
      setFields(oldFields);
      setSubmitValues({ ...submitValues, list: oldFields });
      toast.success("Image uploaded successfully");
    }
  };

  const handleRemoveKeyValue = (idx) => {
    const oldFields = [...fields];
    const firstArr = oldFields.slice(0, idx);
    const lastArr = oldFields.slice(idx + 1);
    const newlastArr = lastArr.map((val) => {
      val.id = val.id - 1;
      return val;
    });
    const fieldsToSet = [...firstArr, ...newlastArr];
    setFields(fieldsToSet);
    setSubmitValues({ ...submitValues, list: fieldsToSet });
  };

  const handleNameChange = (e, f) => {
    const found = fields.find((fi) => fi.id === f.id);
    const index = fields.findIndex((fi) => fi.id === f.id);
    console.log(found);
    console.log(index);
    found.name = e.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, list: oldFields });
  };

  const handleElementAdd = async () => {
    console.log(submitValues);

    const { title, list } = submitValues;
    console.log(list);
    if (!title) {
      toast.error("Title must not be blank");
      return title;
    }
    if (list.length === 0) {
      toast.error("Please add faculty details");
      return;
    }

    // const { heading, history } = submitValues;

    if (!location.state) {
      try {
        setSubmitLoading(true);
        const res = await HttpClient.requestData(
          "addFacultyList",
          "POST",
          submitValues
        );
        console.log(res);
        if (res && res.status) {
          setAdded(++added);
          setSubmitValues({
            title: "",
            list: [],
          });
          setFields([
            {
              id: 1,
              name: "",
              desc: "",
              image: "",
              mobile: "",
              email: "",
              designation: "",
            },
          ]);
          setSubmitLoading(false);
          toast.success("Faculty List added successfully");
        } else {
          setSubmitLoading(false);
          toast.error(res.message);
        }
      } catch (err) {
        console.log(err);
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(true);
      const res = await facultyListEdit(applyId, submitValues);
      setSubmitLoading(false);
      if (res && res.status) {
        toast.success("Faculty List edited successfully");
        navigate(-1);
      }
      //   console.log(sendData,applyId)
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Faculty list
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter name
            </label>
            <input
              type="text"
              id="title"
              className="form-control mt-3 mb-4"
              placeholder="Enter title"
              value={submitValues.title}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, title: e.target.value });
              }}
            />
            <div className="d-flex justify-content-start align-items-center flex-wrap">
              {fields &&
                fields.map((f, i) => (
                  <Card
                    style={{
                      maxWidth: "18rem",
                    }}
                    className="mx-2"
                  >
                    <CardBody>
                      <div className="d-flex justify-content-around">
                        <CardTitle tag="h5" className="mt-2">
                          Card title
                        </CardTitle>
                        {i !== 0 && (
                          <button
                            className="btn btn-danger btn-sm"
                            style={{ maxHeight: "40px" }}
                            onClick={() => handleRemoveKeyValue(i)}
                          >
                            <i
                              className="bi bi-x-circle"
                              style={{ fontSize: "15px" }}
                            ></i>
                          </button>
                        )}
                      </div>

                      {/*  */}

                      {/*  */}
                      <div
                        key={i}
                        style={{ maxWidth: "100%" }}
                        // className="d-flex flex-row flex-wrap"
                      >
                        <input
                          type={"text"}
                          className="form-control mt-3 me-3"
                          placeholder="Enter name"
                          value={f.name}
                          onChange={(e) => handleChange(f.id, e, i, "name")}
                        />
                        <input
                          type={"number"}
                          className="form-control mt-3 me-3"
                          placeholder="Enter mobile"
                          value={f.mobile}
                          onChange={(e) => handleChange(f.id, e, i, "mobile")}
                        />
                        <input
                          type={"email"}
                          className="form-control mt-3 me-3"
                          placeholder="Enter email"
                          value={f.email}
                          onChange={(e) => handleChange(f.id, e, i, "email")}
                        />
                        <input
                          type={"text"}
                          className="form-control mt-3 me-3"
                          placeholder="Enter designation"
                          value={f.designation}
                          onChange={(e) =>
                            handleChange(f.id, e, i, "designation")
                          }
                        />
                        <textarea
                          className="form-control mt-3 me-3"
                          placeholder="Enter desc"
                          value={f.desc}
                          rows={"2"}
                          onChange={(e) => handleChange(f.id, e, i, "desc")}
                        />
                        <div className="d-flex flex-column">
                          <input
                            type="file"
                            className="form-control mt-3 me-3"
                            placeholder="upload image"
                            onChange={(e) => handleImgValueChange(f.id, e, i)}
                          />
                          {f.image && (
                            <img
                              className="mx-auto mt-2"
                              style={{
                                height: "auto",
                                width: 100,
                                height: 100,
                              }}
                              src={f.image}
                            />
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : location.state ? (
                  "Edit Faculty List"
                ) : (
                  "Add Faculty List"
                )}
              </button>
              <button
                className="btn btn-secondary mt-3 me-2"
                onClick={handleKeyValueAdd}
              >
                Add Faculty
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddFacultyList;
