import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Button } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");

  console.log("name , email, password,address", name, email, password, address);
  const updateProfile = async () => {
    let res = await HttpClient.requestData("update", "PUT", {
      name,
      email,
      password,
      address,
    });
    console.log("res", res);
    if (res && res.status) {
      setName("");
      setEmail("");
      setPassword("");
      setAddress("");
      navigate("/");
      toast.success(res.message);
    } else {
      console.log('typeof res.error', typeof res.error)
      if (typeof res.error != "object") {
        toast.error(res.error);
      } else {
        toast.error(res.message);
      }
    }
  };
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img
            src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
            alt="Login"
          />
        </div>
        <Form name="login-form">
          {/* <p className="form-title">Welcome back</p> */}
          <p>Edit Your Profile</p>
          <Form.Item
            name="text"
            // rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input
              placeholder="Name"
              onChange={(val) => setName(val.target.value)}
              //   defaultValue={?.name}
            />
          </Form.Item>
          <Form.Item
            name="email"
            // rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              placeholder="Email"
              onChange={(val) => setEmail(val.target.value)}
              //   defaultValue={admminData?.email}
            />
          </Form.Item>
          <Form.Item
            name="password"
            // rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              //   defaultValue={admminData?.password}
              onChange={(val) => setPassword(val.target.value)}
            />
          </Form.Item>{" "}
          <Form.Item
            name="text"
            // rules={[{ required: true, message: "Please input your address!" }]}
          >
            {console.log("name", address)}
            <Input
              placeholder="Address"
              //   defaultValue={admminData?.address}
              onChange={(val) => setAddress(val.target.value)}
            />
          </Form.Item>
          {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}
          <Form.Item>
            <Button
              onClick={updateProfile}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Update
            </Button>
          </Form.Item>
          {/* <p>
            Don't Have an account ?{" "}
            <Link to="/register" style={{ textDecoration: "none" }}>
              Register here
            </Link>
          </p> */}
        </Form>
      </div>
    </div>
  );
};

export default Profile;
