import React, { useRef, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { postSectionImg, sectionImageUpload, sectionImgEdit } from "../../../Api/api";

function AddSection({ setAdded, added }) {
  const inputRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const [editId] = useState(() => {
    if (location.state) {
      return location.state._id
    } else {
      return null
    }
  })
  const [initialValues, setInitialValues] = useState(() => {
    if (location.state) {
      return {
        heading: location.state?.heading,
        subheading: location.state?.subheading,
        image: location.state?.image
      }
    } else {
      return null
    }
  })
  const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
    if (location.state) {
      return location.state.image
    } else {
      return ""
    }
  });
  const [loading, setLoading] = useState(false);


  const onSubmit = async () => {
    console.log(uploadedImgUrl)
    // const { image } = data;
    if (!uploadedImgUrl) {
      toast.error("Please upload an image");
      //return;
    }
    let sendData = {
      heading: initialValues.heading,
      subheading: initialValues.subheading,
      image: uploadedImgUrl,
    };

    if (!editId) {
      console.log(sendData)
      // return
      setLoading(true);
      const res = await postSectionImg(sendData);
      setLoading(false);
      console.log(res);
      if (res && res.status) {
        toast.success("Image section added successfully");
        setUploadedImgUrl("");
        inputRef.current.value = ""
        setAdded(added + 1)
      }
    } else {
      console.log(sendData, editId)
      // return
      setLoading(true)
      const res = await sectionImgEdit(editId, sendData)
      setLoading(false)
      if (res && res.status) {
        toast.success("Image section edited successfully")
        navigate(-1)
      } else {
        toast.error("Something went wrong please try again later")
      }
    }
  };

  const handleImageUpload = async (e) => {
    let data = new FormData();
    data.append("image", e.target.files[0]);
    console.log(data, "daaaaa");
    setLoading(true);
    const result = await sectionImageUpload(data);
    setLoading(false);
    console.log(result);
    if (result && result.status) {
      setUploadedImgUrl(result.url);
      //   setInitialValues({image:result.url})
      toast.success("Image successfully uploaded");
    } else {
      toast.error("Something went wrong,please try again");
    }
  };

  const setThisFormData = (e) => {
    e.preventDefault()
    setInitialValues({ ...initialValues, [e.target.name]: e.target.value })
  }

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Image section
            </CardTitle>

            <label htmlFor="heading" className="mt-3">
              Enter heading
            </label>
            <input
              type="text"
              id="heading"
              name="heading"
              defaultValue={initialValues ? initialValues.heading : ""}
              className="form-control mt-3 mb-1"
              placeholder="Enter heading"
              onChange={(e) => setThisFormData(e)}
            />

            <label htmlFor="heading" className="mt-3">
              Enter subheading
            </label>
            <input
              type="text"
              id="subheading"
              name="subheading"
              defaultValue={initialValues ? initialValues.subheading : ""}
              className="form-control mt-3 mb-1"
              placeholder="Enter subheading"
              onChange={(e) => setThisFormData(e)}
            />

            <div className="mb-1 mt-3">
              {uploadedImgUrl && (
                <img
                  style={{ height: "auto", width: 100, height: 100 }}
                  src={uploadedImgUrl}
                />
              )}
            </div>
            <div className="mb-3">
              <label for="formFile" className="form-label mt-3">
                Add an image
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                id="formFile"
                ref={inputRef}
                onChange={handleImageUpload}
              />
              <br />
            </div>
            <div className="d-flex justify-content-between">
              <button className="btn btn-primary mt-3" disabled={loading} onClick={onSubmit}>
                {loading ? <Loader /> : editId ? "Edit Image section" : "Add Image section"}
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddSection;
