import React, { useEffect, useState } from "react";
// import HttpClient from "../../utils/HttpClient";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";

import { MDBDataTable } from "mdbreact";
import HttpClient from "../../../utils/HttpClient";
import { Value } from "sass";
import { toast } from "react-hot-toast";
import { bannerDelete, championsDelete, experienceDelete, welcomeDelete } from "../../../Api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import ReactPlayer from "react-player";

function ViewChampions({added}) {
  const navigate = useNavigate();
  const [champions, setChampions] = useState([]);
  const [Loading, setLoading] = useState(false);
  // const [elementFieldType,setelementFieldType] = useState("")

  const data = {
    columns: [
      {
        label: "Page Name",
        field: "pageName",
        center: true,
      },
      {
        label: "Short Description",
        field: "shortDesc",
        center: true,
        sort: "disabled",
      },
      {
        label: "Long Description",
        field: "longDesc",
        center: true,
        sort: "disabled",
      },
      {
        label: "Question",
        field: "question",
        center: true,
        sort: "disabled",
      },
      {
        label: "Answer",
        field: "answer",
        center: true,
        sort: "disabled",
      },
      {
        label: "Image",
        field: "bgImage",
        center: true,
        sort: "disabled",
      },
      {
        label: "Video",
        field: "bgVideo",
        center: true,
        sort: "disabled",
      },
      {
        label: "Action",
        field: "action",
        center: true,
        sort: "disabled",
      },
    ],
    rows: champions,
  };

  useEffect(() => {
    fetchAllChampionsIntro();
  }, [added]);

  async function fetchAllChampionsIntro() {
    setLoading(true);
    try {
      const res = await HttpClient.requestData("viewChampionsIntro", "GET", {});
      console.log(res);
      if (res.status) {
        const data = res.data.map((val, i) => {
          return {
            ...val,
            shortDesc:(<div dangerouslySetInnerHTML={{__html:val.shortDesc}}></div>),
            longDesc:(<div dangerouslySetInnerHTML={{__html:val.longDesc}}></div>),
            answer:(<div dangerouslySetInnerHTML={{__html:val.answer}}></div>),
            action: (
              <div>
                <Button color="info" onClick={() => hanldleEdit(val)}>
                  Edit
                </Button>
                <Button color="danger" onClick={() => hanldleDelete(val._id)}>
                  Delete
                </Button>
              </div>
            ),
            bgImage: (
              <img
                style={{ height: "auto", width: 200, margin: 5 }}
                src={val.bgImage}
              />
            ),
            bgVideo:(
              <ReactPlayer url={val.video} controls={true} width={360} height={150} />
            )
          };
        });
        console.log(data);
        setChampions(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  if (Loading) {
    return (
       <Loader />
    );
  }

  const hanldleEdit = (val) => {
     navigate("/champions/championsintroedit",{state:val})
     console.log(val)
  };

  const hanldleDelete = async(id) => {
    Swal.fire({
        title: 'Are you sure you want to delete this item?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async(result) => {
        if (result.isConfirmed) {
            const res = await championsDelete(id);
            console.log(res);
            if(res && res.status){
                Swal.fire(
                    'Deleted!',
                    'Item has been deleted.',
                    'success'
                  )
                fetchAllChampionsIntro();
            }else{
                toast.error("something went wrong! please try again later")
            }
        
        }
      })

    console.log(id);
  };

  return (
    <div className="container">
      <Col>
        <Row>
          <Card>
            <CardBody>
              <CardTitle tag="h5">Champions Intro</CardTitle>
              <MDBDataTable responsive bordered data={data} autoWidth />
            </CardBody>
          </Card>
        </Row>
      </Col>
    </div>
  );
}



export default ViewChampions