import React from 'react'
import { Navigate,Outlet} from 'react-router-dom'
const PrivateRoute = () => {
    let user = localStorage.getItem("userAdminData");
    if (user) {
        return <Outlet />;
      } else {
        return <Navigate to={"/login"} />;
      }
}

export default PrivateRoute