import { Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import HttpClient from "../../../../utils/HttpClient";
import Field from "./Comp/Field";

const Recurring = ({ label, placeholder }) => {
  const [recurring, setRecurring] = useState([]);
  const [addRecurring, setAddRecurring] = useState("");
  const [editEnable, setEditEnable] = useState(false);
  const [editId, setEditId] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(`view-recurring`, "GET", {});

    if (result && result.status) {
      setRecurring(result.data); // set data
    } else {
      toast.error(result.error);
    }
  };
  const onDelete = async (id) => {
    let res = await HttpClient.requestData(`delete-recurring/${id}`, "PUT");
    if (res && res.status) {
      getData();
      toast.success(res.message);
    } else {
      toast.error(res.error);
    }
  };
  const addData = async () => {
    // console.log("addRecurring", addRecurring);
    if (editEnable) {
      let data = {
        recurring: addRecurring,
      };
      console.log("data", data);
      let res = await HttpClient.requestData(
        "update-recurring/" + editId,
        "PUT",
        data
      );
      if (res && res.status) {
        setAddRecurring("");
        setEditEnable(false);
        setEditId("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    } else {
      let data = {
        recurring: addRecurring,
      };
      console.log("data", data);
      let res = await HttpClient.requestData("addRecurring", "POST", data);
      if (res && res.status) {
        setAddRecurring("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    }
  };
  //   console.log("addRecurring", addRecurring);

  const handleEdit = (data, id) => {
    setAddRecurring(data);
    setEditEnable(true);
    setEditId(id);
  };
  return (
    <Field
      state={recurring}
      setState={setRecurring}
      keyName="recurring"
      label={label}
      placeholder={placeholder}
      handleDelete={onDelete}
      addNew={setAddRecurring}
      addData={addData}
      inputValue={addRecurring}
      handleEdit={handleEdit}
      editEnable={editEnable}
    />
  );
};

export default Recurring;
