import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { introHistoryUpload, addNewsPost, editNewsPost, postNews, postPartnershipIntro, editPartnerIntro, partnershipIntroImgUpload, championsIntroImageUpload, homeVideoUpload, postChampionsIntro, editChampionsIntro } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import ReactPlayer from "react-player";
import toolbarConfig from "../../ReactRte/Index";

function AddChampionsIntro({ setAdded, added }) {
    const imgRef = useRef();
    const videoRef = useRef();
    const navigate = useNavigate()
    const location = useLocation()
    const [editId] = useState(() => {
        if (location.state) {
            return location.state._id
        } else {
            return null
        }
    })

    const [editorVal, setEditorVal] = useState(() => {
        if (location.state) {
            return {
                longDesc: RichTextEditor.createValueFromString(location.state.longDesc, "html"),
                shortDesc: RichTextEditor.createValueFromString(location.state.shortDesc, "html"),
                answer: RichTextEditor.createValueFromString(location.state.answer, "html"),
            }
        } else {
            return {
                longDesc: RichTextEditor.createEmptyValue(),
                shortDesc: RichTextEditor.createEmptyValue(),
                answer: RichTextEditor.createEmptyValue()
            }
        }
    })
    const [initialValues] = useState(() => {
        if (location.state) {
            return {
                pageName: location.state.pageName,
                shortDesc: location.state.shortDesc,
                longDesc: location.state.longDesc,
                question: location.state.question,
                answer: location.state.answer,
                bgImage: location.state.bgImage,
                video: location.state.video
            }
        } else {
            return null
        }
    })
    const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
        if (location.state) {
            return location.state.bgImage
        } else {
            return ""
        }
    });

    const [uploadedVideoUrl, setuploadedVideoUrl] = useState(() => {
        if (location.state) {
            return location.state.video
        } else {
            return ""
        }
    });
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const { pageName, question } = data;
        // if (!uploadedImgUrl) {
        //     toast.error("Please upload an image");
        //     return;
        // }
        // let sendData = {
        //     pageName,
        //     shortDesc,
        //     question,
        //     answer,
        //     bgImage: uploadedImgUrl,
        // };

        if (!pageName || !question || !uploadedImgUrl || !uploadedVideoUrl ||
            !editorVal.longDesc || !editorVal.shortDesc || !editorVal.answer
        ) {
            toast.error("Please enter all fields");
            return
        }


        const sendData = {
            shortDesc: editorVal.shortDesc.toString("html"),
            longDesc: editorVal.longDesc.toString("html"),
            answer: editorVal.answer.toString("html"),
            question,
            pageName,
            bgImage: uploadedImgUrl,
            video: uploadedVideoUrl
        }

        console.log(sendData)

        if (!initialValues) {
            setLoading(true);
            const res = await postChampionsIntro(sendData);
            setLoading(false);
            console.log(res);
            if (res && res.status) {
                toast.success("Champions intro added successfully");
                // reset();
                // setUploadedImgUrl("");
                // setuploadedVideoUrl("");
                // // setEditorVal({
                // //     longDesc: RichTextEditor.createEmptyValue(),
                // //     shortDesc: RichTextEditor.createEmptyValue(),
                // //     answer: RichTextEditor.createEmptyValue()
                // // })
                // imgRef.current.value = "";
                // videoRef.current.value = ""
                setAdded(added + 1)
            } else {
                toast.error(res.message)
            }
        } else {
            setLoading(true)
            const res = await editChampionsIntro(editId, sendData)
            setLoading(false)
            if (res && res.status) {
                toast.success("News edited successfully")
                navigate(-1)
            } else {
                toast.error("Something went wrong please try again later")
            }
        }
    };

    const handleImageUpload = async (e) => {
        let data = new FormData();
        data.append("image", e.target.files[0]);
        console.log(data, "daaaaa");
        setLoading(true);
        const result = await championsIntroImageUpload(data);
        setLoading(false);
        console.log(result);
        if (result && result.status) {
            setUploadedImgUrl(result.url);
            toast.success("Image successfully uploaded");
        } else {
            toast.error("Something went wrong,please try again");
        }
    };

    const handleVideoUpload = async (e) => {
        let data = new FormData();
        data.append("video", e.target.files[0]);
        console.log(data, "daaaaa");
        setLoading(true);
        const result = await homeVideoUpload(data);
        console.log(result)
        setLoading(false);
        console.log(result);
        if (result && result.status) {
            setuploadedVideoUrl(result.originalUrl);
            toast.success("Video successfully uploaded");
        } else {
            toast.error("Something went wrong,please try again");
        }
    }

    const handleEditorShortDescChange = (value) => {
        setEditorVal({
            ...editorVal,
            shortDesc: value
        })
    };

    const handleEditorLongDescChange = (value) => {
        setEditorVal({
            ...editorVal,
            longDesc: value
        })
    }

    const handleEditorAnswerDescChange = (value) => {
        setEditorVal({
            ...editorVal,
            answer: value
        })
    }
    useEffect(() => {
        console.log(editorVal)
    }, [editorVal])

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                            <i className="bi bi-bell me-2"> </i>
                            Add Champions intro
                        </CardTitle>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="heading" className="mt-3">
                                Enter Page Name
                            </label>
                            <input
                                type="text"
                                id="heading"
                                defaultValue={initialValues ? initialValues.pageName : ""}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter Page name"
                                {...register("pageName", { required: true })}
                            />
                            {errors.pageName && (
                                <span className="mt-2 text-danger">
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="elemdesc" className="mt-3">
                                Enter short description
                            </label>
                            <RichTextEditor
                            toolbarConfig={toolbarConfig}
                                id="elemdesc"
                                value={editorVal.shortDesc}
                                onChange={handleEditorShortDescChange}
                            />
                            <br />
                            <label htmlFor="elemdesc" className="mt-3">
                                Enter long description
                            </label>
                            <RichTextEditor
                            toolbarConfig={toolbarConfig}
                                id="elemdesc"
                                value={editorVal.longDesc}
                                onChange={handleEditorLongDescChange}
                            />
                            <br />
                            {/* <textarea
                                defaultValue={initialValues ? initialValues.shortDesc : ""}
                                id="elemTitle"
                                rows={"2"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter short description"
                                {...register("shortDesc", { required: true })}
                            />
                            {errors.shortDesc && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br /> */}
                            <label htmlFor="elemTitle" className="mt-3">
                                Enter question
                            </label>
                            <textarea
                                defaultValue={initialValues ? initialValues.question : ""}
                                id="elemTitle"
                                rows={"2"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter short description"
                                {...register("question", { required: true })}
                            />
                            {errors.question && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br />

                            <label htmlFor="elemans" className="mt-3">
                                Enter answer
                            </label>
                            <RichTextEditor
                            toolbarConfig={toolbarConfig}
                                id="elemans"
                                value={editorVal.answer}
                                onChange={handleEditorAnswerDescChange}
                            />
                            {/* <textarea
                                defaultValue={initialValues ? initialValues.answer : ""}
                                id="elemTitle"
                                rows={"2"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter short description"
                                {...register("answer", { required: true })}
                            />
                            {errors.answer && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )} */}
                            <br />


                            <div className="mb-1 mt-3">
                                {uploadedImgUrl && (
                                    <img
                                        style={{ height: "auto", width: 300, height: 150 }}
                                        src={uploadedImgUrl}
                                    />
                                )}
                            </div>
                            <div className="mb-3">
                                <label for="formFile" className="form-label mt-3">
                                    Add an image
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    accept="image/*"
                                    id="formFile"
                                    {...register("bgImage")}
                                    onChange={handleImageUpload}
                                />
                                {/* {errors.image && (
                  <span className="mt-2 text-danger">
                    {" "}
                    * This field is required
                  </span>
                )} */}
                                <br />
                            </div>
                            <div className="mb-1 mt-3">
                                {uploadedVideoUrl && (
                                    // <img
                                    //   style={{ height: "auto", width: 100, height: 100 }}
                                    //   src={uploadedImgUrl}
                                    // />
                                    <ReactPlayer url={uploadedVideoUrl} width={"640px"} height={"360px"} controls={true} />
                                )}
                            </div>
                            <div className="mb-3">
                                <label for="formFile" className="form-label mt-3">
                                    Add an video
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    accept="video/*"
                                    id="formFile"
                                    onChange={handleVideoUpload}
                                    ref={videoRef}
                                />
                                <br />
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary mt-3" disabled={loading}>
                                    {loading ? <Loader /> : initialValues ? "Edit Champions Intro" : "Add Champions Intro"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AddChampionsIntro;
