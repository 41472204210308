import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  postAreaOfStudy,
  postDegreeLevel,
  postStudentType,
} from "../../Api/api";
import HttpClient from "../../utils/HttpClient";
import Item from "antd/es/list/Item";
import { Card, Row, Col, CardTitle, CardBody, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";

function Degrees({}) {
  const [areaOfStudy, setareaOfStudy] = useState([]);
  const [areaOfStudyId, setAreaofStudyId] = useState("");
  const [degreeLevel, setdegreeLevel] = useState("");
  const [dissableButton, setDissableButton] = useState(true);
  const [allData, setAllData] = useState([]);
  const [editEnable, seteditEnable] = useState(false);
  const [editId, setEditId] = useState("");
  //   const handleSubmit = async () => {
  //     if (!areaOfStudy) {
  //       toast.error("Please provide area of study value");
  //       return;
  //     }
  //     const res = await postAreaOfStudy({ areaOfStudy: areaOfStudy });
  //     console.log(res);
  //     if (res && res.status) {
  //       areaofstudyID.current = res.data;
  //       toast.success("Area of study added successfully");
  //       setareaOfStudy("");
  //     } else {
  //       toast.error("Something went wrong please try again");
  //     }
  //   };

  const handleSubmitdegree = async () => {
    if (areaOfStudyId === "") {
      toast.error("Please Slecct an area of study first");
      return;
    }
    if (!degreeLevel) {
      toast.error("Enter a degree level");
      return;
    }
    console.log({
      areaOfStudy: areaOfStudyId,
      degreeLevel,
    });
    let res;
    if (editEnable) {
      res = await HttpClient.requestData(`edit-degree-level/${editId}`, "PUT", {
        areaOfStudy: areaOfStudyId,
        degreeLevel,
      });

      // "edit api"
    } else {
      res = await postDegreeLevel({
        areaOfStudy: areaOfStudyId,
        degreeLevel,
      });
    }

    if (res && res.status) {
      setEditId("");
      seteditEnable(false);
      fetchDegreeLevels()
      //   areaofstudyID.current = null;
      setdegreeLevel("");
      setAreaofStudyId("");
      toast.success("Action successfully");
    }
  };

  const fetchAreaOfStudy = async () => {
    let result = await HttpClient.requestData("addAreaOfStudy", "GET");

    // console.log("areaofstudy", result);

    if (result && result.status) {
      setareaOfStudy(result.data);
    }
  };

  const fetchDegreeLevels = async () => {
    let result = await HttpClient.requestData("view-degree-level", "GET");
    console.log("res", result);
    if (result && result.status) {
      let arr = result.data.map((item) => {
        return {
          ...item,
          action: (
            <div>
              <Button
                color="info"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  setdegreeLevel(item.degreeLevel);
                  setAreaofStudyId(item.areaOfStudy);
                  seteditEnable(true);
                  setEditId(item._id);
                }}
              >
                Edit
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteHandle(item._id);
                    }
                  });
                }}
              >
                Delete
              </Button>
            </div>
          ),
        };
      });
      setAllData(arr);
    }
  };
  useEffect(() => {
    fetchAreaOfStudy();
    fetchDegreeLevels();
    // fetchF
  }, []);

  const data = {
    columns: [
        {
          label: "Area Of Study",
          field: "Areaofstudies",
          center: true,
        },
      {
        label: "Degree Lable",
        field: "degreeLevel",
        center: true,
      },
      {
        label: "Action",
        field: "action",
        center: true,
        sort: "disabled",
      },
    ],
    rows: allData,
  };

  const deleteHandle = async (id) => {
    let result = await HttpClient.requestData(
      `delete-degree-level/${id}`,
      "DELETE"
    );
    if (result && result.status) {
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    //   fetchAreaOfStudy();
      fetchDegreeLevels()
    }
  };
  return (
    <>
      <div className="form-group">
        <label for="exampleInputEmail1">Select Area of Study</label>
        <select
          className="form-select"
          aria-label="Default select example"
          value={areaOfStudyId}
          onChange={(val) => setAreaofStudyId(val.target.value)}
        >
          <option value="">Select Area of Study</option>
          {areaOfStudy.map((Item) => {
            return (
              <option key={Item._id} value={Item._id}>
                {Item.areaOfStudy}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-group">
        <label for="exampleInputEmail1">Enter Degree Level Name</label>

        <input
          type={"text"}
          className="form-control"
          placeholder="Enter degree level"
          value={degreeLevel}
          onChange={(e) => setdegreeLevel(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSubmitdegree}>
         {editEnable?" Update Degree Level":" Add Degree Level"}
        </button>
      </div>
      <div className="container">
        <Col>
          <Row>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Degree levels</CardTitle>
                <MDBDataTable responsive bordered data={data} />
              </CardBody>
            </Card>
          </Row>
        </Col>
      </div>
    </>
  );
}

export default Degrees;
