import React, { useState } from 'react'
import AddNewsEvent from '../../components/dashboard/newsevent/AddNewsEvent'
import ViewNewsEvent from '../../components/dashboard/newsevent/ViewNewsEvent'

function NewsEvent() {
  const [added,setAdded] = useState(0)
  return (
    <div>
        <AddNewsEvent setAdded={setAdded} added={added} />
        <ViewNewsEvent added={added} />
    </div>
  )
}

export default NewsEvent