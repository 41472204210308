import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import HttpClient from "../../../../utils/HttpClient";
import Field from "./Comp/Field";

const ExperienceMonth = ({ label, placeholder }) => {
  const [experienceMonth, setexperienceMonth] = useState([]);
  const [newexpMonth, setNewexpMonth] = useState("");
  const [editEnable, setEditEnable] = useState(false);
  const [editId, setEditId] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(
      `view-experience-month`,
      "GET",
      {}
    );

    if (result && result.status) {
      setexperienceMonth(result.data); // set data
    } else {
      toast.error(result.error);
    }
  };
  const onDelete = async (id) => {
    let res = await HttpClient.requestData(
      `delete-experience-month/${id}`,
      "PUT"
    );
    if (res && res.status) {
      getData();
      toast.success(res.message);
    } else {
      toast.error(res.error);
    }
  };
  const addData = async () => {
    if (editEnable) {
      let res = await HttpClient.requestData(
        "edit-experience-month/" + editId,
        "PUT",
        {
          expirationMonth: newexpMonth,
        }
      );
      if (res && res.status) {
        setNewexpMonth("");
        setEditEnable(false);
        setEditId("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    } else {
      let res = await HttpClient.requestData("addExpirationMonth", "POST", {
        expirationMonth: newexpMonth,
      });
      if (res && res.status) {
        setNewexpMonth("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    }
  };
  const handleEdit = (data, id) => {
    setNewexpMonth(data);
    setEditEnable(true);
    setEditId(id);
  };
  return (
    <Field
      state={experienceMonth}
      setState={setexperienceMonth}
      keyName="expirationMonth"
      label={label}
      placeholder={placeholder}
      handleDelete={onDelete}
      inputValue={newexpMonth}
      addNew={setNewexpMonth}
      addData={addData}
      handleEdit={handleEdit}
      editEnable={editEnable}
    />
  );
};

export default ExperienceMonth;
