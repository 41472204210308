import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { postStudentType, update_student_type } from '../../Api/api';
import { useLocation } from 'react-router-dom';

function AddStudentType( {add,setAdded}) {
    const location=useLocation().state
    const [studentType,setStudentType] = useState("")
    console.log("location",location);
    useEffect(()=>{
        if(location){
            setStudentType(location?.studentType )
        }
    },[useLocation()])



    const handleSubmit = async()=>{
        if(location){
            const result=await update_student_type({studentType},location?._id)
            console.log(result);
            if(result && result.status){
                toast.success(result.message)
                setAdded(add+1)
                setStudentType("")
            }
        }else{
             if(!studentType){
            toast.error("Please provide student value");
            return;
        }
        const res = await postStudentType({studentType});
        console.log(res)
        if(res && res.status){
           toast.success("Student Type added successfully")
           setStudentType("")
           setAdded(add+1)
        }else{
           toast.error("Something went wrong please try again")
        } 
        }
      
        console.log(studentType)
    }
    console.log("studenttype",studentType);
    return (
        <div>
            <input
            id='student_type_edit'
                type={"text"}
                className="form-control mt-3 me-3"
                placeholder="Enter student type"
                value={studentType}
                onChange={(e) => setStudentType(e.target.value)}
            />
            <button className='btn btn-primary' onClick={handleSubmit}>
                {
                    location?"Update":"Add Student"
                }
                
                </button>
        </div>
    )
}

export default AddStudentType