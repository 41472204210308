import React, { useState } from 'react'
import AddChampions from '../../components/dashboard/champions/AddChampions'
import ViewChampions from '../../components/dashboard/champions/ViewChampions'

function ChampionsPage() {
    const [added,setAdded] = useState(0)

  return (
    <div>
        <AddChampions added={added} setAdded={setAdded} />
        <ViewChampions added={added} />
    </div>
  )
}

export default ChampionsPage