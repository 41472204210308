import React, { useState } from 'react'
import AddScholarShipOverView from '../../components/dashboard/scholarshipandgrant/AddScholarShipOverView'
import ViewScholarShipOverView from '../../components/dashboard/scholarshipandgrant/ViewScholarShipOverView'

function ScholarShipOverViewPage() {
    const [added,setAdded] = useState(0)

  return (
    <div>
        <AddScholarShipOverView added={added} setAdded={setAdded} />
        <ViewScholarShipOverView added={added} />
    </div>
  )
}

export default ScholarShipOverViewPage