import React, { useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  addExperiencePost,
  experienceImageUpload,
  experienceEdit,
  introHistoryUpload,
  addHistoryIntroPost,
  introHistoryEdit,
  missionsAndValuesEdit,
  missionsValuesPost,
  missionsandvaluesupload,
} from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import toolbarConfig from "../../ReactRte/Index";

function AddMissionsAndValues({ setAdded, added }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [description, setDescription] = useState(() => {
    if (location.state) {
      return RichTextEditor.createValueFromString(
        location.state.description,
        "html"
      );
    } else {
      return RichTextEditor.createEmptyValue();
    }
  });
  const [editId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });
  const [initialValues] = useState(() => {
    if (location.state) {
      return {
        heading: location.state.heading,
        // description: location.state.description.toString("html"),
        image: location.state.image,
      };
    } else {
      return null;
    }
  });
  const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
    if (location.state) {
      return location.state.image;
    } else {
      return "";
    }
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { heading } = data;
    if (!uploadedImgUrl) {
      toast.error("Please upload an image");
      return;
    }
    let sendData = {
      heading,
      description: description.toString("html"),
      image: uploadedImgUrl,
    };
    console.log("sendData", sendData);
    if (!initialValues) {
      setLoading(true);
      const res = await missionsValuesPost(sendData);
      setLoading(false);
      console.log(res);
      if (res && res.status) {
        toast.success("Intro history added successfully");
        reset();
        setUploadedImgUrl("");
        setAdded(added + 1);
      }
    } else {
      setLoading(true);
      const res = await missionsAndValuesEdit(editId, sendData);
      setLoading(false);
      if (res && res.status) {
        toast.success("Intro History edited successfully");
        navigate(-1);
      } else {
        toast.error("Something went wrong please try again later");
      }
    }
  };

  const handleImageUpload = async (e) => {
    let data = new FormData();
    data.append("image", e.target.files[0]);
    console.log(data, "daaaaa");
    setLoading(true);
    const result = await missionsandvaluesupload(data);
    setLoading(false);
    console.log(result);
    if (result && result.status) {
      setUploadedImgUrl(result.url);
      toast.success("Image successfully uploaded");
    } else {
      toast.error("Something went wrong,please try again");
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Missions and Value Intro
            </CardTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="heading" className="mt-3">
                Enter heading
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues ? initialValues.heading : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter Heading"
                {...register("heading", { required: true })}
              />
              {errors.heading && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="elemTitle" className="mt-3">
                Enter description
              </label>
              <RichTextEditor
              toolbarConfig={toolbarConfig}
                value={description}
                onChange={(val) => setDescription(val)}
              />
              {/* <textarea
                defaultValue={initialValues ? initialValues.description : ""}
                id="elemTitle"
                rows={"3"}
                className="form-control mt-3 mb-1"
                placeholder="Enter description"
                {...register("description", { required: true })}
              />
              {errors.description && (
                <span className="mt-2 text-danger">
                  {" "}
                  * This field is required
                </span>
              )} */}
              <br />

              {/* <div className="mb-1 mt-3">
            {submitValues.logo && (
              <img
              style={{ height: "auto", width: 100, height: 100 }}
              src={submitValues.logo}
            />
            )}
          </div> */}

              <div className="mb-1 mt-3">
                {uploadedImgUrl && (
                  <img
                    style={{ height: "auto", width: 100, height: 100 }}
                    src={uploadedImgUrl}
                  />
                )}
              </div>
              <div className="mb-3">
                <label for="formFile" className="form-label mt-3">
                  Add an image
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  {...register("bgImage")}
                  onChange={handleImageUpload}
                />
                {/* {errors.image && (
                  <span className="mt-2 text-danger">
                    {" "}
                    * This field is required
                  </span>
                )} */}
                <br />
              </div>
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? (
                    <Loader />
                  ) : initialValues ? (
                    "Edit Missions and values"
                  ) : (
                    "Add Missions and values"
                  )}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddMissionsAndValues;
