import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { postScholarShipOverView, editScholarshipoverview } from "../../../Api/api";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import { toast } from "react-hot-toast";
import toolbarConfig from "../../ReactRte/Index";

function AddScholarShipOverView({ setAdded, added }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [editId] = useState(() => {
        if (location.state) {
            return location.state._id
        } else {
            return null
        }
    })

    const [editorVal, setEditorVal] = useState(() => {
        if (location.state) {
            return {
                description: RichTextEditor.createValueFromString(location.state.description, "html"),
                donationDesc: RichTextEditor.createValueFromString(location.state.donationDesc, "html"),
            }
        } else {
            return {
                description: RichTextEditor.createEmptyValue(),
                donationDesc: RichTextEditor.createEmptyValue(),
            }
        }
    })
    const [initialValues] = useState(() => {
        if (location.state) {
            return {
                heading: location.state.heading,
                description: location.state.description,
                title: location.state.title,
                donationDesc: location.state.donationDesc,
            }
        } else {
            return null
        }
    })
   
   
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        // const { pageName, question } = data;
        // console.log(editorVal)
        console.log(data)
        const {heading,title} =data;
        const description =  editorVal.description.toString("html");
        const donationDesc = editorVal.donationDesc.toString("html");

      if(!description || !donationDesc){
        toast.error("Please enter all fields")
      }

        const sendData = {
            heading,
            title,
            description,
            donationDesc
        }

        console.log(sendData)

        if (!initialValues) {
            setLoading(true);
            const res = await postScholarShipOverView(sendData);
            setLoading(false);
            console.log(res);
            if (res && res.status) {
                toast.success("Scholarship Overview added successfully");
                reset();
                
                setEditorVal({
                    description: RichTextEditor.createEmptyValue(),
                    donationDesc: RichTextEditor.createEmptyValue(),
                })
              
                setAdded(++added)
            } else {
                toast.error(res.message)
            }
        } else {
            setLoading(true)
            const res = await editScholarshipoverview(editId, sendData)
            setLoading(false)
            if (res && res.status) {
                toast.success("Scholarship overview edited successfully")
                navigate(-1)
            } else {
                toast.error("Something went wrong please try again later")
            }
        }
    };

   

    const handleEditorShortDescChange = (value) => {
        setEditorVal({
            ...editorVal,
            description: value
        })
    };

    const handleEditorLongDescChange = (value) => {
        setEditorVal({
            ...editorVal,
            donationDesc: value
        })
    }


    useEffect(() => {
        console.log(editorVal)
    }, [editorVal])

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                            <i className="bi bi-bell me-2"> </i>
                            Add Scholarship Overview
                        </CardTitle>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="heading1" className="mt-3">
                                Enter heading
                            </label>
                            <input
                                type="text"
                                id="heading"
                                defaultValue={initialValues ? initialValues.heading : ""}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter heading"
                                {...register("heading", { required: true })}
                            />
                            {errors.heading && (
                                <span className="mt-2 text-danger">
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="description" className="mt-3">
                                Enter description
                            </label>
                            <RichTextEditor
                             toolbarConfig={toolbarConfig}
                                id="description"
                                value={editorVal.description}
                                onChange={handleEditorShortDescChange}
                            />
                            <br />
                            <label htmlFor="heading2" className="mt-3">
                                Enter title
                            </label>
                            <input
                                type="text"
                                id="title"
                                defaultValue={initialValues ? initialValues.title : ""}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter title"
                                {...register("title", { required: true })}
                            />
                            {errors.title && (
                                <span className="mt-2 text-danger">
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="donationDesc" className="mt-3">
                                Enter donation Description
                            </label>
                            <RichTextEditor
                             toolbarConfig={toolbarConfig}
                                id="desc2"
                                value={editorVal.donationDesc}
                                onChange={handleEditorLongDescChange}
                            />
                            <br />
                            {/* <textarea
                                defaultValue={initialValues ? initialValues.shortDesc : ""}
                                id="elemTitle"
                                rows={"2"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter short description"
                                {...register("shortDesc", { required: true })}
                            />
                            {errors.shortDesc && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br /> */}
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary mt-3" disabled={loading}>
                                    {loading ? <Loader /> : initialValues ? "Edit Scholarship Overview" : "Add Scholarship Overview"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AddScholarShipOverView;
