import React, { useCallback, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { addScholarshipPost, contactDetailsEdit, contactDetailsPost, contactIntroEdit, contactIntroImageUpload, contactIntroPost, scholarshipEdit, scholarshipImageUpload, uploadHomeImage } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';


const containerStyle = {
  width: '100%',
  height: '400px'
};


function AddContactDetails({ setAdded, added }) {

  const navigate = useNavigate()
  const location = useLocation()

  const [center, setCenter] = useState(()=>{
    if(!location.state){
       return {
        lat: 23.007067039912062,
        lng: 88.549068237784
      }
    }else{
      return {
        lat:location.state.location.coordinates[1],
        lng:location.state.location.coordinates[0]
      }
    }
  })
  const [map, setMap] = useState(null)
  const [selectedCoords,setSelectedCoords] = useState(()=>{
    if(location.state){
      return location.state.location.coordinates.reverse()
    }else{
      return null;
    }
  })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDoTIrlJxHd9rTLoytrigY9piIQk1OcTJk"
  })

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(10)
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])


  const [editId] = useState(() => {
    if (location.state) {
      return location.state._id
    } else {
      return null
    }
  })

  const [coords, setCoords] = useState(() => {
    if (location.state) {
      return location.state.location.coordinates
    } else {
      return null
    }
  })
  const [initialValues] = useState(() => {
    if (location.state) {
      console.log(location.state)
      return {
        title: location.state.title,
        message: location.state.message,
        note: location.state.note,
        address: location.state.address,
        email: location.state.email,
        phone: location.state.phone,
        image: location.state.image,
      }
    } else {
      return null
    }
  })
  const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
    if (location.state) {
      return location.state.image
    } else {
      return ""
    }
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { title, message, note, address, email, phone } = data;
    if (!uploadedImgUrl) {
      toast.error("Please upload an image");
      return;
    }

    if(!selectedCoords){
      toast.error("Please select contact location on map");
      return
    }
    let sendData = {
      title,
      message,
      note,
      address,
      email,
      phone,
      image: uploadedImgUrl,
      location:{
        type:"Point",
        coordinates:selectedCoords.reverse()
      }
    };
    // console.log(selectedCoords)
    console.log(sendData)
    // return

    if (!initialValues) {
      setLoading(true);
      const res = await contactDetailsPost(sendData);
      setLoading(false);
      console.log(res);
      if (res && res.status) {
        toast.success("Contact details added successfully");
        reset();
        setUploadedImgUrl("");
        setAdded(added + 1)
      } else {
        toast.error(res.message)
      }
    } else {
      setLoading(true)
      const res = await contactDetailsEdit(editId, sendData)
      console.log(res)
      setLoading(false)
      if (res && res.status) {
        toast.success("Contact intro edited successfully")
        navigate(-1)
      } else {
        toast.error("Something went wrong please try again later")
      }
    }
  };

  const handleImageUpload = async (e) => {
    let data = new FormData();
    data.append("image", e.target.files[0]);
    console.log(data, "daaaaa");
    setLoading(true);
    const result = await uploadHomeImage(data);
    setLoading(false);
    console.log(result);
    if (result && result.status) {
      setUploadedImgUrl(result.url);
      toast.success("Image successfully uploaded");
    } else {
      toast.error("Something went wrong,please try again");
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Contact Details
            </CardTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="heading" className="mt-3">
                Enter Page Title
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues ? initialValues.title : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter Page Title"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="heading" className="mt-3">
                Enter Page Message
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues ? initialValues.message : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter Message"
                {...register("message", { required: true })}
              />
              {errors.message && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="elemTitle" className="mt-3">
                Enter Note
              </label>
              <textarea
                defaultValue={initialValues ? initialValues.note : ""}
                id="elemTitle"
                className="form-control mt-3 mb-1"
                placeholder="Enter Note"
                {...register("note", { required: true })}
              />
              {errors.note && (
                <span className="mt-2 text-danger">
                  {" "}
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="elemTitle" className="mt-3">
                Enter Address
              </label>
              <textarea
                defaultValue={initialValues ? initialValues.address : ""}
                id="elemTitle"
                className="form-control mt-3 mb-1"
                placeholder="Enter address"
                {...register("address", { required: true })}
              />
              {errors.address && (
                <span className="mt-2 text-danger">
                  {" "}
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="heading" className="mt-3">
                Enter Email
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues ? initialValues.email : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter Email"
                {...register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
              />
              {errors.email && (
                <span className="mt-2 text-danger">
                  * Enter a valid email
                </span>
              )}
              <br />
              <label htmlFor="heading" className="mt-3">
                Enter Phone
              </label>
              <input
                type="number"
                id="heading"
                defaultValue={initialValues && initialValues.phone}
                className="form-control mt-3 mb-1"
                placeholder="Enter phone number"
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />

              <div className="mb-1 mt-3">
                {uploadedImgUrl && (
                  <img
                    style={{ height: "auto", width: 100, height: 100 }}
                    src={uploadedImgUrl}
                  />
                )}
              </div>
              <div className="mb-3">
                <label for="formFile" className="form-label mt-3">
                  Add an image
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  {...register("image")}
                  onChange={handleImageUpload}
                />
                <br />
              </div>
              {
                isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={5}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={ev => {
                      setSelectedCoords([ev.latLng.lat(),ev.latLng.lng()])
                      setCenter({
                        lat: ev.latLng.lat(),
                        lng: ev.latLng.lng()
                      })
                      console.log("latitide = ", ev.latLng.lat());
                      console.log("longitude = ", ev.latLng.lng());
                    }}
                  >

                   {
                    selectedCoords && (
                      <Marker
                      position={{
                        lat: Number(selectedCoords[0]),
                        lng: Number(selectedCoords[1])
                      }} />
                    )
                   }
                    { /* Child components, such as markers, info windows, etc. */}
                    <></>
                  </GoogleMap>
                ) : <></>
              }
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? <Loader /> : initialValues ? "Edit Contact Intro" : "Add Contact Intro"}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddContactDetails;
