import React, { useState } from 'react'
import AddFaculty from '../../components/dashboard/faculty/AddFaculty'
import ViewFaculty from '../../components/dashboard/faculty/ViewFaculty'

function FacultyIntro() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddFaculty setAdded={setAdded} added={added} />
        <ViewFaculty added={added} />
    </div>
  )
}

export default FacultyIntro