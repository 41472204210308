import React, { useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { introHistoryUpload, addNewsPost, editNewsPost, postNews, postPresidentGoal, editPresidentGoal, uploadHomeImage } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

function AddPresidentGoal({ setAdded, added }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [editId] = useState(() => {
        if (location.state) {
            return location.state._id
        } else {
            return null
        }
    })
    const [initialValues] = useState(() => {
        if (location.state) {
            return {
                name: location.state.name,
                designation: location.state.designation,
                description: location.state.description,
                image: location.state.image
            }
        } else {
            return null
        }
    })
    const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
        if (location.state) {
            return location.state.image
        } else {
            return ""
        }
    });
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const { name, designation,description } = data;
        if (!uploadedImgUrl) {
            toast.error("Please upload an image");
            return;
        }
        let sendData = {
            name,
            designation,
            description,
            image: uploadedImgUrl,
        };

        if (!initialValues) {
            setLoading(true);
            const res = await postPresidentGoal(sendData);
            setLoading(false);
            console.log(res);
            if (res && res.status) {
                toast.success("President goal added successfully");
                reset();
                setUploadedImgUrl("");
                setAdded(added + 1)
            }else{
                toast.error(res.message)
            }
        } else {
            setLoading(true)
            const res = await editPresidentGoal(editId, sendData)
            setLoading(false)
            if (res && res.status) {
                toast.success("President goal edited successfully")
                navigate(-1)
            } else {
                toast.error("Something went wrong please try again later")
            }
        }
    };

    const handleImageUpload = async (e) => {
        let data = new FormData();
        data.append("image", e.target.files[0]);
        console.log(data, "daaaaa");
        setLoading(true);
        const result = await uploadHomeImage(data);
        setLoading(false);
        console.log(result);
        if (result && result.status) {
            setUploadedImgUrl(result.url);
            toast.success("Image successfully uploaded");
        } else {
            toast.error("Something went wrong,please try again");
        }
    };

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                            <i className="bi bi-bell me-2"> </i>
                            Add President goal
                        </CardTitle>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="name" className="mt-3">
                                Enter name
                            </label>
                            <input
                                type="text"
                                id="name"
                                defaultValue={initialValues ? initialValues.name : ""}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter name"
                                {...register("name", { required: true })}
                            />
                            {errors.name && (
                                <span className="mt-2 text-danger">
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="designation" className="mt-3">
                                Enter designation
                            </label>
                            <input
                                type={"text"}
                                defaultValue={initialValues ? initialValues.designation : ""}
                                id="designation"
                                rows={"2"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter designation"
                                {...register("designation", { required: true })}
                            />
                            {errors.designation && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br />
                            <label htmlFor="description" className="mt-3">
                                Enter description
                            </label>
                            <textarea
                                defaultValue={initialValues ? initialValues.description : ""}
                                id="description"
                                rows={"4"}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter description"
                                {...register("description", { required: true })}
                            />
                            {errors.description && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )}
                            <br />

                          
                            <div className="mb-1 mt-3">
                                {uploadedImgUrl && (
                                    <img
                                        style={{ height: "auto", width: 100, height: 100 }}
                                        src={uploadedImgUrl}
                                    />
                                )}
                            </div>
                            <div className="mb-3">
                                <label for="formFile" className="form-label mt-3">
                                    Add an image
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    accept="image/*"
                                    id="formFile"
                                    {...register("bgImage")}
                                    onChange={handleImageUpload}
                                />
                                {/* {errors.image && (
                  <span className="mt-2 text-danger">
                    {" "}
                    * This field is required
                  </span>
                )} */}
                                <br />
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary mt-3" disabled={loading}>
                                    {loading ? <Loader /> : initialValues ? "Edit President Goal" : "Add President Goal"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AddPresidentGoal;
