import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { uploadHomeImage } from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";

function Welcome({ setAddData, addData }) {
  const inputRef = useRef();
  const [fields, setFields] = useState([{ id: 1, title: "", desc: "" }]);
  const [elementTypes, setElementTypes] = useState([]);
  const [elementtTypes, setElementtTypes] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState({
    heading: "",
    description: "",
    logo: "",
    sectionNote: [],
  });
  const handleKeyValueAdd = () => {
    if (fields && fields.length > 0) {
      const lastKeyValue = fields[fields.length - 1];
      const newKeyValue = { id: lastKeyValue.id + 1, title: "", desc: "" };
      setFields([...fields, newKeyValue]);
    } else {
      setFields([{ id: 1, title: "", desc: "" }]);
    }
  };

  useEffect(() => {
    console.log(submitValues);
  }, [submitValues]);

  const handleKeyValueChange = (id, ev, index) => {
    const found = fields.find((f) => f.id === id);
    found.title = ev.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, sectionNote: oldFields });
  };

  const handleRemoveKeyValue = (idx) => {
    const oldFields = [...fields];
    const firstArr = oldFields.slice(0, idx);
    const lastArr = oldFields.slice(idx + 1);
    const newlastArr = lastArr.map((val) => {
      val.id = val.id - 1;
      return val;
    });
    const fieldsToSet = [...firstArr, ...newlastArr];
    setFields(fieldsToSet);
    setSubmitValues({ ...submitValues, sectionNote: fieldsToSet });
  };

  const handleFieldChange = (e, f) => {
    const found = fields.find((fi) => fi.id === f.id);
    const index = fields.findIndex((fi) => fi.id === f.id);
    console.log(found);
    console.log(index);
    found.desc = e.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, sectionNote: oldFields });
  };

  const handleImageUpload = async (e) => {
    // console.log(e.target.files[0])
    let data = new FormData();
    data.append("image", e.target.files[0]);
    console.log(data, "daaaaa");

    const result = await uploadHomeImage(data);
    console.log(result);
    if (result && result.status) {
      setSubmitValues({ ...submitValues, logo: result.url });
      toast.success("Image successfully uploaded");
    }
    //   let result = await HttpClient.fileUplode(
    //     "uploadimage",
    //     "POST",
    //     data
    //     );
    //     console.log(result);
  };

  const handleElementAdd = async () => {
    // const {elementType,title,image,field} = submitValues;

    // if(!elementType || !title || !image || (Array.isArray(field) && field.length === 0)){
    //   toast.error("Please enter all fields")
    //   return false
    // }

    console.log(submitValues);

    const { heading, description, logo, sectionNote } = submitValues;

    if (!heading || !description || !logo || !sectionNote) {
      toast.error("Please enter all fields before submitting");
      return;
    }
    try {
      setSubmitLoading(true);
      const res = await HttpClient.requestData(
        "addWelcomeSection",
        "POST",
        submitValues
      );
      console.log(res);
      if (res && res.status) {
        setSubmitLoading(false);
        setAddData(++addData);
        setSubmitValues({
          heading: "",
          description: "",
          logo: "",
          sectionNote: [],
        });
        setFields([{ id: 1, title: "", desc: "" }]);
        inputRef.current.value = "";
        toast.success("Welcome section added successfully");
      }else{
        toast.error("Something went wrong!!!");
        setSubmitLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add welcome section
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter heading
            </label>
            <input
              type="text"
              id="heading"
              className="form-control mt-3"
              placeholder="Enter heading"
              value={submitValues.heading}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, heading: e.target.value });
              }}
            />
            <label htmlFor="elemTitle" className="mt-3">
              Enter description
            </label>
            <input
              type="text"
              id="elemTitle"
              className="form-control mt-3"
              value={submitValues.description}
              placeholder="Enter description"
              onChange={(e) => {
                setSubmitValues({
                  ...submitValues,
                  description: e.target.value,
                });
              }}
            />
            <div className="mb-1 mt-3">
              {submitValues.logo && (
                <img
                style={{ height: "auto", width: 100, height: 100 }}
                src={submitValues.logo}
              />
              )}
            </div>
            <div className="mb-3">
              <label for="formFile" className="form-label mt-3">
                Add a logo
              </label>
              <input
                ref={inputRef}
                className="form-control"
                type="file"
                accept="image/*"
                id="formFile"
                onChange={handleImageUpload}
              />
            </div>
            <label className="mt-3"> Add Section Notes</label>
            <Card>
              <CardBody>
              {fields.map((f, i) => (
              <div
                key={i}
                className="d-flex justify-content-center align-items-center"
              >
                <input
                  type="text"
                  className="form-control mt-3 me-3"
                  placeholder="enter title"
                  value={f.title}
                  onChange={(e) => handleKeyValueChange(f.id, e, i)}
                />

                <input
                  type="text"
                  className="form-control mt-3 me-3"
                  placeholder="enter desc"
                  value={f.desc}
                  onChange={(e) => handleFieldChange(e, f)}
                />
                {i !== 0 && (
                  <button
                    className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                    onClick={() => handleRemoveKeyValue(i)}
                  >
                    <i
                      className="bi bi-x-circle"
                      style={{ fontSize: "15px" }}
                    ></i>
                  </button>
                )}
              </div>
            ))}
               <button
                className="btn btn-secondary mt-3 me-2"
                onClick={handleKeyValueAdd}
              >
                Add section notes
              </button>
              </CardBody>
            </Card>

           
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Add Element"
                )}
              </button>
           
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default Welcome;
