import React, { useState } from "react";
import { Form, Input, Checkbox, Button } from "antd";
import "antd/dist/reset.css";
import { getLogin } from "../../Api/api";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import HttpClient from "../../utils/HttpClient";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onFinish = async () => {
    if (email.trim() != "" && password.trim() != "") {
      let data = {
        email,
        password,
      };
      console.log(data);
      let result = await getLogin(data);
      console.log(result);
      if (result && result.status) {
        toast.success("Successfully logged in");
        reactLocalStorage.setObject("userAdminData", result.data);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        toast.error(result.message);
      }
    } else {
      alert("please add valid data");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="login-page">
        <div className="login-box">
          <div className="illustration-wrapper">
            <img
              src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
              alt="Login"
            />
          </div>
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <p className="form-title">Welcome back</p>
            <p>Login to the Dashboard</p>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                placeholder="Email"
                onChange={(val) => setEmail(val.target.value)}
                value={email}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                value={password}
                onChange={(val) => setPassword(val.target.value)}
              />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                LOGIN
              </Button>
            </Form.Item>
            {/* <p>Don't Have an account ? <Link to="/register" style={{textDecoration:"none"}}>Register here</Link></p> */}
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
