import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { AddAdminationDropDown, UpdateAdmissionDropDown } from "../../../../Api/api";
import { useNavigate, useLocation } from "react-router-dom";

export default function AddAdmissionDropDown({add,setAdd}) {
  let location = useLocation().state;
  const navigate=useNavigate()
  console.log("asdsadsa", location);
  const [category, setCategory] = useState([""]);
  const [title, setTitle] = useState("");
  console.log(category);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location) {
      setTitle(location.title);
      setCategory(location.category);
    }
  }, [location]);

  const HandelSubmit = async () => {
    let catData = category.filter((val) => val !== "");
    console.log(catData);

    if (title === "" && catData.length < 1) {
      toast.error("* Required Fild");
    } else {
      // console.log(catData);

      if (location) {
        let data={
            title: title,
            category: catData, 
        }
        let result= await UpdateAdmissionDropDown(data,location._id)
        console.log("result",result);
        if(result && result.status){
            toast.success(result.message)
            navigate("/admissions/admissionDropDown",{state:null})
            setAdd(add+1)
            setCategory([])
            setTitle("")
        }
      } else {
        let data = {
          title: title,
          category: catData,
        };
        console.log(data);
        let result = await AddAdminationDropDown(data);
        if (result && result.status) {
          toast.success(result.message);
          setCategory([])
            setTitle("")
            setAdd(add+1)
        } else if (result) {
          toast.error(result.message);
        }
        console.log(result);
      }
    }
  };

  const addAnotherField = async (e) => {
    e.preventDefault();
    let arr = [...category];
    // count = count + 1;
    arr.push("");
    setCategory(arr);
    // setCreateSub({ ...createSub, desc: arr })
  };

  const RemoveField = (ind) => {
    let arr = [...category];
    arr.splice(ind, 1);
    setCategory(arr);
  };

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                <i className="bi bi-bell me-2"> </i>
                Add Admission DropDown
              </CardTitle>
              <label htmlFor="heading" className="mt-3">
                Enter Title
              </label>
              <input
                id="dropdown_edit"
                type="text"
                className="form-control mt-3"
                placeholder="Enter heading type"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              {category?.map((val, index) => {
                return (
                  <div key={index}>
                    <label htmlFor="title" className="mt-3">
                      Enter category
                    </label>
                    <input
                      type="text"
                      id="title"
                      className="form-control mt-3"
                      placeholder="Enter title"
                      value={val}
                      onChange={(e) => {
                        let arr = [...category];
                        arr[index] = e.target.value;
                        setCategory(arr);
                      }}
                    />

                    {index !== 0 && (
                      <button
                        className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                        onClick={() => RemoveField(index)}
                      >
                        <i
                          className="bi bi-x-circle"
                          style={{ fontSize: "15px" }}
                        ></i>
                      </button>
                    )}
                  </div>
                );
              })}

              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => {
                    HandelSubmit();
                  }}
                >
                  {
                    location?"Update":"Submit"
                  }
                </button>
                <button
                  className="btn btn-secondary mt-3 me-2"
                  onClick={(e) => {
                    addAnotherField(e);
                  }}
                >
                  Add category
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
