import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import HttpClient from "../../../../utils/HttpClient";
import Field from "./Comp/Field";

const State = ({ label, placeholder }) => {
  const [statesName, setStatesName] = useState([]);
  const [newState, setNewState] = useState("");
  const [editEnable, setEditEnable] = useState(false);
  const [editId, setEditId] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(`view-state`, "GET", {});

    if (result && result.status) {
      setStatesName(result.data); // set data
    } else {
      toast.error(result.error);
    }
  };
  const onDelete = async (id) => {
    let res = await HttpClient.requestData(`delete-state/${id}`, "PUT");
    if (res && res.status) {
      getData();
      toast.success(res.message);
    } else {
      toast.error(res.error);
    }
  };
  const addData = async () => {
    if (editEnable) {
      let res = await HttpClient.requestData("update-state/" + editId, "PUT", {
        state: newState,
      });
      if (res && res.status) {
        setNewState("");
        setEditEnable(false);
        setEditId("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    } else {
      let res = await HttpClient.requestData("addState", "POST", {
        state: newState,
      });
      if (res && res.status) {
        setNewState("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    }
  };

  const handleEdit = (data, id) => {
    setNewState(data);
    setEditEnable(true);
    setEditId(id);
  };
  return (
    <Field
      state={statesName}
      setState={setStatesName}
      label={label}
      placeholder={placeholder}
      keyName="state"
      handleDelete={onDelete}
      inputValue={newState}
      addNew={setNewState}
      addData={addData}
      handleEdit={handleEdit}
      editEnable={editEnable}
    />
  );
};

export default State;
