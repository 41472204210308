import React, { useState } from 'react'
import AddChampionsLife from '../../components/dashboard/championslife/AddChampionsLife'
import ViewChampionsLife from '../../components/dashboard/championslife/ViewChampionsLife'

function ChampionsLife() {
   const [added,setAdded] = useState(0)
  return (
    <div>
        <AddChampionsLife added={added} setAdded={setAdded} />
        <ViewChampionsLife added={added} />
    </div>
  )
}

export default ChampionsLife