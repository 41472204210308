import React, { useState } from 'react'
import AddQuickViewForm from '../../components/dashboard/quickview/AddQuickViewForm'
import ViewQuickView from '../../components/dashboard/quickview/ViewQuickView'

function AddQuickView() {
  const [added,setAdded] = useState(0)

  return (
    <div>
        <AddQuickViewForm setAdded={setAdded} added={added} />
        <ViewQuickView added={added} />
    </div>
  )
}

export default AddQuickView