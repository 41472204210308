import React, { useState } from 'react'
import AddGalleryIntro from '../../components/dashboard/gallery/AddGalleryIntro'
import ViewGalleryIntro from '../../components/dashboard/gallery/ViewGalleryIntro'

function GalleryIntro() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddGalleryIntro setAdded={setAdded} added={added} />
        <ViewGalleryIntro added={added} />
    </div>
  )
}

export default GalleryIntro