import React, { useState } from 'react'
import AddAboutIntro from '../../components/dashboard/aboutintro/AddAboutIntro'
import ViewAboutIntro from '../../components/dashboard/aboutintro/ViewAboutIntro'

function AboutIntro() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddAboutIntro setAdded={setAdded} added={added} />
        <ViewAboutIntro added={added} />
    </div>
  )
}

export default AboutIntro