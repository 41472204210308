import React, { useState } from 'react'
import AddBannerComp from '../../components/dashboard/banner/AddBannerComp'
import ViewBannerComp from '../../components/dashboard/banner/ViewBannerComp'

function AddBanner() {
  const [added,setAdded] = useState(0)
  return (
    <div>
        <AddBannerComp setAdded={setAdded} added={added} />
        <ViewBannerComp added={added} />
    </div>
  )
}

export default AddBanner