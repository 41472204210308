import React, { useState } from 'react'
import AddCampus from '../../components/dashboard/campus/AddCampus'
import ViewCampus from '../../components/dashboard/campus/ViewCampus'

function Campus() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddCampus added={added} setAdded={setAdded} />
        <ViewCampus added={added} />
    </div>
  )
}

export default Campus