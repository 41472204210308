import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { applyEdit, uploadHomeImage } from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";

function AddApplySection({ added, setAdded }) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const [applyId, setApplyId] = useState(null);
  const [fields, setFields] = useState(() => {
    if (location.state) {
      const arr = location.state.sectionNote.map((val, i) => {
        return {
          title: val.title,
          desc: val.desc,
          id: Number(val.serialNo),
        };
      });
      return arr;
    } else {
      return [{ id: 1, title: "", desc: "" }];
    }
  });
  const [elementTypes, setElementTypes] = useState([]);
  const [elementtTypes, setElementtTypes] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState({
    heading: "",
    sectionNote: [{ title: "", desc: "" }],
  });
  const handleKeyValueAdd = () => {
    setSubmitValues((prev) => {
      return {
        ...prev,
        sectionNote: [...prev.sectionNote, { title: "", desc: "" }],
      };
    });
    // if (fields && fields.length > 0) {
    //   const lastKeyValue = fields[fields.length - 1];
    //   const newKeyValue = { id: lastKeyValue.id + 1, title: "", desc: "" };
    //   setFields([...fields, newKeyValue]);
    // } else {
    //   setFields([{ id: 1, title: "", desc: "" }]);
    // }
  };

  useEffect(() => {
    console.log(submitValues);
  }, [submitValues]);

  const handleKeyValueChange = (val, index, field) => {
    setSubmitValues((prev) => {
      let updated = [...prev.sectionNote];
      updated[index][field] = val;

      return {
        ...prev,
        sectionNote: updated,
      };
    });
    // const found = fields.find((f) => f.id === id);
    // found.title = ev.target.value;
    // let oldFields = [...fields];
    // oldFields.splice(index, 1, found);
    // setFields(oldFields);
    // setSubmitValues({ ...submitValues, sectionNote: oldFields });
  };

  const handleRemoveKeyValue = (idx) => {
    setSubmitValues((prev) => {
      let updated = [...prev.sectionNote];
      updated.splice(idx, 1);
      return {
        ...prev,
        sectionNote: updated,
      };
    });
    // const oldFields = [...fields];
    // const firstArr = oldFields.slice(0, idx);
    // const lastArr = oldFields.slice(idx + 1);
    // const newlastArr = lastArr.map((val) => {
    //   val.id = val.id - 1;
    //   return val;
    // });
    // const fieldsToSet = [...firstArr, ...newlastArr];
    // setFields(fieldsToSet);
    // setSubmitValues({ ...submitValues, sectionNote: fieldsToSet });
  };

  const handleFieldChange = (e, f) => {
    const found = fields.find((fi) => fi.id === f.id);
    const index = fields.findIndex((fi) => fi.id === f.id);
    console.log(found);
    console.log(index);
    found.desc = e.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, sectionNote: oldFields });
  };

  const handleElementAdd = async () => {
    console.log(submitValues);

    const { heading, sectionNote } = submitValues;

    if (!heading || !sectionNote) {
      toast.error("Please enter all fields before submitting");
      return;
    }

    const sendData = {
      heading,
      sectionNote: sectionNote.map((val, i) => {
        if (val.id < 10) {
          val.serialNo = "0" + val.id;
        } else {
          val.serialNo = `${val.id}`;
        }
        delete val.id;
        return val;
      }),
    };
    console.log(sendData);
    // if (!location.state) {
    //   try {
    //     setSubmitLoading(true);
    //     const res = await HttpClient.requestData(
    //       "addApplySection",
    //       "POST",
    //       sendData
    //     );
    //     console.log(res);
    //     if (res && res.status) {
    //       setAdded(++added);
    //       setSubmitValues({
    //         heading: "",
    //         description: "",
    //         logo: "",
    //         sectionNote: [],
    //       });
    //       setFields([{ id: 1, title: "", desc: "" }]);
    //       setSubmitLoading(false);
    //       toast.success("Apply section added successfully");
    //     } else {
    //       setSubmitLoading(false);
    //       toast.error(res.message);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     setSubmitLoading(false);
    //   }
    // } else {
      setSubmitLoading(true);
      const res = await applyEdit(applyId, sendData);
      setSubmitLoading(false);
      if (res && res.status) {
        toast.success("Apply section edited successfully");
        // navigate(-1);
      } else {
        console.log("first");
        setSubmitLoading(false);
        toast.error("Something went wrong!!!");
      }
      console.log(sendData, applyId);
    // }
  };

  useEffect(() => {
    fetchAllApply();
  }, []);

  async function fetchAllApply() {
    try {
      const res = await HttpClient.requestData("viewApplySection", "GET", {});
      console.log("apply", res);
      if (res.status) {
        let data = res.data[0];
        setSubmitValues((prev) => {
          return {
            ...prev,
            heading: data.heading,
            sectionNote: data.sectionNote,
          };
        });
        setApplyId(data._id);

        // console.log(data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add apply section
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter heading
            </label>
            <input
              type="text"
              id="heading"
              className="form-control mt-3"
              placeholder="Enter heading"
              value={submitValues.heading}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, heading: e.target.value });
              }}
            />
            <label className="mt-3">Section Notes</label>
            <Card>
              <CardBody>
                {submitValues.sectionNote &&
                  submitValues.sectionNote.map((f, i) => (
                    <div
                      key={i}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <input
                        type="text"
                        className="form-control mt-3 me-3"
                        placeholder="enter title"
                        value={f.title}
                        onChange={(e) =>
                          handleKeyValueChange(e.target.value, i, "title")
                        }
                      />

                      <input
                        type="text"
                        className="form-control mt-3 me-3"
                        placeholder="enter desc"
                        value={f.desc}
                        onChange={(e) =>
                          handleKeyValueChange(e.target.value, i, "desc")
                        }
                      />
                      {i !== 0 && (
                        <button
                          className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                          onClick={() => handleRemoveKeyValue(i)}
                        >
                          <i
                            className="bi bi-x-circle"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </button>
                      )}
                    </div>
                  ))}

                <button
                  className="btn btn-secondary mt-3 me-2"
                  onClick={handleKeyValueAdd}
                >
                  Add section notes
                </button>
              </CardBody>
            </Card>

            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : location.state ? (
                  ""
                ) : (
                  "Edit Apply section"
                )}
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddApplySection;
