import React from "react";

function Progressbar({percentage=1}) {
  return (
    <div id="myProgress">
      <div style={{width:`${percentage}%`}} id="myBar"></div>
    </div>
  );
}

export default Progressbar;
