import React, { useEffect, useState } from "react";
// import HttpClient from "../../utils/HttpClient";
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Button,
} from "reactstrap";

import { MDBDataTable } from "mdbreact";
import HttpClient from "../../../utils/HttpClient";
import { toast } from "react-hot-toast";
import { experienceDelete, partnerShipDelete, welcomeDelete } from "../../../Api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";

function ViewPartnerShip({ added }) {
    const navigate = useNavigate();
    const [partner, setPartner] = useState([]);
    const [Loading, setLoading] = useState(false);
    // const [elementFieldType,setelementFieldType] = useState("")

    const data = {
        columns: [
            {
                label: "Heading",
                field: "heading",
                center: true,
            },
            {
                label: "Description",
                field: "description",
                center: true,
            },
            {
                label: "Image",
                field: "imgelem",
                center: true,
                sort: "disabled",
            },
            //   {
            //     label: "Section note",
            //     field: "sectionNotes",
            //     center: true,
            //     sort: "disabled",
            //   },
            {
                label: "Action",
                field: "action",
                center: true,
                sort: "disabled",
            },
        ],
        rows: partner,
    };

    useEffect(() => {
        fetchAllPartner();
    }, [added]);

    async function fetchAllPartner() {
        setLoading(true);
        try {
            const res = await HttpClient.requestData("viewPartnership", "GET", {});
            console.log(res);
            if (res.status) {
                const data = res.data.map((val, i) => {
                    return {
                        ...val,
                        action: (
                            <div>
                                <Button color="info" onClick={() => hanldleEdit(val)}>
                                    Edit
                                </Button>
                                <Button color="danger" onClick={() => hanldleDelete(val._id)}>
                                    Delete
                                </Button>
                            </div>
                        ),
                        imgelem: (
                            <img
                                style={{ height: "auto", width: 50, margin: 5 }}
                                src={val.image}
                            />
                        ),
                    };
                });
                console.log(data);
                setPartner(data);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }
    if (Loading) {
        return (
            <Loader />
        );
    }

    const hanldleEdit = (val) => {
        navigate("/home/partnershipedit", { state: val })
        console.log(val)
    };

    const hanldleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete this item?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await partnerShipDelete(id);
                console.log(res);
                if (res && res.status) {
                    Swal.fire(
                        'Deleted!',
                        'Item has been deleted.',
                        'success'
                    )
                    fetchAllPartner();
                } else {
                    toast.error("something went wrong! please try again later")
                }

            }
        })

        console.log(id);
    };

    return (
        <div className="container">
            <Col>
                <Row>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">All Partnership</CardTitle>
                            <MDBDataTable responsive bordered data={data} />
                        </CardBody>
                    </Card>
                </Row>
            </Col>
        </div>
    );
}

export default ViewPartnerShip;
