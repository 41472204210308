import React, { useState } from "react";
import AddStudentType from "./AddStudentType";
import ViewStudentType from "./ViewStudentType";



export default function StudentType (){
    const [added,setAdded]=useState(0)
    return(
        <>
        <AddStudentType add={added} setAdded={setAdded}/>
        <ViewStudentType added={added}/>
        </>
    )
}