import HttpClient from "../utils/HttpClient";

// export async function getLogin(data) {
//   let result = await HttpClient.requestData("login", "POST", data);
//   return result;
// }
// export async function professionImageUpload(data) {
//   let result = await HttpClient.fileUplode(
//     "uploadprofessionimage",
//     "POST",
//     data
//   );
//   return result;
// }

// export async function homescreenImageUpload(data) {
//   let result = await HttpClient.fileUplode(
//     "uploadhomescreenimage",
//     "POST",
//     data
//   );
//   return result;
// }

// export async function createProfession(data) {
//   let result = await HttpClient.requestData("createprofession", "POST", data);
//   return result;
// }

// export async function createHomescreen(data) {
//   let result = await HttpClient.requestData("createhomescreen", "POST", data);
//   return result;
// }
// export async function professionGet() {
//   let result = await HttpClient.requestData("viewprofession", "GET");
//   return result;
// }

// export async function homeScreenGet() {
//   let result = await HttpClient.requestData("viewhomescreen", "GET");
//   return result;
// }

// export async function deleteProfession(id) {
//   let result = await HttpClient.requestData(`deleteprofession/${id}`, "DELETE");
//   return result;
// }

// export async function deleteHomescreen(id) {
//   let result = await HttpClient.requestData(`deletehomescreen/${id}`, "DELETE");
//   return result;
// }

// export async function editHomescreen(id,data) {
//   let result = await HttpClient.requestData(`updatehomescreendetails/${id}`,"PUT",data);
//   return result;
// }

// export async function CreateModule(data) {
//   let result = await HttpClient.requestData(`app-module`, "POST", data);
//   return result;
// }
// export async function GetModule() {
//   let result = await HttpClient.requestData(`app-module`, "GET");
//   return result;
// }
// export async function editModule(id,data) {
//   let result = await HttpClient.requestData(`app-module/update/${id}`, "PUT", data);
//   return result;
// }
// export async function createElementType(data) {
//   let result = await HttpClient.requestData("element-type", "POST", data);
//   return result;
// }
// export async function createElementFeildType(data) {
//   let result = await HttpClient.requestData("element-field-type", "POST", data);
//   return result;
// }
// export async function viewElement(data) {
//   let result = await HttpClient.requestData("element", "GET", data);
//   return result;
// }

// actual apis

export async function getLogin(data) {
  let result = await HttpClient.requestData("login", "POST", data);
  return result;
}

export async function getSignUp(data) {
  let result = await HttpClient.requestData("register", "POST", data);
  return result;
}

export async function uploadHomeImage(data) {
  let result = await HttpClient.fileUplode("homeImageUpload", "POST", data);
  return result;
}

export async function welcomeDelete(id) {
  const uri = `deleteWelcome/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function experienceImageUpload(data) {
  const result = await HttpClient.fileUplode(
    "experienceImageUpload",
    "POST",
    data
  );
  return result;
}

export async function addExperiencePost(data) {
  const result = await HttpClient.requestData(
    "addExperienceSection",
    "POST",
    data
  );
  return result;
}

export async function experienceDelete(id) {
  const uri = `deleteExperience/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function applyDelete(id) {
  const uri = `deleteApply/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function experienceEdit(id, data) {
  const uri = `editExperience/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function applyEdit(id, data) {
  const uri = `editApply/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function addNewsPost(data) {
  let result = await HttpClient.requestData("addNewsAndEvent", "POST", data);
  return result;
}

export async function newsEventDelete(id) {
  const uri = `deleteNewsAndEvent/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function newsEdit(id, data) {
  const uri = `editNewsAndEvent/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function sectionImageUpload(data) {
  const result = await HttpClient.fileUplode(
    "sectionImageUpload",
    "POST",
    data
  );
  return result;
}

export async function postSectionImg(data) {
  const result = await HttpClient.requestData("addimage", "POST", data);
  return result;
}

export async function imgSectionDelete(id) {
  const uri = `deleteImage/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function sectionImgEdit(id, data) {
  const uri = `editImage/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function quickViewDelete(id) {
  const uri = `deleteQuickView/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function editQuickView(id, data) {
  const uri = `editQuickView/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function bannerImageUpload(data) {
  const result = await HttpClient.fileUplode("bannerImageUpload", "POST", data);
  console.log(result, "result");
  return result;
}

export async function bannerVideoUpload(data) {
  const result = await HttpClient.fileUplode("bannerVideo", "POST", data);
  return result;
}

export async function addBannerPost(data) {
  const result = await HttpClient.requestData("addBanner", "POST", data);
  return result;
}

export async function bannerDelete(id) {
  const uri = `deleteBanner/${id}`;
  let result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function bannerEdit(id, data) {
  const uri = `editBanner/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function scholarshipDelete(id) {
  const uri = `deleteScholarship/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function addScholarshipPost(data) {
  const result = await HttpClient.requestData("addScholarship", "POST", data);
  return result;
}

export async function scholarshipEdit(id, data) {
  const uri = `editScholarship/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function scholarshipImageUpload(data) {
  const result = await HttpClient.fileUplode(
    "ScholarshipImageUpload",
    "POST",
    data
  );
  console.log(result, "result");
  return result;
}

export async function networkingDelete(id) {
  const uri = `deleteNetworking/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function addNetworkingPost(data) {
  const result = await HttpClient.requestData("addNetworking", "POST", data);
  return result;
}

export async function networkingEdit(id, data) {
  const uri = `editNetworking/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}
export async function tuitionDelete(id) {
  const uri = `deleteTutionFees/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function feeEdit(id, data) {
  const uri = `editTutionFees/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function feePost(data) {
  const result = HttpClient.requestData("addTutionFees", "POST", data);
  return result;
}

export async function postChampion(data) {
  const result = await HttpClient.requestData("addChampionsLife", "POST", data);
  return result;
  console.log(result);
}

export async function championDelete(id) {
  const uri = `deleteChampionsLife/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function editChampion(id, data) {
  const uri = `editChampionsLife/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function partnerShipDelete(id) {
  const uri = `deletePartnership/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function addPartnerShipPost(data) {
  const result = await HttpClient.requestData("addPartnership", "POST", data);
  return result;
}

export async function partnerEdit(id, data) {
  const uri = `editPartnership/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function introHistoryUpload(data) {
  const result = await HttpClient.fileUplode(
    "facultyIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function addHistoryIntroPost(data) {
  const result = await HttpClient.requestData("addIntroHistory", "POST", data);
  return result;
}

export async function introHistoryEdit(id, data) {
  const uri = `editIntroHistory/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function introHistoryDelete(id) {
  const uri = `deleteIntroHistory/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function universityHistoryDelete(id) {
  const uri = `deleteUniversityHistory/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function universityHistoryEdit(id, data) {
  const uri = `editUniversityHistory/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function postNews(data) {
  const result = await HttpClient.requestData("addNews", "POST", data);
  return result;
}

export async function editNewsPost(id, data) {
  const uri = `editNews/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function newsDelete(id) {
  const uri = `deleteNews/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postFacultyIntro(data) {
  const result = await HttpClient.requestData("addFacultyIntro", "POST", data);
  return result;
}

export async function editFacultyIntro(id, data) {
  const uri = `editFacultyIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function facultyIntroImgUpload(data) {
  const result = await HttpClient.fileUplode(
    "facultyIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function facultyIntroDelete(id) {
  const url = `deleteFacultyIntro/${id}`;
  const result = await HttpClient.requestData(url, "PUT", {});
  return result;
}
export async function facultyListImgUpload(data) {
  const result = HttpClient.fileUplode("facultyListImageUpload", "POST", data);
  return result;
}

export async function facultyListEdit(id, data) {
  const uri = `editFacultyList/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function facultyListDelete(id) {
  const uri = `deleteFacultyList/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postAboutIntro(data) {
  const result = await HttpClient.requestData("addAboutIntro", "POST", data);
  return result;
}

export async function editAboutIntro(id, data) {
  const uri = `editAboutIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function aboutIntroImgUpload(data) {
  const result = await HttpClient.fileUplode(
    "aboutIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function aboutIntroDelete(id) {
  const uri = `deleteAboutIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function campusImageUpload(data) {
  const result = await HttpClient.fileUplode("campusImageUpload", "POST", data);
  return result;
}

export async function campusVideoUpload(data) {
  const result = await HttpClient.uploadFileRequest("campusVideo", "POST", data);
  console.log(result);
  return result;
}

export async function homeVideoUpload(data) {
  const result = await HttpClient.fileUplode("homePageVideo", "POST", data);
  console.log(result);
  return result;
}

export async function campusEdit(id, data) {
  const uri = `editCampus/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function campusDelete(id) {
  const uri = `deleteCampus/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function orgImageUpload(data) {
  const result = await HttpClient.fileUplode("");
}

export async function orgEdit(id, data) {
  const uri = `editOrganisationInfo/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function orgInfoDelete(id) {
  const uri = `deleteOrganisationInfo/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postHistoryDetails(data) {
  const result = await HttpClient.requestData(
    "addHistorydetails",
    "POST",
    data
  );
  return result;
}

export async function editHistoryDetails(id, data) {
  const uri = `editHistorydetails/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function deleteHistoryDetails(id) {
  const uri = `deleteHistorydetails/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function missionsandvaluesupload(data) {
  const result = await HttpClient.fileUplode(
    "/missionAndValueImageUpload",
    "POST",
    data
  );
  return result;
}

export async function missionsValuesPost(data) {
  const result = await HttpClient.requestData(
    "addMissionAndValues",
    "POST",
    data
  );
  return result;
}

export async function missionsAndValuesEdit(id, data) {
  const uri = `editMissionAndValues/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}
export async function missionvaluesDelete(id) {
  const uri = `deleteMissionAndValues/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postStudentType(data) {
  const result = await HttpClient.requestData("addStudentType", "POST", data);
  return result;
}

export async function postCurrentStatus(data) {
  const result = await HttpClient.requestData("addCurrentStatus", "POST", data);
  return result;
}

export async function postAreaOfStudy(data) {
  const result = await HttpClient.requestData("addAreaOfStudy", "POST", data);
  return result;
}
export async function editAreaOfStudy(data, id) {
  const result = await HttpClient.requestData(
    "update-area-of-study/" + id,
    "PUT",
    data
  );
  return result;
}
export async function editDegreeLevel(data, id) {
  const result = await HttpClient.requestData(
    "edit-degree-level/" + id,
    "PUT",
    data
  );
  return result;
}

export async function postDegreeLevel(data) {
  const result = await HttpClient.requestData("addDegreeLevel", "POST", data);
  return result;
}

export async function postPartnershipIntro(data) {
  const result = await HttpClient.requestData(
    "addPartnershipIntro",
    "POST",
    data
  );
  return result;
}

export async function editPartnerIntro(id, data) {
  const uri = `editPartnershipIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function partnershipIntroImgUpload(data) {
  const result = await HttpClient.fileUplode(
    "partnershipIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function partnershipIntroDelete(id) {
  const uri = `deletePartnershipIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function addPartnershipdegree(data) {
  const result = await HttpClient.requestData(
    "addPartnerShipDegree",
    "POST",
    data
  );
  return result;
}

export async function editpartnershipdegree(id, data) {
  const uri = `editPartnerShipDegree/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function deletePartnershipDegree(id) {
  const uri = `deletePartnerShipDegree/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postGalleryIntro(data) {
  const result = await HttpClient.requestData("addGalleryInfo", "POST", data);
  return result;
}

export async function editGalleryIntro(id, data) {
  const uri = `editGalleryIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function galleryIntroImgUpload(data) {
  const result = await HttpClient.fileUplode(
    "galleryIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function galleryIntroDelete(id) {
  const uri = `deleteGalleryIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postGallery(data) {
  const result = await HttpClient.requestData("addPhotoGallery", "POST", data);
  return result;
}

export async function editGallery(id, data) {
  const uri = `editPhotoGallery/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function galleryDelete(id) {
  const uri = `deletePhotoGallery/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function championsIntroImageUpload(data) {
  const result = await HttpClient.fileUplode(
    "ChampionsIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function postChampionsIntro(data) {
  const result = await HttpClient.requestData(
    "addChampionsIntro",
    "POST",
    data
  );
  return result;
}

export async function editChampionsIntro(id, data) {
  const uri = `editChampionsIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function championsDelete(id) {
  const uri = `deleteChampionsIntro/${id}`;
  const result = HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function contactIntroPost(data) {
  const result = HttpClient.requestData("addContactUsIntro", "POST", data);
  return result;
}

export async function contactIntroEdit(id, data) {
  const uri = `editContactUsIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function contactIntroImageUpload(data) {
  const result = await HttpClient.fileUplode(
    "ContactUsIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function contactIntroDelete(id) {
  const uri = `deleteContactUsIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function contactDetailsPost(data) {
  const result = await HttpClient.requestData(
    "addContactDetails",
    "POST",
    data
  );
  return result;
}

export async function contactDetailsEdit(id, data) {
  const uri = `editContactDetails/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function contactDetailsDelete(id) {
  const uri = `deleteContactDetails/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function addOurGoalIntroPost(data) {
  const result = await HttpClient.requestData("addGoalIntro", "POST", data);
  return result;
}

export async function goalIntroEdit(id, data) {
  const uri = `editGoalIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function goalIntroImageUpload(data) {
  const result = await HttpClient.fileUplode(
    "GoalIntroImageUpload",
    "POST",
    data
  );
  return result;
}

export async function goalIntroDelete(id) {
  const uri = `deleteGoalIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}
export async function postPresidentGoal(data) {
  const result = await HttpClient.requestData("addPresidentGoal", "POST", data);
  return result;
}

export async function editPresidentGoal(id, data) {
  const uri = `editPresidentGoal/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function presidentGoalDelete(id) {
  const uri = `deletePresidentGoal/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function scholarshipandgrantsdelete(id) {
  const uri = `deleteScholarshipAndGrandIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postScholarShipAndGrants(data) {
  const result = await HttpClient.requestData(
    "addScholarshipAndGrandIntro",
    "POST",
    data
  );
  return result;
}

export async function editScholarShipAndGrants(id, data) {
  const url = `editScholarshipAndGrandIntro/${id}`;
  const result = await HttpClient.requestData(url, "PUT", data);
  return result;
}

export async function registrationInfoEdit(id, data) {
  const uri = `editRegistrationInfo/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function registrationInfoDelete(id) {
  const url = `deleteRegistrationInfo/${id}`;
  const result = await HttpClient.requestData(url, "PUT", {});
  return result;
}

export async function postCieIntro(data) {
  const result = await HttpClient.requestData("addCIEIntro", "POST", data);
  return result;
}

export async function editCieIntro(id, data) {
  console.log("data", data);
  const uri = `editCIEIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function cieDelete(id) {
  const uri = `deleteCIEIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}
export async function addApplyCIE(data) {
  const result = await HttpClient.requestData(`addApplyCEI`, "POST", data);
  return result;
}

export async function cieApplyEdit(id, data) {
  const uri = `editApplyCEI/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function applyCIEdelete(id) {
  const uri = `deleteApplyCEI/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function postDeliveryMode(data) {
  const result = await HttpClient.requestData("addDeliveryMode", "POST", data);
  return result;
}

export async function editDeliveryMode(id, data) {
  const uri = `editDeliveryMode/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function deliveryModeDelete(id) {
  const uri = `deleteDeliveryMode/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", {});
  return result;
}

export async function addDonationPost(data) {
  const result = await HttpClient.requestData("addDonationIntro", "POST", data);
  return result;
}

export async function editDonationIntro(id, data) {
  const uri = `editDonationIntro/${id}`;
  const result = await HttpClient.requestData(uri, "PUT", data);
  return result;
}

export async function donationIntroImgUpload(data) {
  const result = await HttpClient.fileUplode(
    "donationImageUpload",
    "POST",
    data
  );
  return result;
}

export async function donationIntroDelete(id) {
  const uri = `deleteDonationIntro/${id}`;
  const result = await HttpClient.requestData(uri, "POST", {});
  return result;
}

export async function scholarshipoverviewDelete(id, data) {
  const url = `deleteScholarshipOverview/${id}`;
  const result = await HttpClient.requestData(url, "PUT", data);
  return result;
}

export async function postScholarShipOverView(data) {
  const result = await HttpClient.requestData(
    "addScholarshipOverview",
    "POST",
    data
  );
  return result;
}

export async function editScholarshipoverview(id, data) {
  const url = `editScholarshipOverview/${id}`;
  const result = await HttpClient.requestData(url, "PUT", data);
  return result;
}

export async function addRecurringGift(data) {
  const result = await HttpClient.requestData("addRecurring", "POST", data);
  return result;
}

export async function delete_student_type(id) {
  const result = await HttpClient.requestData(
    `delete-student-type/${id}`,
    "PUT"
  );
  return result;
}

export async function update_student_type(data, id) {
  const result = await HttpClient.requestData(
    `update-student-type/${id}`,
    "PUT",
    data
  );
  return result;
}

export async function deleteCurrentStatus(id) {
  const result = await HttpClient.requestData(
    `delete-area-of-status/${id}`,
    "PUT"
  );
  return result;
}

export async function EditCurrentStatus(data, id) {
  const result = await HttpClient.requestData(
    `update-area-of-status/${id}`,
    "PUT",
    data
  );
  return result;
}

// Admission

export async function uploadAddAdmissionImage(data) {
  const result = await HttpClient.fileUplode(
    "admissionImageUpload",
    "POST",
    data
  );
  return result;
}

export async function AddAdmissionDetail(data) {
  const result = await HttpClient.requestData(
    "addAdmissionDetails",
    "POST",
    data
  );
  return result;
}

export async function DeleteAdmissionDetail(id) {
  const result = await HttpClient.requestData(
    `deleteAdmissionDetails/${id}`,
    "PUT"
  );
  return result;
}
export async function UpdateAdmissionDetail(data, id) {
  const result = await HttpClient.requestData(
    `editAdmissionDetails/${id}`,
    "PUT",
    data
  );
  return result;
}

export async function AddAdminationDropDown(data) {
  const result = await HttpClient.requestData(
    "addAdmissionDropDown",
    "POST",
    data
  );
  return result;
}

export async function DeleteAdminationDropDown(id) {
  const result = await HttpClient.requestData(
    `deleteAdmissionDropDown/${id}`,
    "PUT"
  );
  return result;
}

export async function UpdateAdmissionDropDown(data, id) {
  const result = await HttpClient.requestData(
    `editAdmissionDropDown/${id}`,
    "PUT",
    data
  );
  return result;
}
