import React, { useState } from 'react'
import AddIntroHistory from '../../components/dashboard/introhistory/AddIntroHistory'
import ViewIntroHistory from '../../components/dashboard/introhistory/ViewIntroHistory'

function IntroHistory() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddIntroHistory setAdded={setAdded} added={added} />
        <ViewIntroHistory added={added} />
    </div>
  )
}

export default IntroHistory