import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import HttpClient from "../../../../utils/HttpClient";
import Field from "./Comp/Field";

const ExpirationYear = ({ label, placeholder }) => {
  const [experienceYear, setexperienceYear] = useState([]);
  const [newExpYear, setNewExpYear] = useState("");
  const [editEnable, setEditEnable] = useState(false);
  const [editId, setEditId] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(
      `view-expiration-year`,
      "GET",
      {}
    );

    if (result && result.status) {
      setexperienceYear(result.data); // set data
    } else {
      toast.error(result.error);
    }
  };
  const onDelete = async (id) => {
    let res = await HttpClient.requestData(
      `delete-expiration-year/${id}`,
      "PUT"
    );
    if (res && res.status) {
      getData();
      toast.success(res.message);
    } else {
      toast.error(res.error);
    }
  };
  const addData = async () => {
    if (editEnable) {
      let res = await HttpClient.requestData("edit-expiration-year/"+editId, "PUT", {
        expirationYear: newExpYear,
      });
      if (res && res.status) {
        setNewExpYear("");
        setEditEnable(false);
        setEditId("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    } else {
      let res = await HttpClient.requestData("addExpirationYear", "POST", {
        expirationYear: newExpYear,
      });
      if (res && res.status) {
        setNewExpYear("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    }
  };

  const handleEdit = (data, id) => {
    setNewExpYear(data);
    setEditEnable(true);
    setEditId(id);
  };

  return (
    <Field
      state={experienceYear}
      setState={setexperienceYear}
      label={label}
      placeholder={placeholder}
      keyName="expirationYear"
      handleDelete={onDelete}
      inputValue={newExpYear}
      addNew={setNewExpYear}
      addData={addData}
      handleEdit={handleEdit}
      editEnable={editEnable}
    />
  );
};

export default ExpirationYear;
