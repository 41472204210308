import React from 'react'
import AddNews from '../../components/dashboard/news/AddNews'
import ViewNews from '../../components/dashboard/news/ViewNews'

function NewsPage() {
  return (
    <div>
        <AddNews />
        <ViewNews />
    </div>
  )
}

export default NewsPage