import React, { useState } from 'react'
import AddPresidentGoal from '../../components/dashboard/ourgoal/AddPresidentGoal'
import ViewPresidentGoal from '../../components/dashboard/ourgoal/ViewPresidentGoal'

function PresidentGoalPage() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddPresidentGoal added={added} setAdded={setAdded} />
        <ViewPresidentGoal added={added} />
    </div>
  )
}

export default PresidentGoalPage