import React, { useRef, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { addExperiencePost, experienceImageUpload,experienceEdit, bannerImageUpload, bannerVideoUpload, addBannerPost, bannerEdit } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation,useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

function AddBannerComp({setAdded,added}) {
  const imgRef = useRef();
  const videoRef = useRef();
  const navigate = useNavigate()
  const location = useLocation()
  const [editId] = useState(()=>{
    if(location.state){
      return location.state._id
    }else{
      return null
    }
  })
  const [initialValues] = useState(()=>{
    if(location.state){
      return {
        heading:location.state.heading,
        bannerImage:location.state.bannerImage,
        bannerVideo:location.state.bannerVideo
      }
    }else{
      return null
    }
  })
  const [uploadedImgUrl, setUploadedImgUrl] = useState(()=>{
    if(location.state){
      return location.state.bannerImage
    }else{
      return ""
    }
  });

  const [uploadedVideoUrl, setuploadedVideoUrl] = useState(()=>{
    if(location.state){
      return location.state.bannerVideo
    }else{
      return ""
    }
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { heading } = data;
    // if(!heading || !uploadedImgUrl || !uploadedVideoUrl){
    //   toast.error("Please enter all fields");
    //   return;
    // }

    if(!heading){
      toast.error("Please enter all fields");
      return;
    }
    // if (!uploadedImgUrl) {
    //   toast.error("Please upload an image");
    //   return;
    // }
    // if(!uploadedVideoUrl){
    //     toast.error("Please upload a video");
    //     return;
    // }
    let sendData = {
      heading,
      bannerVideo:uploadedVideoUrl,
      bannerImage: uploadedImgUrl,
    };

    console.log(sendData)
    if(!initialValues){
    setLoading(true);
    const res = await addBannerPost(sendData);
    setLoading(false);
    console.log(res);
    if (res && res.status) {
      toast.success("Banner added successfully");
      reset();
      imgRef.current.value = "";
      videoRef.current.value = "";
      setUploadedImgUrl("");
      setuploadedVideoUrl("")

      setAdded(added+1)
    }else{
      if(typeof res.message=="string"){
        toast.error(res.message)
      }
    }
    }else{

      setLoading(true)
      console.log(editId,sendData)
      const res = await bannerEdit(editId,sendData)
      setLoading(false)
      if(res && res.status){
        toast.success("Banner edited successfully")
        navigate(-1)
      }else{
        toast.error("Something went wrong please try again later")
      }
    console.log("HELLO")
    }
  };

  const handleVideoUpload = async(e)=>{
    let data = new FormData();
    data.append("video", e.target.files[0]);
    console.log(data, "daaaaa");
    setLoading(true);
    const result = await bannerVideoUpload(data);
    console.log(result)
    setLoading(false);
    console.log(result);
    if (result && result.status) {
      setuploadedVideoUrl(result.originalUrl);
      toast.success("Video successfully uploaded");
    } else {
      toast.error("Video upload went wrong, please try again!");
    }
  }


  const handleImageUpload = async (e) => {
    let data = new FormData();
    data.append("image", e.target.files[0]);
    console.log(data, "daaaaa");
    setLoading(true);
    const result = await bannerImageUpload(data);
    console.log(result)
    setLoading(false);
    console.log(result);
    if (result && result.status) {
      setUploadedImgUrl(result.url);
      toast.success("Image successfully uploaded");
    } else {
      toast.error("Something went wrong, please try again!");
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Banner section
            </CardTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="heading" className="mt-3">
                Enter heading
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues ?initialValues.heading:""}
                className="form-control mt-3 mb-1"
                placeholder="Enter heading"
                {...register("heading", { required: true })}
              />
              {errors.heading && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />

              <div className="mb-1 mt-3">
                {uploadedImgUrl && (
                  <img
                    style={{ height: "auto", width: 100, height: 100 }}
                    src={uploadedImgUrl}
                  />
                )}
              </div>
              <div className="mb-3">
                <label for="formFile" className="form-label mt-3">
                  Add an image
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  onChange={handleImageUpload}
                  ref={imgRef}
                />
                <br />
              </div>
              <div className="mb-1 mt-3">
                {uploadedVideoUrl && (
                  // <img
                  //   style={{ height: "auto", width: 100, height: 100 }}
                  //   src={uploadedImgUrl}
                  // />
                  <ReactPlayer url={uploadedVideoUrl} width={"640px"} height={"360px"} controls={true} />
                )}
              </div>
              <div className="mb-3">
                <label for="formFile" className="form-label mt-3">
                  Add an video
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="video/*"
                  id="formFile"
                  onChange={handleVideoUpload}
                  ref={videoRef}
                />
                <br />
              </div>
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? <Loader /> : initialValues ? "Edit Banner":"Add Banner"}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddBannerComp;
