import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import {
  applyEdit,
  introHistoryUpload,
  universityHistoryEdit,
  uploadHomeImage,
} from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";

function AddUniversityHistory({ added, setAdded }) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const [applyId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });
  const [fields, setFields] = useState(() => {
    if (location.state) {
      const arr = location.state.history.map((val, i) => {
        return {
          id: i + 1,
          year: val.year,
          desc: val.desc,
          image: val.image,
        };
      });
      return arr;
    } else {
      return [{ id: 1, year: "", desc: "", image: "" }];
    }
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState(() => {
    if (location.state) {
      return {
        heading: location.state.heading,
        history: location.state.history.map((val, i) => {
          return {
            year: val.year,
            desc: val.desc,
            image: val.image,
            id: i + 1,
          };
        }),
      };
    } else {
      return {
        heading: "",
        history: [],
      };
    }
  });
  const handleKeyValueAdd = () => {
    if (fields && fields.length > 0) {
      const lastKeyValue = fields[fields.length - 1];
      const newKeyValue = {
        id: lastKeyValue.id + 1,
        year: "",
        desc: "",
        image: "",
      };
      setFields([...fields, newKeyValue]);
    } else {
      setFields([{ id: 1, year: "", desc: "", image: "" }]);
    }
  };

  useEffect(() => {
    console.log(submitValues);
  }, [submitValues]);

  const handleKeyValueChange = (id, ev, index) => {
    const found = fields.find((f) => f.id === id);
    found.desc = ev.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, history: oldFields });
  };

  const handleImgValueChange = async (id, ev, index) => {
    // submitLoading(true)
    setSubmitLoading(true);
    let data = new FormData();
    data.append("image", ev.target.files[0]);
    console.log(data, "daaaaa");
    const result = await introHistoryUpload(data);
    setSubmitLoading(false);
    if (result && result.status) {
      const found = fields.find((f) => f.id === id);
      found.image = result.url;
      let oldFields = [...fields];
      oldFields.splice(index, 1, found);
      setFields(oldFields);
      setSubmitValues({ ...submitValues, history: oldFields });
      toast.success("Image uploaded successfully");
    }
  };

  const handleRemoveKeyValue = (idx) => {
    const oldFields = [...fields];
    const firstArr = oldFields.slice(0, idx);
    const lastArr = oldFields.slice(idx + 1);
    const newlastArr = lastArr.map((val) => {
      val.id = val.id - 1;
      return val;
    });
    const fieldsToSet = [...firstArr, ...newlastArr];
    setFields(fieldsToSet);
    setSubmitValues({ ...submitValues, history: fieldsToSet });
  };

  const handleFieldChange = (e, f) => {
    const found = fields.find((fi) => fi.id === f.id);
    const index = fields.findIndex((fi) => fi.id === f.id);
    console.log(found);
    console.log(index);
    found.year = e.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, history: oldFields });
  };

  const handleElementAdd = async () => {
    console.log(submitValues);

    const { heading, history } = submitValues;

    if (!heading) {
      toast.error("Please enter a heading before submitting");
      return;
    }
    const { image, year, desc } = history[0];

    if (!image || !year || !desc) {
      toast.error("Please enter at least one history details");
      return;
    }

    if (!location.state) {
      try {
        setSubmitLoading(true);
        const res = await HttpClient.requestData(
          "addUniversityHistory",
          "POST",
          submitValues
        );
        console.log(res);
        if (res && res.status) {
          setAdded(++added);
          setSubmitValues({
            heading: "",
            history: [],
          });
          setFields([{ id: 1, year: "", desc: "", image: "" }]);
          setSubmitLoading(false);
          toast.success("University History added successfully");
        } else {
            setSubmitLoading(false);
          toast.error(res.message);
        }
      } catch (err) {
        console.log(err);
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(true);
      const res = await universityHistoryEdit(applyId, submitValues);
      setSubmitLoading(false);
      if (res && res.status) {
        toast.success("University History edited successfully");
        navigate(-1);
      }
      //   console.log(sendData,applyId)
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add University History section
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter heading
            </label>
            <input
              type="text"
              id="heading"
              className="form-control mt-3"
              placeholder="Enter heading"
              value={submitValues.heading}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, heading: e.target.value });
              }}
            />

            {fields &&
              fields.map((f, i) => (
                <div key={i} className="d-flex flex-row">
                  <textarea
                    className="form-control mt-3 me-3"
                    placeholder="Enter desc"
                    value={f.desc}
                    rows={"2"}
                    style={{ maxWidth: "30%" }}
                    onChange={(e) => handleKeyValueChange(f.id, e, i)}
                  />

                  <input
                    type="number"
                    className="form-control mt-3 me-3"
                    placeholder="Enter Year"
                    value={f.year}
                    style={{ maxWidth: "30%" }}
                    onChange={(e) => handleFieldChange(e, f)}
                  />
                  <div className="d-flex flex-column">
                    <input
                      type="file"
                      className="form-control mt-3 me-3"
                      placeholder="upload image"
                      onChange={(e) => handleImgValueChange(f.id, e, i)}
                    />
                    {f.image && (
                      <img
                        className="mx-auto mt-2"
                        style={{ height: "auto", width: 100, height: 100 }}
                        src={f.image}
                      />
                    )}
                  </div>
                  {i !== 0 && (
                    <button
                      className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                      style={{ maxHeight: "40px" }}
                      onClick={() => handleRemoveKeyValue(i)}
                    >
                      <i
                        className="bi bi-x-circle"
                        style={{ fontSize: "15px" }}
                      ></i>
                    </button>
                  )}
                </div>
              ))}
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : location.state ? (
                  "Edit University History"
                ) : (
                  "Add University History"
                )}
              </button>
              <button
                className="btn btn-secondary mt-3 me-2"
                onClick={handleKeyValueAdd}
              >
                Add history
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddUniversityHistory;
