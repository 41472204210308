import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  addNetworkingPost,
  addPartnershipdegree,
  addScholarshipPost,
  editpartnershipdegree,
  networkingEdit,
  scholarshipEdit,
  scholarshipImageUpload,
} from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import toolbarConfig from "../../ReactRte/Index";

function AddPartnerShipDegree({ setAdded, added }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [editId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });
  const [editorVal, setEditorVal] = useState(() => {
    if (location.state) {
      return RichTextEditor.createValueFromString(
        location.state.description,
        "html"
      );
    } else {
      return RichTextEditor.createEmptyValue();
    }
  });
  const [initialValues] = useState(() => {
    if (location.state) {
      return {
        title: location.state.title,
        heading: location.state.heading,
        description: location.state.description,
        address: location.state.address,
      };
    } else {
      return null;
    }
  });
  const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
    if (location.state) {
      return location.state.image;
    } else {
      return "";
    }
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { title, description, heading, address } = data;

    let sendData = {
      title,
      heading,
      description: editorVal.toString("html"),
      address,
    };
    console.log(sendData);
    // return false;

    if (!initialValues) {
      setLoading(true);
      const res = await addPartnershipdegree(sendData);
      setLoading(false);
      console.log(res);
      if (res && res.status) {
        toast.success("Partnership degree added successfully");
        reset();
        setAdded(added + 1);
      } else {
        if (typeof res.message === "string") {
          toast.error(res.message);
        }
        if (typeof res.error === "string") {
          toast.error(res.error);
        }
      }
    } else {
      setLoading(true);
      const res = await editpartnershipdegree(editId, sendData);
      console.log(res);
      setLoading(false);
      if (res && res.status) {
        toast.success("Partnership degree edited successfully");
        navigate(-1);
      } else {
        toast.error("Something went wrong please try again later");
      }
    }
  };

  const handleEditorChange = (value) => {
    setEditorVal(value);
  };
  useEffect(() => {
    console.log(editorVal.toString("html"));
  }, [editorVal]);

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Partnership Degree
            </CardTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="title" className="mt-3">
                Enter title
              </label>
              <input
                type="text"
                id="title"
                defaultValue={initialValues ? initialValues.title : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter title"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="heading" className="mt-3">
                Enter heading
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues ? initialValues.heading : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter heading"
                {...register("heading", { required: true })}
              />
              {errors.heading && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="elemTitle" className="mt-3">
                Enter description
              </label>
              {/* <textarea
                                defaultValue={initialValues ? initialValues.description : ""}
                                id="elemTitle"
                                className="form-control mt-3 mb-1"
                                placeholder="Enter description"
                                {...register("description", { required: true })}
                            />
                            {errors.description && (
                                <span className="mt-2 text-danger">
                                    {" "}
                                    * This field is required
                                </span>
                            )} */}

              <RichTextEditor
                toolbarConfig={toolbarConfig}
                value={editorVal}
                onChange={handleEditorChange}
              />
              <br />
              <label htmlFor="elemTitle" className="mt-3">
                Enter address
              </label>
              <textarea
                defaultValue={initialValues ? initialValues.address : ""}
                id="elemTitle"
                className="form-control mt-3 mb-1"
                placeholder="Enter address"
                {...register("address", { required: true })}
              />
              {errors.address && (
                <span className="mt-2 text-danger">
                  {" "}
                  * This field is required
                </span>
              )}
              <br />
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? (
                    <Loader />
                  ) : initialValues ? (
                    "Edit Partnership degree"
                  ) : (
                    "Add Partnership degree"
                  )}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddPartnerShipDegree;
