import React, { useState } from 'react'
import AddPartnerShipIntro from '../../components/dashboard/partnershipintro/AddPartnerShipIntro'
import ViewPartnerShipIntro from '../../components/dashboard/partnershipintro/ViewPartnerShipIntro'

function PartnerShipIntro() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddPartnerShipIntro added={added} setAdded={setAdded} />
        <ViewPartnerShipIntro added={added} />
    </div>
  )
}

export default PartnerShipIntro