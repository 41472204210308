import React, { useState } from 'react'
import AddNetworking from '../../components/dashboard/networking/AddNetworking'
import ViewNetworking from '../../components/dashboard/networking/ViewNetworking'

function Networking() {
  const [added,setAdded] = useState(0)
  return (
    <>
    <AddNetworking added={added} setAdded={setAdded} />
    <ViewNetworking added={added} />
    </>
  )
}

export default Networking