import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  addExperiencePost,
  experienceImageUpload,
  experienceEdit,
  addNewsPost,
  newsEdit,
} from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import RichTextEditor from "react-rte";

function AddNewsEvent({ setAdded, added }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dateRef = useRef();

  const [date, setDate] = useState(() => {
    if (location.state) {
      return { value: moment(location.state.date), error: false };
    } else {
      return { value: "", error: false };
    }
  });

  const [editId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });

  const [initialValues, setInitialValues] = useState(() => {
    if (location.state) {
      return {
        title: location.state.title,
        description: location.state.description,
        date: location.state.date,
      };
    } else {
      return null;
    }
  });

  const [description, setDescription] = useState(() => {
    if (location.state) {
      return RichTextEditor.createValueFromString(location.state.description, 'html');
    } else {
      return RichTextEditor.createEmptyValue();
    }
  });

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (!date.value) {
      setDate({ value: "", error: true });
      return false;
    }
    console.log('data', initialValues)
    const { title } = initialValues;

    let sendData = {
      title: title,
      description: description.toString('html'), // Convert RTE content to HTML string
      date: date.value,
    };

    if (editId) { // Check if editId exists, meaning it's an edit operation
      setLoading(true);
      const res = await newsEdit(editId, sendData);
      setLoading(false);
      if (res && res.status) {
        toast.success("News edited successfully");
        navigate(-1); // Navigate back after successful edit
      } else {
        setLoading(false);
        toast.error("Something went wrong, please try again later");
      }
    } else { // If no editId, it's an add operation
      setLoading(true);
      const res = await addNewsPost(sendData);
      setLoading(false);
      if (res && res.status) {
        toast.success("News added successfully");
        reset();
        setDescription(RichTextEditor.createEmptyValue());
        setDate({ value: "", error: false });
        dateRef.current.value = "";
        setAdded(added + 1);
      } else {
        setLoading(false);
        if (typeof res?.message === "string") {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    }
};


  const handleTitleChange = (e) => {
    e.preventDefault();
    setInitialValues({ ...initialValues, title: e.target.value });
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleDateChange = (date, dateString) => {
    setDate({ value: dateString, error: false });
    setInitialValues({ ...initialValues, date: dateString });
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add News & Event section
            </CardTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="title" className="mt-3">
                Enter title
              </label>
              <input
                type="text"
                id="title"
                defaultValue={initialValues ? initialValues.title : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter title"
                {...register("title", { required: true })}
                onChange={handleTitleChange}
              />
              {errors.title && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="desc" className="mt-3">
                Enter description
              </label>
              <RichTextEditor
                value={description}
                onChange={handleDescriptionChange}
                className="mt-3 mb-1"
              />
              {errors.description && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <div className="mb-3">
                <label htmlFor="date" className="form-label mt-3">
                  Add date
                </label>
                <DatePicker
                  style={{ width: "100%" }}
                  size="large"
                  id="date"
                  name="date"
                  ref={dateRef}
                  format="YYYY-MM-DD"
                  position="topRight"
                  onChange={handleDateChange}
                  placeholder={
                    date.value
                      ? moment(date.value).format("YYYY MM DD")
                      : "select a date"
                  }
                />
                {date.error && (
                  <span className="mt-2 text-danger">
                    * This field is required
                  </span>
                )}
                <br />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  onClick={onSubmit}
                  className="btn btn-primary mt-3"
                  disabled={loading}
                >
                  {loading ? (
                    <Loader />
                  ) : initialValues ? (
                    "Edit news and event"
                  ) : (
                    "Add news and event"
                  )}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddNewsEvent;
