import React, { useState } from 'react'
import AddUniversityHistory from '../../components/dashboard/universityhistory/AddUniversityHistory'
import ViewUniversityHistory from '../../components/dashboard/universityhistory/ViewUniversityHistory'

function UniversityHistory() {
    const [addData,setAddData] = useState(0)
  return (
    <div>
     <AddUniversityHistory setAdded={setAddData} added={addData} />
     <ViewUniversityHistory added={addData} />
    </div>
  )
}

export default UniversityHistory