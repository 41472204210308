import { Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import HttpClient from "../../../../utils/HttpClient";
import Field from "./Comp/Field";

const Country = ({ label, placeholder }) => {
  const [countryName, setCountry] = useState([]);
  const [newCountryName, setCountryName] = useState("");
  const [editEnable, setEditEnable] = useState(false);
  const [editId, setEditId] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(`view-country`, "GET", {});

    if (result && result.status) {
      setCountry(result.data); // set data
    } else {
      toast.error(result.error);
    }
  };
  const onDelete = async (id) => {
    let res = await HttpClient.requestData(`delete-country/${id}`, "PUT");
    if (res && res.status) {
      getData();
      toast.success(res.message);
    } else {
      toast.error(res.error);
    }
  };
  const addCountry = async () => {
    if (editEnable) {
      let res = await HttpClient.requestData(`edit-country/${editId}`, "PUT", {
        country: newCountryName,
      });
      if (res && res.status) {
        setCountryName("");
        setEditEnable(false);
        setEditId("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    } else {
      let res = await HttpClient.requestData("addCountry", "POST", {
        country: newCountryName,
      });
      if (res && res.status) {
        setCountryName("");
        getData();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    }
  };
  const handleEdit = (data, id) => {
    setCountryName(data);
    setEditEnable(true);
    setEditId(id);
  };
  return (
    <Field
      state={countryName}
      setState={setCountry}
      keyName="country"
      label={label}
      placeholder={placeholder}
      handleDelete={onDelete}
      addNew={setCountryName}
      inputValue={newCountryName}
      addData={addCountry}
      handleEdit={handleEdit}
      editEnable={editEnable}
    />
  );
};

export default Country;
