import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

function WithBreadCrumb() {
  const location = useLocation();
  const [paths, setPaths] = useState(location.pathname.split("/").slice(1));
  useEffect(() => {
    setPaths(location.pathname.split("/").slice(1));
    console.log("first", location.pathname.split("/").slice(1));
    if (location.pathname.split("/").slice(1)[1] == "orginfo") {
      let arr = [...paths];
      arr[1] = "organisation info";
      setPaths(arr);
    } else if (location.pathname.split("/").slice(1)[1] == "addimgsection") {
      let arr = [...paths];
      arr[1] = "Add Image Section";
      setPaths(arr);
    } else if (
      location.pathname.split("/").slice(1)[1] == "scholarshipandgrants" &&
      location.pathname.split("/").slice(1)[0] == "scholarship"
    ) {
      let arr = [...paths];
      arr[0] = "Scholarship and Grands";
      arr[1] = "scholarship and Grands Intro";
      setPaths(arr);
    }
  }, [location]);
  console.log(paths);
  return (
    <>
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`/${paths[0]}`} style={{ textDecoration: "none" }}>
              {paths[0]?.replace(/([A-Z])/g, " $1")
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {paths[1]?.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
              return str.toUpperCase();
            })}
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Outlet />
    </>
  );
}

export default WithBreadCrumb;
