import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { applyEdit, feeEdit, feePost, uploadHomeImage } from "../../../Api/api";

function AddTuitionFees({added,setAdded}) {
  const navigate = useNavigate();
  const location = useLocation()
  console.log(location.state)
  const [applyId] = useState(()=>{
    if(location.state){
      return location.state._id
    }else{
      return null
    }
  })


  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const [fields, setFields] = useState(()=>{
     if(location.state){
       const arr = location.state.feesDetails.map((val,i)=>{
          return {
            course:val.course,
            cost:val.cost,
            id:i+1
          }
       })
       return arr
     }else{
       return [{ id: 1, course: "", cost: "" }]
     }
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState(()=>{
    if(location.state){
      return {
        headingType:location.state.headingType,
        title:location.state.title,
        feesDetails:location.state.feesDetails.map((val,i)=>{
          return {
            course:val.course,
            cost:val.cost,
            id:i+1
          }
        })
      }
    }else{
      return {
        headingType: "",
        title:"",
        feesDetails: [],
      }
    }
  });
  const handleKeyValueAdd = () => {
    if (fields && fields.length > 0) {
      const lastKeyValue = fields[fields.length - 1];
      const newKeyValue = { id: lastKeyValue.id + 1, course: "", cost: "" };
      setFields([...fields, newKeyValue]);
    } else {
      setFields([{ id: 1, course: "", cost: "" }]);
    }
  };

  useEffect(() => {
    console.log(submitValues);
  }, [submitValues]);

  const handleKeyValueChange = (id, ev, index) => {
    const found = fields.find((f) => f.id === id);
    found.course = ev.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, feesDetails: oldFields });
  };

  const handleRemoveKeyValue = (idx) => {
    const oldFields = [...fields];
    const firstArr = oldFields.slice(0, idx);
    const lastArr = oldFields.slice(idx + 1);
    const newlastArr = lastArr.map((val) => {
      val.id = val.id - 1;
      return val;
    });
    const fieldsToSet = [...firstArr, ...newlastArr];
    setFields(fieldsToSet);
    setSubmitValues({ ...submitValues, feesDetails: fieldsToSet });
  };

  const handleFieldChange = (e, f) => {
    const found = fields.find((fi) => fi.id === f.id);
    const index = fields.findIndex((fi) => fi.id === f.id);
    console.log(found);
    console.log(index);
    found.cost = e.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, feesDetails: oldFields });
  };

 

  const handleElementAdd = async () => {
  
    const {headingType,title} = submitValues;
    if(!headingType || !title){
      toast.error("Please provide all values");
      return
    }
    if(fields && (!fields[0].course || !fields[0].cost)){
      toast.error("Please enter at least 1 fee details");
      return
    }
    console.log(submitValues);

    const sendData = {
      ...submitValues,
      feesDetails:submitValues.feesDetails.map((val,i)=>{
        delete val.id;
        val.cost = Number(val.cost);
        return val
      })
    }
    console.log(sendData)
    if(!location.state){
      try {
        setSubmitLoading(true);
        const res = await feePost(sendData)
        console.log(res);
        setSubmitLoading(false);
        if (res && res.status) {
          setAdded(++added);
          setSubmitValues({
            headingType: "",
            title: "",
            feesDetails: [],
          });
          setFields([{ id: 1, course: "", cost: "" }]);
          toast.success("Tuition fee added successfully");
        }else{
          toast.error(res.message)
        }
      } catch (err) {
        console.log(err);
      }
    }else{
      setSubmitLoading(true)
      const res = await feeEdit(applyId,sendData)
      setSubmitLoading(false)
      if(res && res.status){
        toast.success("Tutition fee edited successfully")
        navigate(-1)
      }
      console.log(sendData,applyId)
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Tuition Fees
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter heading
            </label>
            <input
              type="text"
              id="heading"
              className="form-control mt-3"
              placeholder="Enter heading type"
              value={submitValues.headingType}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, headingType: e.target.value });
              }}
            />
            
            <label htmlFor="title" className="mt-3">
              Enter Title
            </label>
            <input
              type="text"
              id="title"
              className="form-control mt-3"
              placeholder="Enter title"
              value={submitValues.title}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, title: e.target.value });
              }}
            />
            
            {fields && fields.map((f, i) => (
              <div
                key={i}
                className="d-flex justify-content-center align-items-center"
              >
                <input
                  type="text"
                  className="form-control mt-3 me-3"
                  placeholder="enter course"
                  value={f.course}
                  onChange={(e) => handleKeyValueChange(f.id, e, i)}
                />

                <input
                  type="number"
                  className="form-control mt-3 me-3"
                  placeholder="enter cost"
                  value={f.cost}
                  onChange={(e) => handleFieldChange(e, f)}
                />
                {i !== 0 && (
                  <button
                    className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                    onClick={() => handleRemoveKeyValue(i)}
                  >
                    <i
                      className="bi bi-x-circle"
                      style={{ fontSize: "15px" }}
                    ></i>
                  </button>
                )}
              </div>
            ))}
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : location.state ? "Edit Tuition Fees":"Add Tuition Fees"}
              </button>
              <button
                className="btn btn-secondary mt-3 me-2"
                onClick={handleKeyValueAdd}
              >
                Add Fee details
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddTuitionFees;
