import React, { useEffect, useState } from "react";
// import HttpClient from "../../utils/HttpClient";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";

import { MDBDataTable } from "mdbreact";
import HttpClient from "../../../utils/HttpClient";
import { Value } from "sass";
import { toast } from "react-hot-toast";
import { experienceDelete, networkingDelete, scholarshipDelete, welcomeDelete } from "../../../Api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";

function ViewAllContacts({added}) {
  const navigate = useNavigate();
  const [contactList, setcontactList] = useState([]);
  const [Loading, setLoading] = useState(false);
  // const [elementFieldType,setelementFieldType] = useState("")

  const data = {
    columns: [
      {
        label: "First Name",
        field: "firstName",
        center: true,
      },
      {
        label: "Last Name",
        field: "lastName",
        center: true,
      },
      {
        label: "Email",
        field: "email",
        center: true,
      },
      {
        label: "Message",
        field: "msg",
        center: true,
      },
    ],
    rows: contactList,
  };

  useEffect(() => {
    fetchAllContactsList();
  }, [added]);

  async function fetchAllContactsList() {
    setLoading(true);
    try {
      const res = await HttpClient.requestData("viewQueryMessage", "GET", {});
      console.log(res);
      if (res.status) {
        const data = res.data.map((val, i) => {
          return {
             firstName:val.firstName,
             lastName:val.lastName,
             email:val.email,
             msg:val.message
          };
        });
        console.log(data);
        setcontactList(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  if (Loading) {
    return (
       <Loader />
    );
  }
  return (
    <div className="container">
      <Col>
        <Row>
          <Card>
            <CardBody>
              <CardTitle tag="h5">All Contacts</CardTitle>
              <MDBDataTable responsive bordered data={data} />
            </CardBody>
          </Card>
        </Row>
      </Col>
    </div>
  );
}

export default ViewAllContacts;
