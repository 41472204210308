import React, { useState } from 'react'
import AddContactDetails from '../../components/dashboard/contact/AddContactDetails'
import ViewContactDetails from '../../components/dashboard/contact/ViewContactDetails'

function ContactDetails() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddContactDetails setAdded={setAdded} added={added} />
        <ViewContactDetails added={added} />
    </div>
  )
}

export default ContactDetails