import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  addExperiencePost,
  experienceImageUpload,
  experienceEdit,
} from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import toolbarConfig from "../../ReactRte/Index";
import HttpClient from "../../../utils/HttpClient";

function AddExperience({ setAdded, added }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [editId, setEditId] = useState("");

  const [editorVal, setEditorVal] = useState(() => {
    if (location.state) {
      return RichTextEditor.createValueFromString(
        location.state.description,
        "html"
      );
    } else {
      return RichTextEditor.createEmptyValue();
    }
  });
  const [initialValues, setInitialValue] = useState({
    heading: "",
    description: RichTextEditor.createEmptyValue(),
    image: "",
  });
  const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
    if (location.state) {
      return location.state.image;
    } else {
      return "";
    }
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    e.preventDefault()
    const { heading, description,image } = initialValues;
    if (!image) {
      toast.error("Please upload an image");
      return;
    }
    if (!heading) {
      toast.error("Please Write a title");
      return;
    }  if (!description) {
      toast.error("Please write a description");
      return;
    }
    let sendData = {
      heading,
      description: description.toString("html"),
      image
    };

    // if (!initialValues) {
    //   setLoading(true);
    //   const res = await addExperiencePost(sendData);
    //   setLoading(false);
    //   console.log(res);
    //   if (res && res.status) {
    //     toast.success("Experience added successfully");
    //     reset();
    //     setUploadedImgUrl("");
    //     setAdded(added + 1);
    //   } else {
    //     toast.error(res.message);
    //   }
    // } else {

    // only edit is available
      setLoading(true);
      const res = await experienceEdit(editId, sendData);
      setLoading(false);
      if (res && res.status) {
        toast.success("Experience edited successfully");
        // navigate(-1);
      } else {
        toast.error("Something went wrong please try again later");
      }
    // }
  };


  useEffect(() => {
    console.log(editorVal.toString("html"));
  }, [editorVal]);

  const handleImageUpload = async (e) => {
    let data = new FormData();
    data.append("image", e.target.files[0]);
    console.log(data, "daaaaa");
    setLoading(true);
    const result = await experienceImageUpload(data);
    setLoading(false);
    console.log(result);
    if (result && result.status) {
      setInitialValue((prev)=>({...prev,image:result.url}) );
      toast.success("Image successfully uploaded");
    } else {
      toast.error("Something went wrong,please try again");
    }
  };

  useEffect(() => {
    fetchAllExp();
  }, []);

  async function fetchAllExp() {
    try {
      const res = await HttpClient.requestData(
        "viewExperienceSection",
        "GET",
        {}
      );

      if (res.status) {
        let data = res.data[0];
        // console.log(data);
        setEditId(data._id);
        setInitialValue((prev) => {
          return {
            ...prev,
            heading: data.heading,
            description: RichTextEditor.createValueFromString(
              data.description,
              "html"
            ),
            image: data.image,
          };
        });

     
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
               Experience section
            </CardTitle>
            <form onSubmit={onSubmit}>
              <label htmlFor="heading" className="mt-3">
                Enter heading
              </label>
              <input
                type="text"
                id="heading"
                value={initialValues.heading}
                className="form-control mt-3 mb-1"
                onChange={(val)=>setInitialValue((prev)=>({...prev,heading:val.target.value}))}
                // placeholder="Enter heading"
                // {...register("heading", { required: true })}
              />
              {errors.heading && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              {/* <label htmlFor="elemTitle" className="mt-3">
                Enter description
              </label>
              <input
                type="text"
                defaultValue={initialValues ?initialValues.description:""}
                id="elemTitle"
                className="form-control mt-3 mb-1"
                placeholder="Enter description"
                {...register("description", { required: true })}
              />
              {errors.description && (
                <span className="mt-2 text-danger">
                  {" "}
                  * This field is required
                </span>
              )}
              <br /> */}
              <label htmlFor="elemTitle" className="mt-3">
                Enter description
              </label>
              <RichTextEditor
                toolbarConfig={toolbarConfig}
                value={initialValues.description}
                onChange={(val)=>setInitialValue((prev)=>({...prev,description:val}))}
              />
              <br />

              {/* <div className="mb-1 mt-3">
            {submitValues.logo && (
              <img
              style={{ height: "auto", width: 100, height: 100 }}
              src={submitValues.logo}
            />
            )}
          </div> */}

             
              <div className="mb-3">
                <label for="formFile" className="form-label mt-3">
                  Add an image
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  // {...register("image")}
                  onChange={handleImageUpload}
                />
                {/* {errors.image && (
                  <span className="mt-2 text-danger">
                    {" "}
                    * This field is required
                  </span>
                )} */}
                <br />
                <div className="mb-1 mt-3">
                {initialValues.image && (
                  <img
                    style={{ height: "auto", width: 100, height: 100 }}
                    src={initialValues.image}
                  />
                )}
              </div>
              </div>
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? (
                    <Loader />
                  ) : initialValues ? (
                    "Edit experience"
                  ) : (
                    "Add experience"
                  )}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddExperience;
