import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import {
  campusImageUpload,
  homeVideoUpload,
  orgEdit,
} from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";

function AddOrgInfo({ added, setAdded }) {
  const videoRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [applyId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });

  const [uploadedVideoUrl, setuploadedVideoUrl] = useState(() => {
    if (location.state) {
      return location.state.video;
    } else {
      return "";
    }
  });

  const [fields, setFields] = useState(() => {
    if (location.state) {
      const arr = location.state.details.map((val, i) => {
        return {
          id: i + 1,
          name: val.name,
          image: val.image,
          count: val.count,
        };
      });
      return arr;
    } else {
      return [{ id: 1, name: "", image: "", count: "" }];
    }
  });

  const [Loading, setLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState(() => {
    if (location.state) {
      return {
        video: location.state.video,
        information: location.state.information,
        details: location.state.details.map((val, i) => {
          return {
            name: val.name,
            image: val.image,
            count: val.count,
            id: i + 1,
          };
        }),
      };
    } else {
      return {
        video: "",
        information: "",
        details: [],
      };
    }
  });
  const handleKeyValueAdd = () => {
    if (fields && fields.length > 0) {
      const lastKeyValue = fields[fields.length - 1];
      const newKeyValue = {
        id: lastKeyValue.id + 1,
        name: "",
        image: "",
        count: "",
      };
      setFields([...fields, newKeyValue]);
    } else {
      setFields([{ id: 1, name: "", image: "", count: "" }]);
    }
  };

  useEffect(() => {
    console.log(submitValues);
  }, [submitValues]);

  const handleChange = (id, ev, index, str) => {
    const found = fields.find((f) => f.id === id);
    if (str === "name") {
      found.name = ev.target.value;
    } else if (str === "count") {
      found.count = ev.target.value;
    }
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, details: oldFields });
  };

  const handleImgValueChange = async (id, ev, index) => {
    // submitLoading(true)
    setLoading(true);
    let data = new FormData();
    data.append("image", ev.target.files[0]);
    console.log(data, "daaaaa");
    const result = await campusImageUpload(data);
    console.log(result);
    setLoading(false);
    if (result && result.status) {
      const found = fields.find((f) => f.id === id);
      found.image = result.url;
      let oldFields = [...fields];
      oldFields.splice(index, 1, found);
      setFields(oldFields);
      setSubmitValues({ ...submitValues, details: oldFields });
      toast.success("Image uploaded successfully");
    }
  };

  const handleRemoveKeyValue = (idx) => {
    const oldFields = [...fields];
    const firstArr = oldFields.slice(0, idx);
    const lastArr = oldFields.slice(idx + 1);
    const newlastArr = lastArr.map((val) => {
      val.id = val.id - 1;
      return val;
    });
    const fieldsToSet = [...firstArr, ...newlastArr];
    setFields(fieldsToSet);
    setSubmitValues({ ...submitValues, details: fieldsToSet });
  };

  const handleVideoUpload = async (e) => {
    let data = new FormData();
    data.append("video", e.target.files[0]);
    console.log(data, "daaaaa");
    setLoading(true);
    const result = await homeVideoUpload(data);
    console.log(result);
    setLoading(false);
    console.log(result);
    if (result && result.status) {
      setuploadedVideoUrl(result.originalUrl);
      toast.success("Video successfully uploaded");
    } else {
      toast.error("Something went wrong,please try again");
    }
  };

  const handleElementAdd = async () => {
    console.log(submitValues);
    const { information, details } = submitValues;

    // console.log(list)
    if (!information) {
      toast.error("Information must not be blank");
      return;
    }

    if (details.length === 0) {
      toast.error("Please add message details");
      return;
    }
    // if (!uploadedVideoUrl) {
    //   toast.error("Please upload campus video");
    //   return;
    // }
    const sendData = {
      information,
      // video: uploadedVideoUrl,
      details,
    };

    console.log(sendData);

    if (!location.state) {
      try {
        setLoading(true);
        const res = await HttpClient.requestData(
          "addAOrganisationInfo",
          "POST",
          sendData
        );
        console.log(res);
        if (res && res.status) {
          setAdded(++added);
          setSubmitValues({
            title: "",
            heading: "",
            video: "",
            message: [],
          });
          setFields([{ id: 1, name: "", image: "", count: "" }]);
          setLoading(false);
          videoRef.current.value = "";
          setuploadedVideoUrl("");
          toast.success("Organiztion Info added successfully");
        } else {
            setLoading(false);
          if (typeof res.error === "string") {
            toast.error(res.error);
          }
          if (typeof res.message === "string") {
            toast.error(res.message);
          }
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      setLoading(true);
      const res = await orgEdit(applyId, sendData);
      setLoading(false);
      if (res && res.status) {
        toast.success("Organiztion Info edited successfully");
        navigate(-1);
      }
      //   console.log(sendData,applyId)
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Organization Info
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter information
            </label>
            <textarea
              id="title"
              className="form-control mt-3 mb-4"
              placeholder="Enter title"
              value={submitValues.information}
              onChange={(e) => {
                setSubmitValues({
                  ...submitValues,
                  information: e.target.value,
                });
              }}
            />

            {/* <div className="mb-1 mt-3">
              {uploadedVideoUrl && (
                // <img
                //   style={{ height: "auto", width: 100, height: 100 }}
                //   src={uploadedImgUrl}
                // />
                <ReactPlayer
                  url={uploadedVideoUrl}
                  width={"640px"}
                  height={"360px"}
                  controls={true}
                />
              )}
            </div>
            <div className="mb-3">
              <label for="formFile" className="form-label mt-3">
                Add an video
              </label>
              <input
                className="form-control"
                type="file"
                accept="video/*"
                id="formFile"
                onChange={handleVideoUpload}
                ref={videoRef}
              />
              <br />
            </div> */}
            <div className="d-flex justify-content-start align-items-center flex-wrap">
              {fields &&
                fields.map((f, i) => (
                  <Card
                    style={{
                      maxWidth: "16rem",
                    }}
                    className="mx-2"
                  >
                    <CardBody>
                      <div className="d-flex justify-content-around">
                        <CardTitle tag="h5" className="mt-2">
                          Card title
                        </CardTitle>
                        {i !== 0 && (
                          <button
                            className="btn btn-danger btn-sm"
                            style={{ maxHeight: "40px" }}
                            onClick={() => handleRemoveKeyValue(i)}
                          >
                            <i
                              className="bi bi-x-circle"
                              style={{ fontSize: "15px" }}
                            ></i>
                          </button>
                        )}
                      </div>

                      <div
                        key={i}
                        style={{ maxWidth: "100%" }}
                        // className="d-flex flex-row flex-wrap"
                      >
                        <input
                          type={"text"}
                          className="form-control mt-3 me-3"
                          placeholder="Enter name"
                          value={f.name}
                          onChange={(e) => handleChange(f.id, e, i, "name")}
                        />
                        <input
                          type={"text"}
                          className="form-control mt-3 me-3"
                          placeholder="Enter count"
                          value={f.count}
                          onChange={(e) => handleChange(f.id, e, i, "count")}
                        />
                        <div className="d-flex flex-column">
                          <input
                            type="file"
                            className="form-control mt-3 me-3"
                            placeholder="upload image"
                            onChange={(e) => handleImgValueChange(f.id, e, i)}
                          />
                          {f.image && (
                            <img
                              className="mx-auto mt-2"
                              style={{
                                height: "auto",
                                width: 100,
                                height: 100,
                              }}
                              src={f.image}
                            />
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={Loading}
              >
                {Loading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : location.state ? (
                  "Edit Organization info"
                ) : (
                  "Add Organization info"
                )}
              </button>
              <button
                className="btn btn-secondary mt-3 me-2"
                onClick={handleKeyValueAdd}
              >
                Add details
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddOrgInfo;
