import React from 'react'
import ViewAllContacts from '../../components/dashboard/contact/ViewAllContacts'

function ViewContacts() {

  return (
    <div>
        <ViewAllContacts />
    </div>
  )
}

export default ViewContacts