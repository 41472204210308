import React, { useState } from 'react'
import AddTutitionFees from '../../components/dashboard/tuitionfees/AddTutitionFees'
import ViewTuitionFees from '../../components/dashboard/tuitionfees/ViewTuitionFees'

function TutionFees() {
  const [added,setAdded] = useState(0)
  return (
    <div>
        <AddTutitionFees added={added} setAdded={setAdded} />
        <ViewTuitionFees added={added} />
    </div>
  )
}

export default TutionFees