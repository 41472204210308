import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { applyEdit, registrationInfoEdit, uploadHomeImage } from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";

function AddRegistrationInfoPage({ added, setAdded }) {
    const navigate = useNavigate();
    const location = useLocation()
    console.log(location.state)
    const [applyId] = useState(() => {
        if (location.state) {
            return location.state._id
        } else {
            return null
        }
    })

    const [option, setOption] = useState("")
    const [fields, setFields] = useState(() => {
        if (location.state) {
            const arr = location.state.delivery.map((val, i) => {
                return {
                    startDate: moment(val.Dates.startDate).format("YYYY-MM-DD"),
                    endDate: moment(val.Dates.endDate).format("YYYY-MM-DD"),
                    daytime: val.daytime,
                    delivery: val.delivery,
                    campus: val.campus,
                    cost: val.cost,
                    availability: val.availability,
                    id: i + 1
                }
            })
            return arr
        } else {
            return [{ id: 1, startDate: "", endDate: "", daytime: "", delivery: "", campus: "", cost: "", availability: "" }]
        }
    });

    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitValues, setSubmitValues] = useState(() => {
        if (location.state) {
            return {
                heading: location.state.heading,
                title: location.state.title,
                option: location.state.option,
                delivery: location.state.delivery.map((val, i) => {
                    return {
                        ...val,
                        id: i + 1
                    }
                })
            }
        } else {
            return {
                heading: "",
                title: "",
                option: [],
                delivery: [],
            }
        }
    });
    const handleKeyValueAdd = () => {
        if (fields && fields.length > 0) {
            const lastKeyValue = fields[fields.length - 1];
            const newKeyValue = {
                startDate: "",
                endDate: "",
                daytime: "",
                delivery: "",
                campus: "",
                cost: "",
                availability: "",
                id: lastKeyValue.id + 1,
            }
            setFields([...fields, newKeyValue]);
        } else {
            setFields([{
                startDate: "",
                endDate: "",
                daytime: "",
                delivery: "",
                campus: "",
                cost: "",
                availability: "",
                id: 1
            }]);
        }
    };

    useEffect(() => {
        console.log(submitValues);
    }, [submitValues]);

    // const handleKeyValueChange = (id, ev, index) => {
    //     const found = fields.find((f) => f.id === id);
    //     found.heading = ev.target.value;
    //     let oldFields = [...fields];
    //     oldFields.splice(index, 1, found);
    //     setFields(oldFields);
    //     setSubmitValues({ ...submitValues, sectionNote: oldFields });
    // };

    const handleRemoveKeyValue = (idx) => {
        const oldFields = [...fields];
        const firstArr = oldFields.slice(0, idx);
        const lastArr = oldFields.slice(idx + 1);
        const newlastArr = lastArr.map((val) => {
            val.id = val.id - 1;
            return val;
        });
        const fieldsToSet = [...firstArr, ...newlastArr];
        setFields(fieldsToSet);
        setSubmitValues({ ...submitValues, delivery: fieldsToSet });
    };

    const handleFieldChange = (e, f, type) => {
        const found = fields.find((fi) => fi.id === f.id);
        const index = fields.findIndex((fi) => fi.id === f.id);
        console.log(found);
        console.log(index);
        found[type] = e.target.value;
        let oldFields = [...fields];
        oldFields.splice(index, 1, found);
        setFields(oldFields);
        setSubmitValues({ ...submitValues, delivery: oldFields });
    };



    const handleElementAdd = async () => {

        console.log(submitValues);


        const { heading, option, title, delivery } = submitValues;
        
        if (!heading) {
            toast.error("Please enter valid heading");
            return;
        }
        if (!title) {
            toast.error("Please enter title");
            return;
        }
        if (!option || option.length < 1) {
            toast.error("Please enter at least 1 option");
            return
        }
        if (!delivery || delivery.length < 1) {
            toast.error("Please fill delivery details");
            return
        }
        let isValidDelivery = true;
        delivery.forEach(element => {
            console.log('element', element)
            // const { availability, campus, cost, daytime, delivery, endDate, startDate } = element;
            // if ( !campus || !cost ||
            //     !daytime || !delivery || !endDate || !startDate) {
            //     isValidDelivery = false
            // }
            const { availability, campus, cost, daytime, delivery, Dates } = element;
            // if ( !availability || !campus || !cost || !daytime || !delivery ||
            //     !Dates.endDate || !Dates.startDate) {
            //     isValidDelivery = false
            // }

        });
        if (!isValidDelivery) {
            toast.error("Please fill delivery details before submit");
            return;
        }

        const sendData = {
            ...submitValues,
            delivery: submitValues.delivery.map((v) => {
                const newObj = {}
                newObj.daytime = v.daytime
                newObj.delivery = v.delivery
                newObj.campus = v.campus
                newObj.cost = v.cost
                newObj.availability = v.availability
                newObj.Dates = {
                    startDate: moment(v.startDate, "YYYY-MM-DD").format('MMMM D Y'),
                    endDate: moment(v.endDate, "YYYY-MM-DD").format('MMMM D Y'),
                    // startDate: moment(v.Dates.startDate, "MMMM D Y").format('YYYY-MM-DD'),
                    // endDate: moment(v.Dates.endDate, "MMMM D Y").format('YYYY-MM-DD'),
                }
                return newObj
            })
        }

        console.log(sendData)
        // return

        if (!location.state) {
            try {
                setSubmitLoading(true);
                const res = await HttpClient.requestData(
                    "addRegistrationInfo",
                    "POST",
                    sendData
                );
                setSubmitLoading(false);
                console.log(res);
                if (res && res.status) {
                    setAdded(++added);
                    setSubmitValues({
                        heading: "",
                        title: "",
                        option: [],
                        delivery: [],
                    });
                    setFields([{
                        id: 1, startDate: "", endDate: "",
                        daytime: "", delivery: "", campus: "", cost: "",
                        availability: ""
                    }]);
                    toast.success("Registration info added successfully");
                }else{
                    toast.error(res.message)
                }
            } catch (err) {
                console.log(err);
                setSubmitLoading(false);
            }
        } else {
            setSubmitLoading(true)
            const res = await registrationInfoEdit(applyId, sendData)
            setSubmitLoading(false)
            if (res && res.status) {
                toast.success("Registration info successfully")
                navigate(-1)
            }
            console.log(sendData, applyId)
        }
    };

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                            <i className="bi bi-bell me-2"> </i>
                            Add Registration Info 
                        </CardTitle>
                        <label htmlFor="heading" className="mt-3">
                            Enter heading
                        </label>
                        <input
                            type="text"
                            id="heading"
                            className="form-control mt-3"
                            placeholder="Enter heading"
                            value={submitValues.heading}
                            onChange={(e) => {
                                setSubmitValues({ ...submitValues, heading: e.target.value });
                            }}
                        />

                        <label htmlFor="title" className="mt-3">
                            Enter title
                        </label>
                        <input
                            type="text"
                            id="title"
                            className="form-control mt-3"
                            placeholder="Enter title"
                            value={submitValues.title}
                            onChange={(e) => {
                                setSubmitValues({ ...submitValues, title: e.target.value });
                            }}
                        />
                        <div>
                            <ul class="list-group my-4">
                                {
                                    submitValues.option && submitValues.option.length > 0 && (
                                        <h6>Options</h6>
                                    )
                                }
                                {
                                    submitValues.option && submitValues.option.map((val, i) => {

                                        return <li class="list-group-item">
                                            <span>{val}</span>
                                            <i
                                                onClick={() => {
                                                    const options = [...submitValues.option];
                                                    options.splice(i, 1);
                                                    console.log(options)
                                                    setSubmitValues({ ...submitValues, option: options })
                                                }}
                                                className="bi bi-x-circle"
                                                style={{ fontSize: "25px", marginLeft: "20px", color: "red" }}
                                            ></i>
                                        </li>
                                    })
                                }
                            </ul>
                            <label htmlFor="heading" className="mt-3">
                                Enter Option
                            </label>
                            <input
                                type="text"
                                id="heading"
                                className="form-control mt-3"
                                placeholder="Add Option"
                                value={option}
                                onChange={(e) => {
                                    setOption(e.target.value)
                                }}
                            />
                            <button className="btn btn-primary" onClick={() => {
                                if (option !== "") {
                                    setSubmitValues({ ...submitValues, option: [...submitValues.option, option] })
                                    toast.success("Option added successfully")
                                    setOption("")
                                }
                            }}>
                                Add Option
                            </button>
                        </div>
                        <div className="d-flex justify-content-start align-items-center flex-wrap mt-4">
                            {fields && fields.map((f, i) => (
                                <Card
                                    style={{
                                        maxWidth: '18rem'
                                    }}
                                    className="mx-2"
                                >
                                    <CardBody>
                                        <div
                                            key={i}
                                            className="d-flex justify-content-around flex-column"
                                        >
                                            {/* <input
                                    type="text"
                                    className="form-control mt-3 me-3"
                                    placeholder="enter title"
                                    value={f.title}
                                    onChange={(e) => handleKeyValueChange(f.id, e, i)}
                                /> */}

                                            <input
                                                type="text"
                                                className="form-control mt-3 me-3"
                                                placeholder="enter daytime"
                                                value={f.daytime}
                                                onChange={(e) => handleFieldChange(e, f, "daytime")}
                                            />
                                            <input
                                                type="text"
                                                className="form-control mt-3 me-3"
                                                placeholder="enter delivery"
                                                value={f.delivery}
                                                onChange={(e) => handleFieldChange(e, f, "delivery")}
                                            />
                                            <input
                                                type="text"
                                                className="form-control mt-3 me-3"
                                                placeholder="enter campus"
                                                value={f.campus}
                                                onChange={(e) => handleFieldChange(e, f, "campus")}
                                            />
                                            <input
                                                type="number"
                                                className="form-control mt-3 me-3"
                                                placeholder="enter cost"
                                                value={f.cost}
                                                onChange={(e) => handleFieldChange(e, f, "cost")}
                                            />

                                            <input
                                                type="text"
                                                className="form-control mt-3 me-3"
                                                placeholder="enter availability"
                                                value={f.availability}
                                                onChange={(e) => handleFieldChange(e, f, "availability")}
                                            />
                                            <input
                                                type="date"
                                                className="form-control mt-3 me-3"
                                                placeholder="Enter starting date"
                                                value={f.startDate}
                                                onChange={(e) => handleFieldChange(e, f, "startDate")}
                                            />
                                            <input
                                                type="date"
                                                className="form-control mt-3 me-3"
                                                placeholder="Enter ending date"
                                                value={f.endDate}
                                                onChange={(e) => handleFieldChange(e, f, "endDate")}
                                            />


                                            {i !== 0 && (
                                                <button
                                                    className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                                                    style={{ width: "60px", height: "40px",position:"absolute",top:"-30px" ,right:"0" }}
                                                    onClick={() => handleRemoveKeyValue(i)}
                                                >
                                                    <i
                                                        className="bi bi-x-circle"
                                                        style={{ fontSize: "15px" }}
                                                    ></i>
                                                </button>
                                            )}
                                        </div>

                                    </CardBody>
                                </Card>

                            ))}
                        </div>
                        <div className="d-flex justify-content-between">
                            <button
                                className="btn btn-primary mt-3"
                                onClick={handleElementAdd}
                                disabled={submitLoading}
                            >
                                {submitLoading ? (
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : location.state ? "Edit Apply section" : "Add Apply section"}
                            </button>
                            <button
                                className="btn btn-secondary mt-3 me-2"
                                onClick={handleKeyValueAdd}
                            >
                                Add delivery
                            </button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AddRegistrationInfoPage;
