import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import {
  AddAdmissionDetail,
  UpdateAdmissionDetail,
  uploadAddAdmissionImage,
} from "../../../../Api/api";
import HttpClient from "../../../../utils/HttpClient";
import { toast } from "react-hot-toast";
import Loader from "../../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";

export default function AddAdminationDetail({ add, setAdd }) {
  const location = useLocation().state;
  const navigate = useNavigate();
  const [pageName, setPageName] = useState("");
  const [shortDesc, setShortDec] = useState("");
  const [longDesc, setLongDesc] = useState(RichTextEditor.createEmptyValue());
  const [bgImage, setbgImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [allPageNames, setAllPageNames] = useState([]);
  const [editId, setEditId] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPagename();
    if (location) {
      setPageName(location?.pageName);
      setShortDec(location?.shortDesc);
      setLongDesc(RichTextEditor.createValueFromString(location?.longDesc || "", 'html'));
      setbgImage(location?.bgImage);
    }
  }, [location]);

  const fetchPagename = async () => {
    let result = await HttpClient.requestData("viewAdmissionDropDown", "GET");
    if (result && result.status) {
      let allPage = [];
      result.data.map((it) => {
        allPage = [...allPage, ...it.category];
      });
      setAllPageNames(allPage);
    }
  };

  const Submit = async () => {
    setLoading(true);
    if (pageName !== "" && shortDesc !== "" && longDesc !== "") {
      let data = {
        bgImage: bgImage,
        shortDesc: shortDesc,
        longDesc: longDesc.toString('html'), // Convert the RichTextEditor content to HTML
        pageName: pageName,
      };
      if (editId) {
        let updateResult = await UpdateAdmissionDetail(data, editId);
        if (updateResult && updateResult.status) {
          toast.success("Details Updated Successfully");
          navigate("/admissions/admissionDetail", { state: null });
          resetForm();
          setAdd(add + 1);
        }
      } else {
        let result = await AddAdmissionDetail(data);
        if (result && result.status) {
          toast.success(result.message);
          resetForm();
          setAdd(add + 1);
        }
      }
    } else {
      toast.error("* required field");
    }
    setLoading(false);
  };

  const HandelImage = async (val) => {
    let data = new FormData();
    data.append("image", val);
    setLoading(true);
    let result = await uploadAddAdmissionImage(data);
    if (result && result.status) {
      setbgImage(result.url);
    }
    setLoading(false);
  };

  const resetForm = () => {
    setPageName("");
    setShortDec("");
    setLongDesc(RichTextEditor.createEmptyValue());
    setbgImage("");
    setEditId("");
  };

  const fetchPageDetails = async (pagename) => {
    let result = await HttpClient.requestData(`viewAdmissionDetails/${pagename}`, "GET");
    if (result.data.length > 0 && result.status) {
      setEditId(result.data[0]._id);
      setShortDec(result.data[0].shortDesc);
      setLongDesc(RichTextEditor.createValueFromString(result.data[0].longDesc || "", 'html'));
      setbgImage(result.data[0].bgImage);
    } else {
      resetForm();
    }
  };

  const handleLongDescChange = (value) => {
    setLongDesc(value);
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Admission Details
            </CardTitle>
            <div>
              <label htmlFor="pageName" id="edit_admission" className="mt-3">
                Enter Page Name
              </label>
              <select
                name="Select Page Name"
                className="form-control mt-3 mb-1"
                onChange={(e) => {
                  setPageName(e.target.value);
                  fetchPageDetails(e.target.value);
                }}
              >
                <option value="">Select Page Name</option>
                {allPageNames.map((val, index) => (
                  <option
                    key={index}
                    value={val.trim()}
                    selected={val.trim() === pageName ? true : false}
                  >
                    {val.trim()}
                  </option>
                ))}
              </select>
              <br />
              <label htmlFor="shortDesc" className="mt-3">
                Enter short description
              </label>
              <textarea
                id="shortDesc"
                rows={"3"}
                className="form-control mt-3 mb-1"
                placeholder="Enter short description"
                value={shortDesc}
                onChange={(e) => {
                  setShortDec(e.target.value);
                }}
              />
              <br />
              <label htmlFor="longDesc" className="mt-3">
                Enter Long description
              </label>
              <RichTextEditor
                value={longDesc}
                onChange={handleLongDescChange}
                className="mt-3 mb-1"
              />
              <br />

              <div className="mb-1 mt-3">
                {bgImage && (
                  <img
                    style={{ width: 100, height: 100 }}
                    src={bgImage}
                    alt="Background"
                  />
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label mt-3">
                  Add an image
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  onChange={(e) => {
                    HandelImage(e.target.files[0]);
                  }}
                />
                <br />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-primary mt-3"
                  onClick={Submit}
                >
                  {loading ? (
                    <Loader />
                  ) : editId ? (
                    "Update Details"
                  ) : (
                    "Submit Admission Detail"
                  )}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
