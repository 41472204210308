import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AddApplySection from "../components/dashboard/apply/AddApplySection";
import AddBannerComp from "../components/dashboard/banner/AddBannerComp";
import AddChampionsLife from "../components/dashboard/championslife/AddChampionsLife";
import AddExperience from "../components/dashboard/experience/AddExperience";
import AddNetworking from "../components/dashboard/networking/AddNetworking";
import AddNewsEvent from "../components/dashboard/newsevent/AddNewsEvent";
import AddPartnerShip from "../components/dashboard/partnership/AddPartnerShip";
import AddQuickViewForm from "../components/dashboard/quickview/AddQuickViewForm";
import AddScholarShip from "../components/dashboard/scholarship/AddScholarShip";
import AddSection from "../components/dashboard/sectionimg/AddSection";
import SidebarTest from "../components/dashboard/sidebartest/SidebarTest";
import AddTutitionFees from "../components/dashboard/tuitionfees/AddTutitionFees";
import WelcomeEdit from "../components/dashboard/welcome/welcomeEdit";
import PrivateRoute from "../routes/PrivateRoute";
import AddQuickView from "../views/AddQuickView/AddQuickView";
import AddsectionImg from "../views/AddSectionImage/AddsectionImg";
import ApplySection from "../views/ApplySection/ApplySection";

import Register from "../views/Auth/Register";
import AddBanner from "../views/Banner/AddBanner";
import ChampionsLife from "../views/ChampionsLife/ChampionsLife";
import Experience from "../views/Experience/Experience";
import Networking from "../views/Networking/Networking";
import NewsEvent from "../views/NewsEvent/NewsEvent";
import PartnerShip from "../views/Partnership/PartnerShip";
import Scholarship from "../views/ScholarShip/Scholarship";
import TutionFees from "../views/Tuition/TutionFees";

import WelcomePage from "../views/WelcomePage/WelcomePage";
import PublicRoute from "./PublicRoute";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Login = lazy(() => import("../views/Auth/Login"));

/*****Routes******/

const ThemeRoutes = [
  {
    element:<PrivateRoute/>,
    children:[
      {
        path: "/",
        element:<Navigate to="home/welcome" /> ,
        children:[
          {
            path:"home",
            element:<FullLayout />,
            
            children: [
              // { path: "/", element: <Navigate to="/welcome" />, },
              {path:"welcome",exact: true,element: <WelcomePage />},
              // {path:"/welcomeEdit",exact: true,element: <WelcomeEdit />},
              // {path:"/experience",exact: true,element: <Experience />},
              // {path:"/expedit",exact: true,element: <AddExperience />},
              // {path:"/apply",exact: true,element: <ApplySection />},
              // {path:"/applyedit",exact: true,element: <AddApplySection />},
              // {path:"/newsandevent",exact: true,element: <NewsEvent />},
              // {path:"/newseventedit",exact: true,element: <AddNewsEvent />},
              // {path:"/addimgsection",exact: true,element: <AddsectionImg />},
              // {path:"/addimgsectionedit",exact: true,element: <AddSection />},
              // {path:"/addquickview",exact: true,element: <AddQuickView />},
              // {path:"/addquickviewedit",exact: true,element: <AddQuickViewForm />},
              // {path:"/banner",exact: true,element: <AddBanner />},
              // {path:"/banneredit",exact: true,element: <AddBannerComp />},
              // {path:"/scholarship",exact: true,element: <Scholarship />},
              // {path:"/scholarshipedit",exact: true,element: <AddScholarShip />},
              // {path:"/networking",exact: true,element: <Networking />},
              // {path:"/networkingedit",exact: true,element: <AddNetworking />},
              // {path:"/tuitionfees",exact: true,element: <TutionFees />},
              // {path:"/tuitionfeesedit",exact: true,element: <AddTutitionFees />},
              // {path:"/championslife",exact: true,element: <ChampionsLife />},
              // {path:"/championsedit",exact: true,element: <AddChampionsLife />},
              // {path:"/partnership",exact: true,element: <PartnerShip />},
              // {path:"/partnershipedit",exact: true,element: <AddPartnerShip />},
              // { path: "/about", exact: true, element: <About /> },
              // { path: "/alerts", exact: true, element: <Alerts /> },
              // { path: "/badges", exact: true, element: <Badges /> },
              // { path: "/buttons", exact: true, element: <Buttons /> },
              // { path: "/cards", exact: true, element: <Cards /> },
              // { path: "/grid", exact: true, element: <Grid /> },
              // { path: "/table", exact: true, element: <Tables /> },
              // { path: "/forms", exact: true, element: <Forms /> },
              // { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
    
              // extras
              //   { path: "/starter", exact: true, element: <Starter /> },
              // { path: "/profession", exact: true,element: <AddProfession />},
              // { path: "/module", expect: true,element: <Module />},
              // { path: "/elementfieldtype", expect: true, element: <ElementFieldType /> },
              // { path: "/element-type", expect: true,element: <ElementType />},
              // { path: "/add-element", expect: true,element: <AddElement />},
              // { path: "/add-design", expect: true,element: <AddDesign />},
              // { path: "/list-design", expect: true,element: <ListDesign /> },
              // { path: "/add-homescreen", expect: true,element: <AddHomescreen /> },
            ],          }
        ]
       
        
      },  
    ]
  },

  {element:<PublicRoute />,children:[
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
  ]},
  {path:"/sidebar",element:<SidebarTest />}
];

export default ThemeRoutes;
