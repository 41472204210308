import React, { useState } from 'react'
import AddScholarShipAndGrants from '../../components/dashboard/scholarshipandgrant/AddScholarShipAndGrants'
import ViewAddScholarShipAndGrants from '../../components/dashboard/scholarshipandgrant/ViewAddScholarShipAndGrants'

function ScholarShipAndGrantsPage() {
  const [added,setAdded] = useState(0)
  return (
    <div>
        <AddScholarShipAndGrants added={added} setAdded={setAdded} />
        <ViewAddScholarShipAndGrants added={added} />
    </div>
  )
}

export default ScholarShipAndGrantsPage