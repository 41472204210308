import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { EditCurrentStatus, postCurrentStatus, postStudentType } from "../../Api/api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function AddCurrentStatus({ add, setAdd }) {
  const location = useLocation().state;
  const navigate=useNavigate()
  const [currStatus, setCurrStatus] = useState("");
  console.log("location",location);
  useEffect(()=>{
    if(location){
        setCurrStatus(location?.currentStatus)
    }
  },[location])

  const handleSubmit = async () => {

    if(location){
        let data={
            currentStatus:currStatus
        }
        const result=await EditCurrentStatus(data,location?._id)
        if(result  && result.status){
            toast.success(result.message)
            setAdd(add+1)
            setCurrStatus("")
            navigate("/common/addcurrentstatus",{state:null})
        }
    }else{
        if (!currStatus) {
      toast.error("Please provide current status value");
      return;
    }
    const res = await postCurrentStatus({ currentStatus: currStatus });
    console.log(res);
    if (res && res.status) {
      toast.success("Current Status added successfully");
      setCurrStatus("");
      setAdd(add + 1);
    } else {
      toast.error("Something went wrong please try again");
    }
    }

    
  };
  return (
    <div>
      <input
        id="current_Status_edit"
        type={"text"}
        className="form-control mt-3 me-3"
        placeholder="Enter Current status"
        value={currStatus}
        onChange={(e) => setCurrStatus(e.target.value)}
      />
      <button className="btn btn-primary" onClick={handleSubmit}>
        {
            location?"Update Status":"Add Status"
        }
      </button>
    </div>
  );
}

export default AddCurrentStatus;
