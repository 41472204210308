import React, { useState } from 'react'
import AddScholarShip from '../../components/dashboard/scholarship/AddScholarShip'
import ViewScholarShip from '../../components/dashboard/scholarship/ViewScholarShip'

function Scholarship() {
  const [added,setAdded] = useState(0)
  return (
    <div>
        <AddScholarShip added={added} setAdded={setAdded} />
        <ViewScholarShip added={added} />
    </div>
  )
}

export default Scholarship