import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { editQuickView } from "../../../Api/api";
// import { uploadHomeImage } from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";
import toolbarConfig from "../../ReactRte/Index";

function AddQuickViewForm({ setAdded, added }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [elemId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });
  const [fields, setFields] = useState(() => {
    if (!location.state) {
      return [
        { id: 1, title: "", description: RichTextEditor.createEmptyValue() },
      ];
    } else {
      let id = 1;
      const arr = location.state.category.map((val, i) => {
        const obj = {};
        obj.id = id;
        obj.title = val.title;
        obj.description = RichTextEditor.createValueFromString(
          val.description,
          "html"
        );
        id++;
        return obj;
      });
      return arr;
    }
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState(() => {
    if (!location.state) {
      return {
        heading: "",
        shortDesc: "",
        category: [],
      };
    } else {
      let id = 1;
      let obj = {};
      obj.heading = location.state.heading;
      obj.shortDesc = location.state.shortDesc;
      const arr = location.state.category.map((val, i) => {
        const obj2 = {};
        obj2.id = id;
        obj2.title = val.title;
        obj2.description = RichTextEditor.createValueFromString(
          val.description,
          "html"
        );
        id++;
        return obj2;
      });
      obj.category = arr;
      return obj;
    }
  });
  const handleKeyValueAdd = () => {
    if (fields && fields.length > 0) {
      const lastKeyValue = fields[fields.length - 1];
      const newKeyValue = {
        id: lastKeyValue.id + 1,
        title: "",
        description: RichTextEditor.createEmptyValue(),
      };
      setFields([...fields, newKeyValue]);
    } else {
      setFields([
        { id: 1, title: "", description: RichTextEditor.createEmptyValue() },
      ]);
    }
  };

  // useEffect(() => {
  //   console.log(RichTextEditor.createValueFromString(submitValues.shortDesc, "html"));
  // }, [submitValues]);

  const handleKeyValueChange = (id, ev, index) => {
    const found = fields.find((f) => f.id === id);
    found.title = ev.target.value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, category: oldFields });
  };

  const handleRemoveKeyValue = (idx) => {
    console.log(idx);

    const oldFields = [...fields];
    const firstArr = oldFields.slice(0, idx);
    const lastArr = oldFields.slice(idx + 1);
    const newlastArr = lastArr.map((val) => {
      val.id = val.id - 1;
      return val;
    });
    const fieldsToSet = [...firstArr, ...newlastArr];
    console.log(fieldsToSet);
    setFields(fieldsToSet);
    console.log(fieldsToSet);
    setSubmitValues({ ...submitValues, category: fieldsToSet });
  };

  const handleFieldChange = (value, f) => {
    // console.log(RichTextEditor.createValueFromString(value,"html"));
    // console.log(RichTextEditor.createValueFromString(value,"html"))
    const found = fields.find((fi) => fi.id === f.id);
    const index = fields.findIndex((fi) => fi.id === f.id);
    found.description = value;
    let oldFields = [...fields];
    oldFields.splice(index, 1, found);
    setFields(oldFields);
    setSubmitValues({ ...submitValues, category: oldFields });
  };

  const handleElementAdd = async () => {
    console.log(fields);
    const fieldsdesc = fields[0].description;
    console.log(fieldsdesc.toString("html"));
    const sendData = {
      ...submitValues,
      category: submitValues.category.map((val) => {
        return {
          ...val,
          description: val.description.toString("html"),
        };
      }),
    };
    console.log(sendData);

    const { heading, shortDesc, category } = sendData;

    if (
      !heading ||
      !shortDesc ||
      fields[0].title === "" ||
      fields[0].description === ""
    ) {
      toast.error("Please enter all values");
      return;
    }
    console.log(submitValues);
    if (!location.state) {
      try {
        setSubmitLoading(true);
        const res = await HttpClient.requestData(
          "addQuickView",
          "POST",
          sendData
        );
        console.log(res);
        if (res && res.status) {
          setAdded(++added);
          setSubmitValues({
            heading: "",
            shortDesc: "",
            category: [],
          });
          setFields([
            {
              id: 1,
              title: "",
              description: RichTextEditor.createEmptyValue(),
            },
          ]);
          setSubmitLoading(false);
          toast.success("Quick View added successfully");
        }else{
          setSubmitLoading(false);
          toast.error("Something Went wrong")
        }
      } catch (err) {
        console.log(err);
        setSubmitLoading(false);
      }
    } else {
      const res = await editQuickView(elemId, sendData);
      if (res && res.status) {
        toast.success("Quick View edited successfully");
        navigate(-1);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Quick View section
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter heading
            </label>
            <input
              type="text"
              id="heading"
              className="form-control mt-3"
              placeholder="Enter heading"
              value={submitValues.heading}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, heading: e.target.value });
              }}
            />
            <label htmlFor="elemTitle" className="mt-3">
              Enter short description
            </label>
            <textarea
              id="elemTitle"
              rows={"3"}
              className="form-control mt-3"
              value={submitValues.shortDesc}
              placeholder="Enter description"
              onChange={(e) => {
                setSubmitValues({
                  ...submitValues,
                  shortDesc: e.target.value,
                });
              }}
            />
            <label className="mt-3">
              Add Categories
            </label>
<Card>
  <CardBody>
  {fields.map((f, i) => (
    <Card>
      <CardBody>
      <div
                key={i}
                // className="d-flex justify-content-center align-items-center"
                className=""
              >
               <label>Enter Title </label>
                <input
                  type="text"
                  className="form-control mt-3 me-3"
                  placeholder="enter title"
                  value={f.title}
                  onChange={(e) => handleKeyValueChange(f.id, e, i)}
                />

                {/* <textarea
                  rows={"2"}
                  className="form-control mt-3 me-3"
                  placeholder="enter desc"
                  value={f.description}
                  onChange={(e) => handleFieldChange(e, f)}
                /> */}
                <label htmlFor="elemTitle" className="mt-3">
                  Enter description
                </label>
                <RichTextEditor
                 toolbarConfig={toolbarConfig}
                  value={f.description}
                  onChange={(val) => handleFieldChange(val, f)}
                />
                <br />

                {i !== 0 && (
                  <button
                  style={{position:"absolute" ,top:"0" ,right:"0"}}
                    className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                    onClick={() => handleRemoveKeyValue(i)}
                  >
                    <i
                      className="bi bi-x-circle"
                      style={{ fontSize: "15px" }}
                    ></i>
                  </button>
                )}
              </div>
      </CardBody>
    </Card>
             
            ))}

<button
                className="btn btn-secondary mt-3 me-2"
                onClick={handleKeyValueAdd}
              >
                Add category
              </button>
  </CardBody>

</Card>
          
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : location.state ? (
                  "Edit Quick view"
                ) : (
                  "Add Quick view"
                )}
              </button>
          
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddQuickViewForm;
