import React, { useState } from 'react'
import {Form,Input,Checkbox,Button,} from "antd";
import 'antd/dist/reset.css';
import { getLogin, getSignUp } from '../../Api/api';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';


function Register() {
    const navigate= useNavigate()

    const [name,setName] = useState("");
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("");
    const [address,setAddress] = useState("");
    const [confirmPass,setConfirmPass] = useState("");
    const [loading,setLoading] = useState(false);

    const onFinish =async()=>{
        const obj = {
            email,
            name,
            password,
            confirmPass,
            address
        }

        let emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        let isValidEmail = emailPattern.test(email.trim());

        if(name.trim()===""){
            toast.error("Please enter valid error");
            return
        }
        if(!isValidEmail){
            toast.error("Please enter valid email");
            return
        }
        if(password.trim()!==confirmPass.trim()){
            toast.error("Password dont match, please try again");
            return;
        }

        console.log(obj)
        setLoading(true)
        let result = await getSignUp(obj)
        setLoading(false)
        if(result && result.status){
            toast.success("Successfully registered");
            setTimeout(()=>{
              navigate("/login")
            },1000)
        }else{
            toast.error("Something went wrong,please try again later")
        }

        // if(email.trim() !="" && password.trim() !="" ){
        //     let data = {
        //         email,
        //         password
        //     }
        //     console.log(data);
        //     let result = await getLogin(data)
        //     console.log(result);
        //     if(result && result.status){
        //         toast.success("Successfully logged in")
        //         reactLocalStorage.setObject("userAdminData",result.data)
        //         setTimeout(()=>{
        //             navigate("/")
        //         },1000)
        //     }   
        //     else{
        //         toast.error(result.message)
        //     }
        // }
        // else{
        //   toast.error("please add valid data")
        // }
    }
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };
  return (
    <>  
     <div className="register-page">
    
      <div className="register-box">
        <div className="illustration-wrapper">
          <img src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700" alt="Register"/>
        </div>
        <Form
          name="register-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Welcome back</p>
          <p>Create a new account</p>

          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please enter your name!' }]}
          >
            <Input
              placeholder="Name"
              onChange={(val)=>setName(val.target.value)}
              value={name}
            />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              placeholder="Email"
              onChange={(val)=>setEmail(val.target.value)}
              value={email}
            />
          </Form.Item>

          <Form.Item
            name="address"
            rules={[{ required: true, message: 'Please enter your address!' }]}
          >
            <Input
              placeholder="Address"
              onChange={(val)=>setAddress(val.target.value)}
              value={address}
            />
          </Form.Item>
   
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password 
              placeholder="Password"
              value={password}
              onChange={(val)=>setPassword(val.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="confirmpass"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password 
              placeholder="Confirm Password"
              value={confirmPass}
              onChange={(val)=>setConfirmPass(val.target.value)}
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit"
             className="register-form-button"
             disabled={loading}
             >
              Sign Up
            </Button>
          </Form.Item>
          <p>Already Have an account ? <Link to="/login" style={{textDecoration:"none"}}>Login</Link></p>
        </Form>
      </div>
    </div> 
    </>
 )
}

export default Register