import { Toaster } from "react-hot-toast";
import { Navigate, useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import "./views/Auth/Login.css";
import "./App.css";
import "./views/Auth/Register.css";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import { createBrowserRouter, Routes, Route } from "react-router-dom";
import FullLayout from "./layouts/FullLayout";
import Welcome from "./components/dashboard/welcome/Welcome";
import PrivateRoute from "./routes/PrivateRoute";
import WelcomePage from "./views/WelcomePage/WelcomePage";
import WelcomeEdit from "./components/dashboard/welcome/welcomeEdit";
import Experience from "./views/Experience/Experience";
import AddExperience from "./components/dashboard/experience/AddExperience";
import ApplySection from "./views/ApplySection/ApplySection";
import AddApplySection from "./components/dashboard/apply/AddApplySection";
import NewsEvent from "./views/NewsEvent/NewsEvent";
import AddNewsEvent from "./components/dashboard/newsevent/AddNewsEvent";
import AddsectionImg from "./views/AddSectionImage/AddsectionImg";
import AddSection from "./components/dashboard/sectionimg/AddSection";
import AddQuickView from "./views/AddQuickView/AddQuickView";
import AddQuickViewForm from "./components/dashboard/quickview/AddQuickViewForm";
import AddBanner from "./views/Banner/AddBanner";
import AddBannerComp from "./components/dashboard/banner/AddBannerComp";
import Scholarship from "./views/ScholarShip/Scholarship";
import AddScholarShip from "./components/dashboard/scholarship/AddScholarShip";
import Networking from "./views/Networking/Networking";
import AddNetwork from "./components/dashboard/networking/AddNetworking";
import TutionFees from "./views/Tuition/TutionFees";
import AddTuitionFees from "./components/dashboard/tuitionfees/AddTutitionFees";
import ChampionsLife from "./views/ChampionsLife/ChampionsLife";
import AddChampionsLife from "./components/dashboard/championslife/AddChampionsLife";
import PartnerShip from "./views/Partnership/PartnerShip";
import AddPartnerShip from "./components/dashboard/partnership/AddPartnerShip";
import IntroHistory from "./views/IntroHistory/IntroHistory";
import ViewIntroHistory from "./components/dashboard/introhistory/ViewIntroHistory";
import PublicRoute from "./routes/PublicRoute";
import Login from "./views/Auth/Login";
import Register from "./views/Auth/Register";
import UniversityHistory from "./views/UniversityHistory/UniversityHistory";
import AddUniversityHistory from "./components/dashboard/universityhistory/AddUniversityHistory";
import AddIntroHistory from "./components/dashboard/introhistory/AddIntroHistory";
import NewsPage from "./views/NewsPage/NewsPage";
import AddNews from "./components/dashboard/news/AddNews";
import WithBreadCrumb from "./layouts/withBreadCrumb";
import FacultyIntro from "./views/FacultyIntro/FacultyIntro";
import AddFaculty from "./components/dashboard/faculty/AddFaculty";
import FacultyList from "./views/FacultyList/FacultyList";
import AddFacultyList from "./components/dashboard/facultylist/AddFacultyList";
import AboutIntro from "./views/AboutIntro/AboutIntro";
import AddAboutIntro from "./components/dashboard/aboutintro/AddAboutIntro";
import Campus from "./views/Campus/Campus";
import AddCampus from "./components/dashboard/campus/AddCampus";
import OrgInfo from "./views/OrgInfo/OrgInfo";
import AddOrgInfo from "./components/dashboard/orginfo/AddOrgInfo";
import HistoryDetails from "./views/HistoryDetails/HistoryDetails";
import AddHistoryDetails from "./components/dashboard/historydetails/AddHistoryDetails";
import MissionsAndValues from "./views/MissionsAndValues/MissionsAndValues";
import AddMissionsAndValues from "./components/dashboard/missionsandvalues/AddMissionsAndValues";
import ApplicationForm from "./views/ApplicationForm/ApplicationForm";
import AddStudentType from "./views/AddStudentType/AddStudentType";
import AddCurrentStatus from "./views/AddCurrentStatus/AddCurrentStatus";
// import AreaOfStudentAndDegree from "./views/AreaOfStudentAndDegree/AreaOfStudentAndDegree";
import PartnerShipIntro from "./views/Partnership/PartnerShipIntro";
import PartnerShipDegree from "./views/Partnership/PartnerShipDegree";
import AddPartnerShipIntro from "./components/dashboard/partnershipintro/AddPartnerShipIntro";
import AddPartnerShipDegree from "./components/dashboard/partnershipdegree/AddPartnerShipDegree";
import GalleryIntro from "./views/Gallery/GalleryIntro";
import AddGalleryIntro from "./components/dashboard/gallery/AddGalleryIntro";
import Gallery from "./views/Gallery/Gallery";
import AddGallery from "./components/dashboard/gallery/AddGallery";
import ChampionsPage from "./views/Champions/ChampionsPage";
import AddChampions from "./components/dashboard/champions/AddChampions";
import AddChampionsIntro from "./components/dashboard/champions/AddChampions";
import ContactIntroPage from "./views/Contact/ContactIntroPage";
import AddContactIntro from "./components/dashboard/contact/AddContactIntro";
import ContactDetails from "./views/Contact/ContactDetails";
import AddContactDetails from "./components/dashboard/contact/AddContactDetails";
import ViewContacts from "./views/Contact/ViewContacts";
import OurGoalIntroPage from "./views/OurGoal/OurGoalIntroPage";
import AddOurGoalIntro from "./components/dashboard/ourgoal/AddOurGoalIntro";
import PresidentGoalPage from "./views/OurGoal/PresidentGoalPage";
import AddPresidentGoal from "./components/dashboard/ourgoal/AddPresidentGoal";
import ScholarShipAndGrantsPage from "./views/ScholarShipAndGrantsPage/ScholarShipAndGrantsPage";
import AddScholarShipAndGrants from "./components/dashboard/scholarshipandgrant/AddScholarShipAndGrants";
import RegistrationInfoPage from "./views/ScholarShipAndGrantsPage/RegistrationInfoPage";
import AddRegistrationInfoPage from "./components/dashboard/scholarshipandgrant/AddRegistrationInfoPage";
import ScholarShipOverViewPage from "./views/ScholarShipAndGrantsPage/ScholarShipOverViewPage";
import AddScholarShipOverView from "./components/dashboard/scholarshipandgrant/AddScholarShipOverView";
import CIEIntroPage from "./views/CIEIntroPage/CIEIntroPage";
import AddCieIntro from "./components/dashboard/cieIntro/AddCieIntro";
import ApplyCIEPage from "./views/CIEIntroPage/ApplyCIE/ApplyCIEPage";
import AddCIEApply from "./components/dashboard/applycie/AddCIEApply";
import AddDeliveryMode from "./components/dashboard/deliverymode/AddDeliveryMode";
import DeliveryModePage from "./views/CIEIntroPage/DeliveryMode/DeliveryModePage";
import DonationPage from "./views/Donation/DonationPage";
import AddDonationIntro from "./components/dashboard/donation/AddDonationIntro";
import DonationFormPage from "./views/Donation/DonationForm";
import AddDonationForm from "./components/dashboard/donation/AddDonationForm";
import AdmissionDropDown from "./views/Admissions/AdmissionDropDown";
import AdmissionDetails from "./views/Admissions/AdmissionDetails";
import StudentType from "./views/AddStudentType/Index";
import CurrentStatus from "./views/AddCurrentStatus/Index";
import AreaOfStudentAndDegree from "./views/AreaOfStudentAndDegree/Index";
import ViewDonation from "./views/Donation/ViewDonation";
import Profile from "./views/Profile/Profile";
import Degrees from "./views/AreaOfStudentAndDegree/Degrees";
import ScholarshipForm from "./views/ApplicationForm/ScholarshipForm";

const App = () => {
  // const routing = useRoutes(Themeroutes);

  return (
    <div className="dark">
      <Toaster />
      {/* {routing} */}
      <createBrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="profile" element={<Profile />} />
            <Route path="/" element={<FullLayout />}>
              <Route element={<WithBreadCrumb />}>
                <Route index element={<WelcomePage />}></Route>
                <Route path="home">
                  <Route index element={<WelcomePage />}></Route>
                  {/* <Route path="welcome" element={<WelcomePage />}></Route> */}
                  <Route path="welcome" element={<WelcomeEdit />}></Route>
                  <Route path="experience" element={<Experience />}></Route>
                  <Route path="expedit" element={<AddExperience />}></Route>
                  {/* <Route path="apply" element={<ApplySection />}></Route> */}
                  <Route path="apply" element={<AddApplySection />}></Route>

                  <Route
                    path="newseventedit"
                    element={<AddNewsEvent />}
                  ></Route>
                  <Route
                    path="addimgsection"
                    element={<AddsectionImg />}
                  ></Route>
                  <Route
                    path="addimgsectionedit"
                    element={<AddSection />}
                  ></Route>
                  <Route path="addquickview" element={<AddQuickView />}></Route>
                  <Route
                    path="addquickviewedit"
                    element={<AddQuickViewForm />}
                  ></Route>
                  <Route path="banner" element={<AddBanner />}></Route>
                  <Route path="banneredit" element={<AddBannerComp />}></Route>
                  <Route path="scholarship" element={<Scholarship />}></Route>
                  <Route
                    path="scholarshipedit"
                    element={<AddScholarShip />}
                  ></Route>
                  <Route path="networking" element={<Networking />}></Route>
                  <Route path="networkingedit" element={<AddNetwork />}></Route>
                  <Route path="tuitionfees" element={<TutionFees />}></Route>
                  <Route
                    path="tuitionfeesedit"
                    element={<AddTuitionFees />}
                  ></Route>
                  <Route
                    path="championslife"
                    element={<ChampionsLife />}
                  ></Route>
                  <Route
                    path="championsedit"
                    element={<AddChampionsLife />}
                  ></Route>
                  <Route path="partnership" element={<PartnerShip />}></Route>
                  <Route
                    path="partnershipedit"
                    element={<AddPartnerShip />}
                  ></Route>
                </Route>
                {/*  */}
                <Route path="about">
                  <Route index element={<IntroHistory />}></Route>
                  <Route path="newsandevent" element={<NewsEvent />}></Route>
                  <Route path="introhistory" element={<IntroHistory />}></Route>
                  <Route
                    path="introhistoryedit"
                    element={<AddIntroHistory />}
                  ></Route>
                  <Route
                    path="universityhistory"
                    element={<UniversityHistory />}
                  ></Route>
                  <Route
                    path="universityhistoryedit"
                    element={<AddUniversityHistory />}
                  ></Route>
                  <Route path="newspage" element={<NewsPage />}></Route>
                  <Route path="newspageedit" element={<AddNews />}></Route>
                  <Route path="facultyintro" element={<FacultyIntro />}></Route>
                  <Route
                    path="facultyintroedit"
                    element={<AddFaculty />}
                  ></Route>
                  <Route path="facultylist" element={<FacultyList />}></Route>
                  <Route
                    path="facultylistedit"
                    element={<AddFacultyList />}
                  ></Route>
                  <Route path="aboutIntro" element={<AboutIntro />}></Route>
                  <Route
                    path="aboutIntroEdit"
                    element={<AddAboutIntro />}
                  ></Route>
                  <Route path="campus" element={<Campus />}></Route>
                  <Route path="campusedit" element={<AddCampus />}></Route>
                  <Route path="orginfo" element={<OrgInfo />}></Route>
                  <Route path="orginfoedit" element={<AddOrgInfo />}></Route>
                  <Route
                    path="historydetails"
                    element={<HistoryDetails />}
                  ></Route>
                  <Route
                    path="historydetailsedit"
                    element={<AddHistoryDetails />}
                  ></Route>
                </Route>
                <Route path="/common">
                  <Route index element={<MissionsAndValues />}></Route>
                  <Route
                    path="missions-and-values"
                    element={<MissionsAndValues />}
                  ></Route>
                  <Route
                    path="missions-and-values-edit"
                    element={<AddMissionsAndValues />}
                  ></Route>
                  <Route
                    path="applicationform"
                    element={<ApplicationForm />}
                  ></Route>
                  <Route
                    path="scholarshipform"
                    element={<ScholarshipForm />}
                  ></Route>
                  <Route
                    path="applicationform"
                    element={<ApplicationForm />}
                  ></Route>
                  <Route
                    path="addstudenttype"
                    element={<StudentType />}
                  ></Route>
                  <Route
                    path="addcurrentstatus"
                    element={<CurrentStatus />}
                  ></Route>
                  <Route
                    path="areaofstudyanddegree"
                    element={<AreaOfStudentAndDegree />}
                  ></Route>
                  <Route path="degress" element={<Degrees />}></Route>
                </Route>
                <Route path="/partnership">
                  <Route index element={<PartnerShipIntro />}></Route>
                  <Route
                    path="partnershipintro"
                    element={<PartnerShipIntro />}
                  ></Route>
                  <Route
                    path="partnershipintroedit"
                    element={<AddPartnerShipIntro />}
                  ></Route>
                  <Route
                    path="partnershipdegree"
                    element={<PartnerShipDegree />}
                  ></Route>
                  <Route
                    path="partnershipdegreeedit"
                    element={<AddPartnerShipDegree />}
                  ></Route>
                </Route>
                <Route path="/gallery">
                  <Route index element={<GalleryIntro />}></Route>
                  <Route path="galleryintro" element={<GalleryIntro />}></Route>
                  <Route
                    path="gelleryintroedit"
                    element={<AddGalleryIntro />}
                  ></Route>
                  <Route path="galleryimages" element={<Gallery />}></Route>
                  <Route
                    path="galleryimagesedit"
                    element={<AddGallery />}
                  ></Route>
                </Route>
                <Route path="/champions">
                  <Route index element={<ChampionsPage />}></Route>
                  <Route
                    path="championsintro"
                    element={<ChampionsPage />}
                  ></Route>
                  <Route
                    path="championsintroedit"
                    element={<AddChampionsIntro />}
                  ></Route>
                </Route>
                <Route path="/contact">
                  <Route index element={<ChampionsPage />}></Route>
                  <Route
                    path="contactIntro"
                    element={<ContactIntroPage />}
                  ></Route>
                  <Route
                    path="contactintroedit"
                    element={<AddContactIntro />}
                  ></Route>
                  <Route
                    path="contactdetails"
                    element={<ContactDetails />}
                  ></Route>
                  <Route
                    path="contactdetailsedit"
                    element={<AddContactDetails />}
                  ></Route>
                </Route>
                <Route path="/viewcontacts">
                  <Route index element={<ViewContacts />}></Route>
                  <Route path="contacts" element={<ViewContacts />}></Route>
                </Route>
                <Route path="/ourgoal">
                  <Route index element={<OurGoalIntroPage />}></Route>
                  <Route
                    path="goalintro"
                    element={<OurGoalIntroPage />}
                  ></Route>
                  <Route
                    path="goalintroedit"
                    element={<AddOurGoalIntro />}
                  ></Route>
                  <Route
                    path="presidentgoal"
                    element={<PresidentGoalPage />}
                  ></Route>
                  <Route
                    path="presidentgoaledit"
                    element={<AddPresidentGoal />}
                  ></Route>
                </Route>
                <Route path="/scholarship">
                  <Route index element={<ScholarShipAndGrantsPage />}></Route>
                  <Route
                    path="scholarshipandgrants"
                    element={<ScholarShipAndGrantsPage />}
                  ></Route>
                  <Route
                    path="scholarshipandgrantsedit"
                    element={<AddScholarShipAndGrants />}
                  ></Route>
                  <Route
                    path="registrationinfo"
                    element={<RegistrationInfoPage />}
                  ></Route>
                  <Route
                    path="registrationinfoedit"
                    element={<AddRegistrationInfoPage />}
                  ></Route>
                  <Route
                    path="scholarshipoverview"
                    element={<ScholarShipOverViewPage />}
                  ></Route>
                  <Route
                    path="scholarshipoverviewedit"
                    element={<AddScholarShipOverView />}
                  ></Route>
                </Route>
                <Route path="/centerofexcellence">
                  <Route index element={<CIEIntroPage />}></Route>
                  <Route path="cieIntro" element={<CIEIntroPage />}></Route>
                  <Route path="cieIntroedit" element={<AddCieIntro />}></Route>
                  <Route path="applyCEI" element={<ApplyCIEPage />}></Route>
                  <Route path="applyCEIEdit" element={<AddCIEApply />}></Route>
                  <Route
                    path="deliveryMode"
                    element={<DeliveryModePage />}
                  ></Route>
                  <Route
                    path="deliveryEdit"
                    element={<AddDeliveryMode />}
                  ></Route>
                </Route>
                <Route path="/donation">
                  <Route index element={<DonationPage />}></Route>
                  <Route
                    path="donationIntro"
                    element={<DonationPage />}
                  ></Route>
                  <Route
                    path="donationIntroEdit"
                    element={<AddDonationIntro />}
                  ></Route>
                  <Route
                    path="donationForm"
                    element={<DonationFormPage />}
                  ></Route>
                  <Route
                    path="donationIntroEdit"
                    element={<AddDonationForm />}
                  ></Route>
                  <Route
                    path="view-donation"
                    element={<ViewDonation />}
                  ></Route>
                </Route>

                <Route path="/admissions">
                  <Route
                    index
                    path="admissionDropDown"
                    element={<AdmissionDropDown />}
                  />
                  <Route
                    path="admissionDetail"
                    element={<AdmissionDetails />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/register" element={<Register />} /> */}
          </Route>
        </Routes>
      </createBrowserRouter>
    </div>
  );
};

export default App;
