import React from 'react'
import ViewScholarshipForm from '../../components/dashboard/applicationform/ViewScholarshipForm'


function ScholarshipForm() {
  return (
    <div>
        <ViewScholarshipForm />
    </div>
  )
}

export default ScholarshipForm