import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { uploadHomeImage } from "../../../Api/api";
import HttpClient from "../../../utils/HttpClient";
import { object } from "prop-types";

function WelcomeEdit() {
  const navigate = useNavigate();
  const inputRef = useRef();
  const location = useLocation();
  const [edit_id,setEdit_id] = useState("")

  const [fields, setFields] = useState([
    {
      id: 1,
      title: "",
      desc: "",
    },
  ]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState({
    heading: "",
    description: "",
    logo: "",
    sectionNote: [],
  });
  const handleKeyValueAdd = () => {
    for (const element of submitValues.sectionNote) {
      for (const key in element) {
        console.log("key",key);
        if(element[key]==""){
          if(key=="title"){
            toast.error("Please Add Title on section notes")
            return

          }
          if(key=="desc"){
            toast.error("Please Add Description on section notes")
            return

          }
        }
        
       
      }
      // console.log("ele",element);
    
    }

    setSubmitValues((prev)=>{
      return {
        ...prev ,
        sectionNote:[...prev.sectionNote,{title:"",desc:""}]
      }
    })
    
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const handleKeyValueChange = (val, index, field) => {

    setSubmitValues((prev)=>{
      let updatedNotes = [...prev.sectionNote]
      updatedNotes[index][field] = val
      return {
        ...prev,
        sectionNote:updatedNotes
      }
    })
  
  };

  const handleRemoveKeyValue = (idx) => {
    setSubmitValues((prev) => {
      let updatedNotes = [...prev.sectionNote];
      updatedNotes.splice(idx, 1);

      return {
        ...prev,
        sectionNote: updatedNotes,
      };
    });
  };



  const handleImageUpload = async (e) => {
    // console.log(e.target.files[0])
    let data = new FormData();
    data.append("image", e.target.files[0]);
    console.log(data, "daaaaa");

    const result = await uploadHomeImage(data);
    console.log(result);
    if (result && result.status) {
      setSubmitValues({ ...submitValues, logo: result.url });
      toast.success("Image successfully uploaded");
    }
  };

  const handleElementAdd = async () => {
    console.log(submitValues);

    const { heading, description, logo, sectionNote } = submitValues;

    if (!heading || !description || !logo || !sectionNote) {
      toast.error("Please enter all fields before submitting");
      return;
    }
    try {
      setSubmitLoading(true);
      const uri = `editWelcome/${edit_id}`;
      const res = await HttpClient.requestData(uri, "PUT", submitValues);
      console.log(res);
      if (res && res.status) {
        // 
  
        toast.success("Welcome section Updated successfully");
      } else {
        toast.error(res.message);
        setSubmitLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
    }finally{
setSubmitLoading(false)
    }
  };

  useEffect(() => {
    fetchWelcomeSection();
  }, []);

  async function fetchWelcomeSection() {
    // alert("kooo")
    try {
      const res = await HttpClient.requestData("viewWelcomeSection", "GET", {});
      console.log("fecthWelcome", res);
      if (res.status) {
        let data = res.data[0];
        setSubmitValues((prev) => {
          return {
            ...prev,
            heading: data.heading,
            description: data.description,
            logo: data.logo,
            sectionNote: data.sectionNote,
          };
        });

        setEdit_id(data._id)

        // setSubmitValues((prev)=>({
        //   prev.heading:
        // }))
        // setSubmitValues((prev)=>{
        //   prev.heading=data.heading
        //   prev.description=data.description
        //   prev.logo=data.logo
        //   prev.sectionNote=data.sectionNote

        // })
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Welcome section
            </CardTitle>
            <label htmlFor="heading" className="mt-3">
              Enter heading
            </label>
            <input
              type="text"
              id="heading"
              className="form-control mt-3"
              placeholder="Enter heading"
              value={submitValues.heading}
              onChange={(e) => {
                setSubmitValues({ ...submitValues, heading: e.target.value });
              }}
            />
            <label htmlFor="elemTitle" className="mt-3">
              Enter description
            </label>
            <input
              type="text"
              id="elemTitle"
              className="form-control mt-3"
              placeholder="Enter description"
              value={submitValues.description}
              onChange={(e) => {
                setSubmitValues({
                  ...submitValues,
                  description: e.target.value,
                });
              }}
            />

            <div className="mb-3">
              <label for="formFile" className="form-label mt-3">
                logo
              </label>
              <input
                ref={inputRef}
                className="form-control"
                type="file"
                accept="image/*"
                id="formFile"
                onChange={handleImageUpload}
              />
            </div>
            <div className="mb-1 mt-3">
              <img
                style={{ height: "auto", width: 100, height: 100 }}
                src={submitValues.logo}
              />
            </div>
            <label className="mt-3">Section Notes</label>
            <Card>
              <CardBody>
                {submitValues.sectionNote.map((f, i) => (
                  <div
                    key={i}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <input
                      type="text"
                      className="form-control mt-3 me-3"
                      placeholder="enter title"
                      value={f.title}
                      onChange={(e) => handleKeyValueChange(e.target.value,i,"title")}
                    />

                    <input
                      type="text"
                      className="form-control mt-3 me-3"
                      placeholder="enter description"
                      value={f.desc}
                      onChange={(e) => handleKeyValueChange(e.target.value,i,"desc")}
                    />
                    {i !== 0 && (
                      <button
                        className="btn btn-danger btn-sm p-2 mx-2 mt-3"
                        onClick={() => handleRemoveKeyValue(i)}
                      >
                        <i
                          className="bi bi-x-circle"
                          style={{ fontSize: "15px" }}
                        ></i>
                      </button>
                    )}
                  </div>
                ))}

                <button
                  className="btn btn-secondary mt-3 me-2"
                  onClick={handleKeyValueAdd}
                >
                  Add section notes
                </button>
              </CardBody>
            </Card>

            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary mt-3"
                onClick={handleElementAdd}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Edit section"
                )}
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default WelcomeEdit;
