import React, { useState } from 'react'
import AddCieIntro from '../../components/dashboard/cieIntro/AddCieIntro'
import ViewCieIntro from '../../components/dashboard/cieIntro/ViewCieIntro'

function CIEIntroPage() {
  const [added,setAdded] = useState(0)

  return (
    <div>
      <AddCieIntro added={added} setAdded={setAdded} />
      <ViewCieIntro added={added} />
    </div>
  )
}

export default CIEIntroPage