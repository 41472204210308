import React, { useState } from "react";
import { Card, Row, Col, CardTitle, CardBody } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  addNetworkingPost,
  addScholarshipPost,
  networkingEdit,
  scholarshipEdit,
  scholarshipImageUpload,
} from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import toolbarConfig from "../../ReactRte/Index";
function AddNetwork({ setAdded, added }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [editId] = useState(() => {
    if (location.state) {
      return location.state._id;
    } else {
      return null;
    }
  });
  const [initialValues] = useState(() => {
    if (location.state) {
      return {
        title: location.state.title,
        heading: location.state.heading,
        // description: location.state.description,
      };
    } else {
      return null;
    }
  });
  const [uploadedImgUrl, setUploadedImgUrl] = useState(() => {
    if (location.state) {
      return location.state.image;
    } else {
      return "";
    }
  });
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(() => {
    if (location.state) {
      return RichTextEditor.createValueFromString(
        location.state.description,
        "html"
      );
    } else {
      return RichTextEditor.createEmptyValue();
    }
  });
  console.log("desc", description);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { title, heading } = data;

    let sendData = {
      title,
      heading,
      description: description.toString("html"),
    };
    console.log("sendData", sendData);
    if (!initialValues) {
      setLoading(true);
      const res = await addNetworkingPost(sendData);
      setLoading(false);
      console.log(res);
      if (res && res.status) {
        toast.success("Networking added successfully");
        reset();
        setAdded(added + 1);
      }else{
        toast.error("Something Went Wrong");
      }
    } else {
      setLoading(true);
      const res = await networkingEdit(editId, sendData);
      console.log(res);
      setLoading(false);
      if (res && res.status) {
        toast.success("Networking edited successfully");
        navigate(-1);
      } else {
        toast.error("Something went wrong please try again later");
      }
    }
  };
  const handleChange = (val) => {
    setDescription(val);
  };
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-bell me-2"> </i>
              Add Networking section
            </CardTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="heading" className="mt-3">
                Enter heading
              </label>
              <input
                type="text"
                id="heading"
                defaultValue={initialValues ? initialValues.heading : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter heading"
                {...register("heading", { required: true })}
              />
              {errors.heading && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="title" className="mt-3">
                Enter title
              </label>
              <input
                type="text"
                id="title"
                defaultValue={initialValues ? initialValues.title : ""}
                className="form-control mt-3 mb-1"
                placeholder="Enter title"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <span className="mt-2 text-danger">
                  * This field is required
                </span>
              )}
              <br />
              <label htmlFor="elemTitle" className="mt-3">
                Enter description
              </label>
              <RichTextEditor toolbarConfig={toolbarConfig} value={description} onChange={handleChange} />
              {/* <textarea
                defaultValue={initialValues ?initialValues.description:""}
                id="elemTitle"
                className="form-control mt-3 mb-1"
                placeholder="Enter description"
                {...register("description", { required: true })}
              />
              {errors.description && (
                <span className="mt-2 text-danger">
                  {" "}
                  * This field is required
                </span>
              )} */}
              <br />
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary mt-3" disabled={loading}>
                  {loading ? (
                    <Loader />
                  ) : initialValues ? (
                    "Edit network"
                  ) : (
                    "Add network"
                  )}
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default AddNetwork;
