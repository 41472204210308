import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { addRecurringGift } from "../../../Api/api";
import Field from "./FormData/Comp/Field";
import Country from "./FormData/Country";
import ExperienceMonth from "./FormData/ExperienceMonth";
import ExpirationYear from "./FormData/ExpirationYear";
import Recurring from "./FormData/Recurring";
import Salutation from "./FormData/Salutation";
import State from "./FormData/State";
import FundName from "./FormData/FundName";

function AddDonationForm() {
  const [recurring, setRecurring] = useState("");

  const handleSubmit = async () => {
    console.log(recurring);

    if (!recurring) {
      toast.error("Please provide recurring type");
      return;
    }
    const res = await addRecurringGift({ recurring });
    console.log(res);
    if (res && res.status) {
      toast.success("Recurring type added successfully");
      setRecurring("");
    } else {
      toast.error("Something went wrong please try again");
    }
  };

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                <i className="bi bi-bell me-2"> </i>
                View Donation Form
              </CardTitle>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <FundName label="Add Fund Name" placeholder="add fund name" />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Recurring label="Add Recurring" placeholder="add recurring" />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Country label="Add Country" placeholder="add country" />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <State label="Add State" placeholder="add state" />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <ExperienceMonth
                label="Add Experience Month"
                placeholder="add experience month"
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <ExpirationYear
                label="Add Experience Year"
                placeholder="add experience year"
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Salutation label="Add Salutation" placeholder="add salutation" />
            </CardBody>
          </Card>

          

        </Col>
      </Row>
    </>
  );
}

export default AddDonationForm;
