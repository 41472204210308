import React, { useState } from 'react'
import AddOurGoalIntro from '../../components/dashboard/ourgoal/AddOurGoalIntro'
import ViewOurGoalIntro from '../../components/dashboard/ourgoal/ViewOurGoalIntro'

function OurGoalIntroPage() {
  const [added,setAdded] = useState(0)

  return (
    <div>
        <AddOurGoalIntro added={added} setAdded={setAdded} />
        <ViewOurGoalIntro added={added} />
    </div>
  )
}

export default OurGoalIntroPage