import React, { useState } from 'react'
import AddDeliveryMode from '../../../components/dashboard/deliverymode/AddDeliveryMode'
import ViewDeliveryMode from '../../../components/dashboard/deliverymode/ViewDeliveryMode'

function DeliveryModePage() {
    const [added,setAdded] = useState(0)
  return (
    <div>
        <AddDeliveryMode added={added} setAdded={setAdded} />
        <ViewDeliveryMode added={added} />
    </div>
  )
}

export default DeliveryModePage