import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, CardTitle, CardBody, Toast } from "reactstrap";
import { useForm } from "react-hook-form";
import { introHistoryUpload, addNewsPost, editNewsPost, postNews, postPartnershipIntro, editPartnerIntro, partnershipIntroImgUpload, championsIntroImageUpload, homeVideoUpload, postChampionsIntro, editChampionsIntro, uploadHomeImage, postCieIntro, editCieIntro, postDeliveryMode, editDeliveryMode } from "../../../Api/api";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import ReactPlayer from "react-player";
import toolbarConfig from "../../ReactRte/Index";

function AddDeliveryMode({ setAdded, added }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [editId] = useState(() => {
        if (location.state) {
            return location.state._id
        } else {
            return null
        }
    })

    const [editorVal, setEditorVal] = useState(() => {
        if (location.state) {
            return {
                description: RichTextEditor.createValueFromString(location.state.description, "html"),
            }
        } else {
            return {
                description: RichTextEditor.createEmptyValue(),
            }
        }
    })
    const [initialValues] = useState(() => {
        if (location.state) {
            return {
                heading:location.state.heading,
               description:location.state.description
            }
        } else {
            return null
        }
    })
   

 
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const description=editorVal.description.toString("html")
        console.log(data)
        const {heading } = data;
        if(!description){
            toast.error("Please add delivery mode description")
        }
        const sendData = {
            description,
            heading
        }

        console.log(sendData)

        if (!initialValues) {
            setLoading(true);
            const res = await postDeliveryMode(sendData);
            setLoading(false);
            console.log(res);
            if (res && res.status) {
                toast.success("Delivery Mode added successfully");
                reset();
                setEditorVal({
                    description: RichTextEditor.createEmptyValue(),
                })
                setAdded(++added)
            } else {
                toast.error(res.message)
            }
        } else {
            setLoading(true)
            const res = await editDeliveryMode(editId, sendData)
            setLoading(false)
            if (res && res.status) {
                toast.success("Updated successfully")
                navigate(-1)
            } else {
                toast.error("Something went wrong please try again later")
            }
        }
    };

   
    const handleEditorDescChange = (value) => {
        setEditorVal({
            ...editorVal,
            description: value
        })
    };


   
    useEffect(() => {
        console.log(editorVal)
    }, [editorVal])

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                            <i className="bi bi-bell me-2"> </i>
                            Add Delivery Mode
                        </CardTitle>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="heading" className="mt-3">
                                Enter heading
                            </label>
                            <input
                                type="text"
                                id="heading"
                                defaultValue={initialValues ? initialValues.heading : ""}
                                className="form-control mt-3 mb-1"
                                placeholder="Enter header"
                                {...register("heading", { required: true })}
                            />
                            {errors.heading && (
                                <span className="mt-2 text-danger">
                                    * This field is required
                                </span>
                            )}
                            <br />

                            <label htmlFor="elemdesc" className="mt-3">
                                Enter description
                            </label>
                            <RichTextEditor
                            toolbarConfig={toolbarConfig}
                                id="elemdesc"
                                value={editorVal.description}
                                onChange={handleEditorDescChange}
                            />
                            <br />
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary mt-3" disabled={loading}>
                                    {loading ? <Loader /> : initialValues ? "Edit Delivery Mode" : "Add Delivery Mode"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AddDeliveryMode;
